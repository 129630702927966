@if (conversation) {
    <div class="voice-notification mt-2 ms-2 container-fluid w-auto">
        <div class="row ms-3 pt-3">
            <div class="col-12">
                <voice-call-info
                    [conversation]="conversation"
                    [customerName]="customerName"
                ></voice-call-info>
            </div>
        </div>
        <div class="row ms-3">
            <div class="col-2 p-0"></div>
            <div class="col-10 px-2 align-items-start text-start">
                <div class="d-flex">
                    <div
                        class="d-flex flex-column align-items-center justify-content-center ms-2 me-3"
                    >
                        <button
                            type="button"
                            class="btn btn-accept rounded-circle p-3"
                            (click)="onAcceptClick()"
                        >
                            <i class="fa-xl fa-solid fa-phone"></i>
                        </button>
                        <div class="mt-1 font-sm text-center">Accept</div>
                    </div>
                    <div
                        class="d-flex flex-column align-items-center justify-content-center ms-3"
                    >
                        <button
                            type="button"
                            class="btn btn-reject rounded-circle p-3"
                        >
                            <i class="fa-xl fa-solid fa-phone-hangup"></i>
                        </button>
                        <div class="mt-1 font-sm text-center">Reject</div>
                    </div>
                    <div class="align-self-end text-end w-100 me-2 font-lg">
                        {{ countdown | formatDuration }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 p-0">
                <div
                    class="progress-bar progress-voice"
                    [ngClass]="{
                        'bg-success': progress > 66,
                        'bg-warning': progress > 33 && progress <= 66,
                        'bg-danger': progress <= 33,
                    }"
                    role="progressbar"
                    [style.width]="progress + '%'"
                ></div>
            </div>
        </div>
    </div>
}
