import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'telxl-attachment-button',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './attachment-button.component.html',
    styleUrl: './attachment-button.component.scss',
})
export class TelXLAttachmentButtonComponent {
    @Output() attachClicked = new EventEmitter<void>();

    onAttachClick() {
        this.attachClicked.emit();
    }
}
