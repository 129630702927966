import { Injectable } from '@angular/core';
import { IRbacChannelProviderConfiguration } from '../../models/rbac/channel-provider';

export const ChannelProviderTypes = [
    'Webchat',
    'Facebook',
    'Instagram',
    'Whatsapp',
] as const;
export type ChannelProviderType = (typeof ChannelProviderTypes)[number];

@Injectable({
    providedIn: 'root',
})
export class ChannelProviderService {
    private _channelProviderConfigurations: Map<
        ChannelProviderType,
        IRbacChannelProviderConfiguration[]
    > = new Map();

    setChannelProviderConfigurations(
        channelProvider: ChannelProviderType,
        rbacConfigurations: IRbacChannelProviderConfiguration[],
    ) {
        this._channelProviderConfigurations.set(
            channelProvider,
            rbacConfigurations,
        );
    }

    clearChannelProviderConfigurations() {
        this._channelProviderConfigurations.clear();
    }

    getChannelProvidersByWorkflowId(workflowId: string): ChannelProviderType[] {
        const matchingProviders: ChannelProviderType[] = [];

        for (const [providerType, configurations] of this
            ._channelProviderConfigurations) {
            const hasMatchingWorkflow = configurations.some(
                config => config.workflowId === workflowId,
            );

            if (hasMatchingWorkflow) {
                matchingProviders.push(providerType);
            }
        }

        return matchingProviders;
    }
}
