<div class="m-3">
    <div class="font-large">Task wrap up</div>
    <div class="mt-3">
        <div class="dropdown">
            <button
                class="btn btn-dropdown dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
            >
                {{ selectedClosure ? selectedClosure : 'Disposition Codes' }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                @for (item of completionCodes; track item) {
                    <li (click)="selectedClosure = item">
                        <button class="dropdown-item" type="button">
                            {{ item }}
                        </button>
                    </li>
                }
            </ul>
        </div>
    </div>
    <div class="font-small mt-3">Any notes from this task?</div>
    <div>
        <textarea
            placeholder="Notes.."
            class="w-100 notes-text p-2"
            rows="5"
            [ngModel]="notes"
        ></textarea>
    </div>
    <div class="font-small weight-medium mt-3">
        {{ wrapText }}
        @if (!wrapSettings.enabled) {
            <span>{{ wrapSpent | formatDuration }}</span>
        } @else {
            <div class="mt-1">
                <task-progress
                    [totalSeconds]="wrapSettings.timeoutSeconds"
                    [createdAtActual]="now"
                    [isTask]="false"
                    [wrapRestart]="wrapRestart"
                    (wrapTimedOut)="endTask()"
                ></task-progress>
            </div>
        }
    </div>
    <div class="d-flex flex-row justify-content-end pt-3 pb-2">
        @if (wrapSettings.enableWrapupTimerReset) {
            <div class="me-3">
                <button
                    class="btn btn-general"
                    (click)="extendWrap()"
                    [disabled]="wrapRestarts === 0"
                >
                    Extend Wrap
                </button>
            </div>
        }
        <div>
            <button class="btn btn-neutral" (click)="endTask()">
                Complete task
            </button>
        </div>
    </div>
</div>
