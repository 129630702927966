<ngb-progressbar-stacked style="height: 0.3rem">
    @for (state of states; track state.class) {
        <ngb-progressbar
            [type]="state.class"
            [value]="state.value"
            height=".3rem"
        ></ngb-progressbar>
    }
</ngb-progressbar-stacked>

@if (showLabels) {
    <div class="d-flex justify-content-between mt-0" [ngClass]="[font, weight]">
        @for (state of states; track state.class) {
            <div class="text-center label" [style.width.%]="state.value">
                {{ state.value }}%
            </div>
        }
    </div>
}
