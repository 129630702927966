import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { Duration, intervalToDuration } from 'date-fns';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ReplaceSpacesPipe } from '@pipes/replace-spaces/replace-spaces.pipe';
import { FormatDurationPipe } from '@pipes/format-duration/format-duration.pipe';
import { InitialsPipe } from '@pipes/initials/initials.pipe';
import { NotificationHubService } from '@signalr/notification/notification.hub.service';
import { UserService } from '@services/user/user.service';
import { StateService } from '@services/state/state.service';
import { PresenceApiService } from '@api/presence/presence.api.service';
import { AgentHubService } from '@signalr/agent/agent.hub.service';
import { NotificationPanelComponent } from '@components/notification-panel/notification-panel.component';
import { IUser } from '@models/auth/user';

@Component({
    selector: 'header',
    standalone: true,
    imports: [
        CommonModule,
        NgbDropdownModule,
        FormsModule,
        ReplaceSpacesPipe,
        FormatDurationPipe,
        InitialsPipe,
        NotificationPanelComponent,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
    private _intervalSubscription: Subscription | undefined;
    isUserDropdownOpen = false;
    breakNames = this.presenceApiService.breakNames.asReadonly();
    presence = this.agentHubService.presence.asReadonly();
    now = new Date();
    unread = this.notificationHubService.unread;
    isBreakDropdownOpen = false;

    constructor(
        private userService: UserService,
        private notificationHubService: NotificationHubService,
        private presenceApiService: PresenceApiService,
        private stateService: StateService,
        private agentHubService: AgentHubService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
    ) {}

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this._intervalSubscription = interval(1000).subscribe(() => {
                this.now = new Date();

                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
    }

    ngOnDestroy() {
        if (this._intervalSubscription) {
            this._intervalSubscription.unsubscribe();
        }
    }

    toggleDropdownOpenState(isOpen: boolean) {
        this.isUserDropdownOpen = isOpen;
    }

    toggleBreakDropdownOpenState(isOpen: boolean) {
        this.isBreakDropdownOpen = isOpen;
    }

    toggleNotificationPanel() {
        this.notificationHubService.open(NotificationPanelComponent, {
            position: 'end',
        });
    }

    changePresence() {
        if (!this.presence().online) {
            if (this.presence().state === 'LoggedOut') {
                this.stateService.onLogin();
            } else {
                this.stateService.onActivate();
            }
        } else {
            this.stateService.onLogoff();
        }
    }

    goToBreak(breakName: string) {
        this.presenceApiService.goToBreak(breakName);
    }

    get stateSince(): Duration {
        return intervalToDuration({
            start: new Date(this.presence().stateSince),
            end: this.now,
        });
    }

    get user(): IUser {
        return this.userService.user();
    }
}
