import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    computed,
    effect,
} from '@angular/core';
import {
    INotificationModel,
    IUser,
    IVoiceLogin,
    IVoiceServers,
    IVoiceStunServers,
} from '@dxp/shared/models';
import {
    NotificationModalComponent,
    NotificationToastComponent,
} from '@dxp/shared/components';
import { Observable, Subscription } from 'rxjs';
import {
    QueueService,
    SettingsService,
    SipService,
    UserService,
} from '@dxp/shared/services';

import { AuthService } from '@dxp/shared/api';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './base/components/header/header.component';
import { MainLayoutComponent } from './base/components/main-layout/main-layout.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationHubService } from '@dxp/shared/signalr';
import { SideNavComponent } from './base/components/side-nav/container/side-nav.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        SideNavComponent,
        MainLayoutComponent,
        HeaderComponent,
        NotificationToastComponent,
    ],
    selector: 'app-shell-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('localAudio') localAudio!: ElementRef<HTMLAudioElement>;
    @ViewChild('remoteAudio') remoteAudio!: ElementRef<HTMLAudioElement>;

    private _notificationSubscription!: Subscription;
    isCollapsed = false;
    showModal = false;
    notification: Observable<INotificationModel | null>;

    loading = computed<boolean>(() => {
        return (
            this.queueService.queues().length === 0 ||
            this.queueService.queues().some(q => q.information === undefined)
        );
    });

    asteriskServers = computed<IVoiceServers | undefined>(() =>
        this.settingsService.asteriskServers(),
    );

    asteriskStunServers = computed<IVoiceStunServers | undefined>(() =>
        this.settingsService.asteriskStunServers(),
    );

    asteriskLogin = computed<IVoiceLogin | undefined>(() =>
        this.settingsService.asteriskLogin(),
    );

    asteriskRegister = false;

    constructor(
        private modalService: NgbModal,
        private authService: AuthService,
        private userService: UserService,
        private notificationHubService: NotificationHubService,
        private queueService: QueueService,
        private sipService: SipService,
        private settingsService: SettingsService,
    ) {
        this.notification = this.notificationHubService.notificationAlert;
        effect(() => {
            if (this.localAudio && this.sipService.localStream()) {
                this.localAudio.nativeElement.srcObject =
                    this.sipService.localStream();
            }

            if (
                this.asteriskServers() &&
                this.asteriskStunServers() &&
                this.asteriskLogin() &&
                !this.asteriskRegister
            ) {
                this.asteriskRegister = true;
                if (this.userService.hasPermission('voice.read')) {
                    this.registerAsterisk();
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.authService.initializeAuthentication();

        this._notificationSubscription =
            this.notificationHubService.notificationAlert.subscribe(
                notification => {
                    if (notification) {
                        const modalRef = this.modalService.open(
                            NotificationModalComponent,
                            {
                                centered: true,
                                keyboard: false,
                                backdrop: 'static',
                                windowClass: 'modal-wrap',
                            },
                        );

                        modalRef.componentInstance.notification = notification;
                        modalRef.componentInstance.show();
                    } else {
                        this.modalService.dismissAll();
                    }
                },
            );
    }

    registerAsterisk() {
        const servers = (this.asteriskServers()!.data || []).map(item => ({
            uri: `${item.server}.call-view.com`,
        }));

        const stunServers = (this.asteriskStunServers()!.data || []).map(
            item => item.dataValue,
        );

        const login = this.asteriskLogin()!.data?.[0];

        this.sipService.initializeSipClients(
            servers,
            stunServers,
            `webrtc_${login.username}`,
            login.secret,
        );
    }

    ngOnDestroy() {
        if (this._notificationSubscription) {
            this._notificationSubscription.unsubscribe();
        }
    }

    get user(): IUser {
        return this.userService.user();
    }
}
