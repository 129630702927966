export enum ErrorType {
    ConnectionFailed = 'ConnectionFailed',
    TransferToQueueFailed = 'TransferToQueueFailed',
    InitiateTransferFailed = 'InitiateTransferFailed',
    CancelTransferFailed = 'CancelTransferFailed',
}

export enum ErrorReason {
    ConversationNotFound = 'ConversationNotFound',
    Unknown = 'Unknown',
    TransferAlreadyInProgress = 'TransferAlreadyInProgress',
    InitiateTransferFailed = 'InitiateTransferFailed',
    TransferTimeout = 'TransferTimeout',
    NoTransferInProgress = 'NoTransferInProgress',
    TransferRejected = 'TransferRejected',
}
