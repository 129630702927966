import { EnvironmentType } from '@models/configuration/environment';

export interface INavLink {
    routeLink: string;
    icon: string;
    label: string;
    userType?: string;
    permission?: string;
    environments?: EnvironmentType[];
}

export const navbarLinks: INavLink[] = [
    {
        routeLink: 'dashboard',
        icon: 'fa-house',
        label: 'Home',
    },
    {
        routeLink: 'performance',
        icon: 'fa-chart-simple',
        label: 'Performance',
        permission: 'reports.read',
    },
    {
        routeLink: 'chats/voice',
        icon: 'fa-phone',
        label: 'Voice',
        permission: 'voice.read',
    },
    {
        routeLink: 'chats/email',
        icon: 'fa-envelope',
        label: 'Email',
        permission: 'email.read',
    },
    {
        routeLink: 'debug',
        icon: 'fa-debug',
        label: 'Debug',
        environments: [EnvironmentType.Dev, EnvironmentType.Local],
    },
];
