import { IConversation } from '../../models/agent/agent-state';

export interface IIncomingTask {
    order: number;
    workItemId: string;
    conversationId: string;
    mediaType: string;
    channelType: string;
    createdAt: string;
    createdAtActual: Date;
    queueName: string;
    icon: string;
    iconSelected: string;
    selected: boolean;
    classSelected: string;
    navigation: string;
    workItemState: string;
    closureCodes: string[];
    converations: IConversation[];
}
