import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITemplate } from '../search-list/search-list.component';
import { TelXLButtonComponent } from '../button/button.component';
import { TelXLPanelComponent } from '../panel/panel.component';

@Component({
    selector: 'telxl-search-preview',
    standalone: true,
    imports: [CommonModule, TelXLButtonComponent, TelXLPanelComponent],
    templateUrl: './search-preview.component.html',
    styleUrl: './search-preview.component.scss',
})
export class TelXLSearchPreviewComponent {
    @Input() searchPreview: ITemplate | undefined;
    @Output() searchPreviewEmitter = new EventEmitter<{
        item: ITemplate;
        buttonType: string;
    }>();

    onSearchPreviewClick(): void {
        if (this.searchPreview) {
            const event = { item: this.searchPreview, buttonType: 'use' };

            this.searchPreviewEmitter.emit(event);
        }
    }
}
