import { CommonModule } from '@angular/common';
import { Component, Signal, computed } from '@angular/core';
import '@angular/localize/init';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import {
    INotificationModel,
    NotificationActionType,
    NotificationType,
} from '../../models/notification/notification';
import { NotificationHubService } from '../../signalr/notification/notification.hub.service';

@Component({
    selector: 'notification-toasts',
    imports: [CommonModule, NgbToastModule],
    standalone: true,
    templateUrl: './notification-toast.component.html',
    styleUrl: './notification-toast.component.scss',
})
export class NotificationToastComponent {
    private notifications: Signal<INotificationModel[]>;

    tier2 = computed<INotificationModel[]>(() =>
        this.notifications().filter(n => n.type === NotificationType.Tier2),
    );

    constructor(private notificationHubService: NotificationHubService) {
        this.notifications = notificationHubService.notifications;
    }

    dismissNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Dismissed,
        );
    }

    actionNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Actioned,
        );
    }
}
