import {
    EnvironmentType,
    IEnvironmentSettings,
} from '../models/configuration/environment';
import { LogLevel } from '../models/configuration/log';

export const createEnvironmentSettings = (baseSettings: {
    production: boolean;
    logLevel: LogLevel;
    authority: string;
    apiBaseUrl: string;
    environment: EnvironmentType;
    scope: string;
    heartbeatInterval: number;
    pollingInterval: number;
    cacheLifeTime: number;
}): IEnvironmentSettings => ({
    ...baseSettings,
    resize: {
        dashboard: {
            standard: '350',
            expanded: '350',
        },
        performance: {
            standard: '350',
            expanded: '430',
        },
    },
    blenderUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/blender'
    }`,
    rbacUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/rbac'
    }`,
    voiceUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/telxl-voice'
    }`,
    liveUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/live'
    }`,
    aggregationsUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local
            ? ''
            : '/aggregations'
    }`,
    directoryUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/directory'
    }`,
});
