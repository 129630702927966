import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { environment } from './../../environments';
import { ICustomerDetails } from './../../models';
import { LoggerService } from './../../services';

@Injectable({
    providedIn: 'root',
})
export class CustomerApiService {
    customer: WritableSignal<ICustomerDetails | null> =
        signal<ICustomerDetails | null>(null);

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getCustomer(customerId: string) {
        this.http
            .get<ICustomerDetails>(
                `${environment.apiBaseUrl}/customer-manager/v3/Customers/${customerId}`,
            )
            .subscribe({
                next: cust => {
                    this.logger.debug(
                        'Customer Service (Get customer) -> Successful',
                    );
                    this.customer.set(cust);
                },
                error: error => {
                    this.logger.error(
                        'Customer Service (Get customer) -> Failed',
                        error,
                    );
                    this.customer.set(null);
                },
            });
    }
}
