import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

@Component({
    selector: 'voice-call-button',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './voice-call-button.component.html',
    styleUrl: './voice-call-button.component.scss',
})
export class VoiceCallButtonComponent {
    @Output() buttonClicked = new EventEmitter<string>();
    @Input() icon!: string;
    @Input() iconSelected!: string;
    @Input() title!: string;
    @Input() titleSelected!: string;
    @Input() isClicked? = false;

    onButtonClick(): void {
        this.buttonClicked.emit(
            this.isClicked ? this.titleSelected : this.title,
        );
        this.isClicked = !this.isClicked;
    }
}
