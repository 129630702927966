import { computed, Injectable, signal } from '@angular/core';
import {
    BusinessUnit,
    IBusinessUnit,
} from '@models/business-unit/business-unit';
import { Queue } from '@models/queue/queue';
import { RbacApiService } from '@api/rbac/rbac.api.service';
import { TenantService } from '@services/tenant/tenant.service';
import { IRbacBusinessUnit } from '@models/rbac/business-unit';
import { ITelXLKpiQueueThreshold } from '@models/rbac/queue-kpi';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    private _businessUnits = signal<BusinessUnit[]>([]);
    businessUnits = computed<IBusinessUnit[]>(() =>
        this._businessUnits.asReadonly()(),
    );
    loading = signal<boolean>(true);

    constructor(
        private rbacApiService: RbacApiService,
        private tenantService: TenantService,
    ) {}

    set(businessUnits: IRbacBusinessUnit[]) {
        this._businessUnits.set(
            businessUnits.map(bu => new BusinessUnit(bu.id, bu.name, [])),
        );

        this._businessUnits
            .asReadonly()()
            .forEach(businessUnit =>
                this.rbacApiService
                    .getKpiThresholds(businessUnit.id, 'businessunits')
                    .subscribe({
                        next: kpiThresholds => {
                            if (kpiThresholds) {
                                this.setKpi(
                                    businessUnit.id,
                                    kpiThresholds.kpiTelXLThreshold as ITelXLKpiQueueThreshold,
                                );
                            }
                        },
                        complete: () => this.loading.set(false),
                    }),
            );
    }

    getKpi(businessUnitId: string): ITelXLKpiQueueThreshold {
        return (
            this._businessUnits
                .asReadonly()()
                .find(bu => bu.id === businessUnitId) as BusinessUnit
        ).kpiTelXLThreshold;
    }

    setKpi(businessUnitId: string, telXLKpiThreshold: ITelXLKpiQueueThreshold) {
        const businessUnitUpdate = this._businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === businessUnitId
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          bu._queues,
                          telXLKpiThreshold,
                      )
                    : bu,
            );

        this._businessUnits.set(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }

    updateQueue(queue: Queue) {
        if (!queue.businessUnit || !queue.name || queue.name.length === 0)
            return;

        const businessUnit = this._businessUnits
            .asReadonly()()
            .find(bu => bu.id === queue.businessUnit);

        const queues = [
            ...(businessUnit?._queues.filter(q => q.id !== queue.id) ?? []),
            ...[queue],
        ];

        const businessUnitUpdate = this._businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === queue.businessUnit
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          queues,
                          bu.kpiTelXLThreshold,
                      )
                    : bu,
            );

        this._businessUnits.set(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }
}
