<div class="h-100" [ngClass]="compact ? 'pt-1 pb-3' : 'pt-3 pb-3'">
    <div class="d-flex justify-content-between">
        <div
            class="title"
            [ngClass]="
                compact
                    ? 'font-small weight-heavy'
                    : 'font-medium weight-medium'
            "
        >
            @if (icon) {
                <i [ngClass]="icon" class="me-1"></i>
            }
            {{ title }}
        </div>
        <div class="form-check form-switch">
            <input
                class="form-check-input"
                [ngClass]="{
                    'bg-primary border-primary': isEnabled,
                    'bg-secondary border-secondary': !isEnabled,
                }"
                type="checkbox"
                role="switch"
                [(ngModel)]="isEnabled"
                (ngModelChange)="onEnabledValueChange($event)"
            />
        </div>
    </div>
    <div [ngClass]="panelClass">
        @switch (settingPanelType) {
            @case (SettingPanelType.Numeric) {
                @if (isTeamThreshold) {
                    <div class="col-4" [ngClass]="compact ? '' : 'mt-2'">
                        <spinner-input
                            title="Target"
                            [isEnabled]="isEnabled"
                            [minValue]="min"
                            [maxValue]="max"
                            [value]="teamValue.target"
                            [error]="!isValid"
                            (valueChange)="onTargetValueChange($event)"
                        />
                    </div>
                }

                <div class="col-4" [ngClass]="compact ? '' : 'mt-2'">
                    <spinner-input
                        title="Low"
                        [isEnabled]="isEnabled"
                        [minValue]="min"
                        [maxValue]="max"
                        [value]="value.low"
                        [error]="!isValid"
                        (valueChange)="onLowValueChange($event)"
                    />
                </div>

                <div class="col-4" [ngClass]="compact ? '' : 'mt-2'">
                    <spinner-input
                        title="High"
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="100"
                        [value]="value.high"
                        [error]="!isValid"
                        (valueChange)="onHighValueChange($event)"
                    />
                </div>
            }
            @case (SettingPanelType.Percentage) {
                @if (isTeamThreshold) {
                    <div class="col-4" [ngClass]="compact ? '' : 'mt-2'">
                        <spinner-input
                            title="Target"
                            [isEnabled]="isEnabled"
                            [minValue]="min"
                            [maxValue]="max"
                            [value]="teamValue.target * 100"
                            [error]="!isValid"
                            placeholder="%"
                            (valueChange)="onTargetValueChange($event)"
                        />
                    </div>
                }

                <div class="col-4" [ngClass]="compact ? '' : 'mt-2'">
                    <spinner-input
                        title="Low"
                        [isEnabled]="isEnabled"
                        [minValue]="min"
                        [maxValue]="max"
                        [value]="value.low * 100"
                        [error]="!isValid"
                        placeholder="%"
                        (valueChange)="onLowValueChange($event)"
                    />
                </div>

                <div class="col-4" [ngClass]="compact ? '' : 'mt-2'">
                    <spinner-input
                        title="High"
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="100"
                        [value]="value.high * 100"
                        [error]="!isValid"
                        placeholder="%"
                        (valueChange)="onHighValueChange($event)"
                    />
                </div>
            }
            @case (SettingPanelType.Time) {
                @if (isTeamThreshold) {
                    <div
                        class="w-100 d-flex justify-content-between gap-2"
                        [ngClass]="compact ? '' : 'mt-2'"
                    >
                        <spinner-input
                            title="Target"
                            [isEnabled]="isEnabled"
                            [minValue]="0"
                            [maxValue]="31"
                            [value]="
                                secondsToDuration(this.teamValue.target).days ??
                                0
                            "
                            [error]="!isValid"
                            placeholder="D"
                            [twoDigit]="true"
                            (valueChange)="
                                onTargetValueTimeChange({
                                    type: 'D',
                                    newValue: $event,
                                })
                            "
                        />
                        <spinner-input
                            [isEnabled]="isEnabled"
                            [minValue]="0"
                            [maxValue]="23"
                            [value]="
                                secondsToDuration(this.teamValue.target)
                                    .hours ?? 0
                            "
                            [error]="!isValid"
                            placeholder="H"
                            [twoDigit]="true"
                            (valueChange)="
                                onTargetValueTimeChange({
                                    type: 'H',
                                    newValue: $event,
                                })
                            "
                        />
                        <spinner-input
                            [isEnabled]="isEnabled"
                            [minValue]="0"
                            [maxValue]="59"
                            placeholder="M"
                            [twoDigit]="true"
                            [value]="
                                secondsToDuration(this.teamValue.target)
                                    .minutes ?? 0
                            "
                            [error]="!isValid"
                            (valueChange)="
                                onTargetValueTimeChange({
                                    type: 'M',
                                    newValue: $event,
                                })
                            "
                        />
                        <spinner-input
                            [isEnabled]="isEnabled"
                            [minValue]="0"
                            [maxValue]="59"
                            placeholder="S"
                            [twoDigit]="true"
                            [value]="
                                secondsToDuration(this.teamValue.target)
                                    .seconds ?? 0
                            "
                            [error]="!isValid"
                            (valueChange)="
                                onTargetValueTimeChange({
                                    type: 'S',
                                    newValue: $event,
                                })
                            "
                        />
                    </div>
                }

                <div
                    class="w-100 d-flex justify-content-between gap-2"
                    [ngClass]="compact ? '' : 'mt-2'"
                >
                    <spinner-input
                        title="Low"
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="31"
                        [value]="secondsToDuration(this.value.low).days ?? 0"
                        [error]="!isValid"
                        placeholder="D"
                        [twoDigit]="true"
                        (valueChange)="
                            onLowValueTimeChange({
                                type: 'D',
                                newValue: $event,
                            })
                        "
                    />
                    <spinner-input
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="23"
                        [value]="secondsToDuration(this.value.low).hours ?? 0"
                        [error]="!isValid"
                        placeholder="H"
                        [twoDigit]="true"
                        (valueChange)="
                            onLowValueTimeChange({
                                type: 'H',
                                newValue: $event,
                            })
                        "
                    />
                    <spinner-input
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="59"
                        placeholder="M"
                        [twoDigit]="true"
                        [value]="secondsToDuration(this.value.low).minutes ?? 0"
                        [error]="!isValid"
                        (valueChange)="
                            onLowValueTimeChange({
                                type: 'M',
                                newValue: $event,
                            })
                        "
                    />
                    <spinner-input
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="59"
                        placeholder="S"
                        [twoDigit]="true"
                        [value]="secondsToDuration(this.value.low).seconds ?? 0"
                        [error]="!isValid"
                        (valueChange)="
                            onLowValueTimeChange({
                                type: 'S',
                                newValue: $event,
                            })
                        "
                    />
                </div>

                <div class="w-100 mt-2 d-flex justify-content-between gap-2">
                    <spinner-input
                        title="High"
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="31"
                        placeholder="D"
                        [twoDigit]="true"
                        [value]="secondsToDuration(this.value.high).days ?? 0"
                        [error]="!isValid"
                        (valueChange)="
                            onHighValueTimeChange({
                                type: 'D',
                                newValue: $event,
                            })
                        "
                    />
                    <spinner-input
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="23"
                        placeholder="H"
                        [twoDigit]="true"
                        [value]="secondsToDuration(this.value.high).hours ?? 0"
                        [error]="!isValid"
                        (valueChange)="
                            onHighValueTimeChange({
                                type: 'H',
                                newValue: $event,
                            })
                        "
                    />
                    <spinner-input
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="59"
                        placeholder="M"
                        [twoDigit]="true"
                        [value]="
                            secondsToDuration(this.value.high).minutes ?? 0
                        "
                        [error]="!isValid"
                        (valueChange)="
                            onHighValueTimeChange({
                                type: 'M',
                                newValue: $event,
                            })
                        "
                    />
                    <spinner-input
                        [isEnabled]="isEnabled"
                        [minValue]="0"
                        [maxValue]="59"
                        placeholder="S"
                        [twoDigit]="true"
                        [value]="
                            secondsToDuration(this.value.high).seconds ?? 0
                        "
                        [error]="!isValid"
                        (valueChange)="
                            onHighValueTimeChange({
                                type: 'S',
                                newValue: $event,
                            })
                        "
                    />
                </div>
            }
        }
    </div>
</div>
