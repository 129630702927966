export enum Role {
    Agent = 'Agent',
    Customer = 'Customer',
    Bot = 'Bot',
    Supervisor = 'Supervisor',
}

export interface IParticipant {
    id: string;
    role: Role;
    name?: string;
    isInternal: boolean;
    startedTypingAt: string;
}

export interface ICustomer {
    isTyping: boolean;
    updated: string;
}
