<div class="border">
        @if (searchPreview) {
            <div tabindex="0" class="d-flex flex-column p-5" style="height: 100%">
                <div class="font-lg weight-medium">
                    {{ searchPreview.title }}
                </div>
                {{ searchPreview.item }}

                <div class="mt-auto align-self-end">
                    <telxl-button
                        colour="primary"
                        (buttonClick)="onSearchPreviewClick()"
                        (keydown.enter)="onSearchPreviewClick()"
                    >
                        Use
                    </telxl-button>
                </div>
            </div>
        }
    </div>
