export interface IRoleModel {
    id: string;
    tenantId: string;
    name: string;
    permissions: IPermission[];
}

export interface IPermission {
    businessEntity: BusinessEntity;
    permissionType: PermissionType;
}

export enum BusinessEntity {
    Agent = 'Agent',
    Announcement = 'Announcement',
    Campaign = 'Campaign',
    Customer = 'Customer',
    Email = 'Email',
    Facebook = 'Facebook',
    Instagram = 'Instagram',
    Integration = 'Integration',
    MessageConsumption = 'MessageConsumption',
    Reports = 'Reports',
    Role = 'Role',
    User = 'User',
    Tenant = 'Tenant',
    TenantSettings = 'TenantSettings',
    Viber = 'Viber',
    Voice = 'Voice',
    WebChat = 'WebChat',
    WhatsApp = 'WhatsApp',
    Twitter = 'Twitter',
}

export enum PermissionType {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete',
}
