<div class="attachment-wrapper">
    <telxl-attachment-button (attachClicked)="handleAttachmentClick()" />

    <input
        type="file"
        #fileInput
        class="d-none"
        (change)="onFileSelected($event)"
        multiple
    />

    <div *ngIf="attachments.length > 0" class="mt-2">
        <ul class="list-group">
            <li
                *ngFor="let file of attachments"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <span>{{ file.name }}</span>
                <button
                    class="btn btn-sm btn-danger"
                    (click)="removeAttachment(file)"
                >
                    <i class="fa-solid fa-trash"></i>
                </button>
            </li>
        </ul>
    </div>
</div>
