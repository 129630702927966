import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { TelXLButtonComponent } from '../../button/button.component';
import { TelXLPanelComponent } from '../../panel/panel.component';
import {
    ITemplate,
    TelXLSearchListComponent,
} from '../../search-list/search-list.component';
import { TelXLSearchPreviewComponent } from '../../search-preview/search-preview.component';

@Component({
    selector: 'telxl-template',
    standalone: true,
    imports: [
        CommonModule,
        TelXLSearchListComponent,
        TelXLButtonComponent,
        TelXLSearchPreviewComponent,
        TelXLPanelComponent,
    ],
    templateUrl: './templates.component.html',
    styleUrl: './templates.component.scss',
})
export class TelXLTemplatesComponent {
    @Input() items!: ITemplate[];
    @Output() itemEmitter = new EventEmitter<{
        template: ITemplate;
        action: string;
    }>();
    showPreview = signal<ITemplate | undefined>(undefined);

    handleButtonClick(event: { item: ITemplate; buttonType: string }) {
        this.showPreview.set(event.item)
        
        this.itemEmitter.emit({
            template: event.item,
            action: event.buttonType,
        });
    }
}
