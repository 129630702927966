import { toPercentage } from '../../helpers/metric';
import {
    BusinessUnit,
    IBusinessUnit,
    IBusinessUnitStatistics,
} from '../../models/business-unit/business-unit';
import { IMetric, IValueWithMetric } from '../../models/metrics/metric';
import { ChannelGroupType } from '../../models/queue/queue';
import {
    ITelXLKpiQueueThreshold,
    TelXLKpiQueueThresholdDefaultState,
} from '../../models/rbac/queue-kpi';

export interface ITenant {
    id: string;
    name: string;
    kpiTelXLThreshold: ITelXLKpiQueueThreshold;
    businessUnits: IBusinessUnit[];
    activeBusinessUnits: BusinessUnit[];
    channelGroups: ChannelGroupType[];

    statistics(index?: string): ITenantStatistics;
}

export interface ITenantStatistics {
    answeredWithinSLAPercentage: IValueWithMetric;
    answeredWithinSLAPercentageMetricByBusinessUnit: IMetric[];
    waiting: IValueWithMetric;
    connected: number;
    success: number;
    complete: number;
    timedOut: number;
    abandoned: number;
    outOfSla: number;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: IValueWithMetric;
    avgWaitingTimeInSeconds: IValueWithMetric;
}

export class Tenant implements ITenant {
    id: string;
    name: string;
    _businessUnits: BusinessUnit[];
    private _kpiTelXLThreshold: ITelXLKpiQueueThreshold;

    constructor(
        id: string,
        name: string,
        businessUnits: BusinessUnit[],
        kpiTelXLThreshold?: ITelXLKpiQueueThreshold,
    ) {
        this.id = id;
        this.name = name;
        this._businessUnits = businessUnits;
        this._kpiTelXLThreshold =
            kpiTelXLThreshold ?? TelXLKpiQueueThresholdDefaultState;
    }

    get businessUnits(): IBusinessUnit[] {
        return this._businessUnits;
    }

    get activeBusinessUnits(): BusinessUnit[] {
        return this._businessUnits.filter(bu => bu.hasActivity);
    }

    get configuredBusinessUnits(): IBusinessUnit[] {
        return this._businessUnits.filter(bu => bu.hasConfigured);
    }

    get kpiTelXLThreshold(): ITelXLKpiQueueThreshold {
        return this._kpiTelXLThreshold;
    }

    get channelGroups(): ChannelGroupType[] {
        return [
            ...new Set(
                this.configuredBusinessUnits.flatMap(bu => bu.channelGroups),
            ),
        ];
    }

    statistics(index?: ChannelGroupType): ITenantStatistics {
        if (index === undefined) {
            return this.calculatePerformance(
                this.activeBusinessUnits.map(bu => bu.statistics()),
            );
        }

        return this.calculatePerformance(
            this.activeBusinessUnits.map(bu => bu.statistics(index)),
        );
    }

    private calculatePerformance(
        businessUnitStatistics: IBusinessUnitStatistics[],
    ): ITenantStatistics {
        if (businessUnitStatistics.length === 0) {
            return tenantStatisticDefault();
        }

        const currentLongestWaitingTimeStamp = businessUnitStatistics
            .map(bus => bus.currentLongestWaitingTimeStamp)
            .filter((timeStamp): timeStamp is Date => timeStamp !== undefined)
            .reduce((minTimeStamp: Date | undefined, current: Date) => {
                return minTimeStamp === undefined ||
                    current.getTime() < minTimeStamp.getTime()
                    ? current
                    : minTimeStamp;
            }, undefined);

        const answeredWithinSLAPercentageMetricByBusinessUnit =
            businessUnitStatistics.map(
                bus => bus.answeredWithinSLAPercentage.metric,
            );

        const answeredWithinSLAPercentage = Math.round(
            businessUnitStatistics.reduce(
                (total: number, bus: IBusinessUnitStatistics) => {
                    return total + bus.answeredWithinSLAPercentage.value;
                },
                0,
            ) / businessUnitStatistics.length,
        );

        const waiting = businessUnitStatistics.reduce(
            (total: number, bus: IBusinessUnitStatistics) => {
                return total + bus.waiting.value;
            },
            0,
        );

        const connected = businessUnitStatistics.reduce(
            (total: number, bus: IBusinessUnitStatistics) => {
                return total + bus.connected;
            },
            0,
        );

        const success = businessUnitStatistics.reduce(
            (total: number, bus: IBusinessUnitStatistics) => {
                return total + bus.success;
            },
            0,
        );

        const complete = businessUnitStatistics.reduce(
            (total: number, bus: IBusinessUnitStatistics) => {
                return total + bus.complete;
            },
            0,
        );

        const outOfSla = businessUnitStatistics.reduce(
            (total: number, bus: IBusinessUnitStatistics) => {
                return total + bus.outOfSla;
            },
            0,
        );

        const timedOut = businessUnitStatistics.reduce(
            (total: number, bus: IBusinessUnitStatistics) => {
                return total + bus.timedOut;
            },
            0,
        );

        const abandoned = businessUnitStatistics.reduce(
            (total: number, bus: IBusinessUnitStatistics) => {
                return total + bus.abandoned;
            },
            0,
        );

        const longestWaitingTimeInSeconds = businessUnitStatistics.reduce(
            (maxLWT: number, bus: IBusinessUnitStatistics) => {
                return Math.max(maxLWT, bus.longestWaitingTimeInSeconds.value);
            },
            0,
        );

        const avgWaitingTimeInSeconds = businessUnitStatistics.reduce(
            (maxAWT: number, bus: IBusinessUnitStatistics) => {
                return Math.max(maxAWT, bus.avgWaitingTimeInSeconds.value);
            },
            0,
        );

        return {
            answeredWithinSLAPercentageMetricByBusinessUnit:
                answeredWithinSLAPercentageMetricByBusinessUnit,
            answeredWithinSLAPercentage: {
                value: answeredWithinSLAPercentage,
                metric: {
                    percent: answeredWithinSLAPercentage,
                    states: this.kpiTelXLThreshold.slaThreshold.enabled
                        ? [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.low,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.high,
                                  ),
                                  state: 'success',
                              },
                          ]
                        : [],
                },
            },
            waiting: {
                value: waiting,
                metric: this.kpiTelXLThreshold.maxConversationsWaiting.enabled
                    ? {
                          percent: toPercentage(
                              waiting /
                                  this.kpiTelXLThreshold.maxConversationsWaiting
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxConversationsWaiting.low /
                                          this.kpiTelXLThreshold
                                              .maxConversationsWaiting.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            connected: connected,
            currentLongestWaitingTimeStamp: currentLongestWaitingTimeStamp,
            longestWaitingTimeInSeconds: {
                value: longestWaitingTimeInSeconds,
                metric: this.kpiTelXLThreshold.maxLongestWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              longestWaitingTimeInSeconds /
                                  this.kpiTelXLThreshold
                                      .maxLongestWaitTimeSeconds.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            avgWaitingTimeInSeconds: {
                value: avgWaitingTimeInSeconds,
                metric: this.kpiTelXLThreshold.averageWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              (avgWaitingTimeInSeconds ?? 0) /
                                  this.kpiTelXLThreshold.averageWaitTimeSeconds
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .averageWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .averageWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            success: success,
            complete: complete,
            outOfSla: outOfSla,
            timedOut: timedOut,
            abandoned: abandoned,
        };
    }
}

export const tenantStatisticDefault = (): ITenantStatistics => {
    return {
        answeredWithinSLAPercentageMetricByBusinessUnit: [],
        answeredWithinSLAPercentage: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        waiting: { value: 0, metric: { percent: 0, states: [] } },
        connected: 0,
        success: 0,
        complete: 0,
        timedOut: 0,
        abandoned: 0,
        outOfSla: 0,
        longestWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        avgWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
    };
};
