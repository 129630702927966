<div class="position-relative w-100 h-100">
    <!-- Overlay Container -->
    <div
        class="overlay position-absolute top-50 start-0 w-100 h-100 d-flex flex-column"
        [ngClass]="{
            'd-none': !isOverlayVisible,
            'fade-in': isOverlayVisible,
            'fade-out': !isOverlayVisible,
        }"
        [ngStyle]="{ 'background-color': overlayBackground, 'z-index': 1050 }"
    >
        <!-- Top Section with Reduced Opacity -->
        <div
            class="flex-grow-1 bg-dark"
            [ngStyle]="{ opacity: topOpacity, minHeight: '200px' }"
        ></div>

        <!-- Bottom Section with Content -->
        <div class="bg-white p-3 shadow-lg">
            <ng-content></ng-content>
        </div>
    </div>
</div>
