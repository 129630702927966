import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelXLAttachmentButtonComponent } from '../../attachment-button/attachment-button.component';

@Component({
    selector: 'telxl-attachment',
    standalone: true,
    imports: [CommonModule, TelXLAttachmentButtonComponent],
    templateUrl: './attachment.component.html',
    styleUrl: './attachment.component.scss',
})
export class TelXLAttachmentComponent {
    attachments: File[] = [];

    handleAttachmentClick() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.multiple = true;
      fileInput.addEventListener('change', (event: any) => this.onFileSelected(event));
      fileInput.click();
    }
  
    onFileSelected(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        this.attachments = [...this.attachments, ...Array.from(input.files)];
      }
    }
  
    removeAttachment(file: File) {
      this.attachments = this.attachments.filter(att => att !== file);
    }
}
