<div class="d-flex w-100 border p-2">
    <telxl-search-list
        class="w-50  border p-2 m-1"
        [items]="items"
        (itemEmitter)="handleButtonClick($event)"
    >
        <ng-template let-item let-clickFn="clickHandler">
            <div class="d-flex justify-content-end gap-2 w-100">
                <telxl-button
                    colour="secondary"
                    [outline]="true"
                    class="w-25"
                    (buttonClick)="clickFn(item, 'preview')"
                    tabindex="0"
                    (keydown)="clickFn(item, 'preview')"
                >
                    Preview
                </telxl-button>

                <telxl-button
                    colour="primary"
                    class="w-25"
                    [outline]="true"
                    (buttonClick)="clickFn(item, 'use')"
                    tabindex="0"
                    (keydown)="clickFn(item, 'use')"
                >
                    Use
                </telxl-button>
            </div>
        </ng-template>
    </telxl-search-list>

    <div class="w-50 d-flex align-items-center  border p-2 m-1">
            @if (showPreview()) {
            <telxl-search-preview
                [searchPreview]="showPreview()"
                (searchPreviewEmitter)="handleButtonClick($event)"
            />
        }
        </div>
</div>
