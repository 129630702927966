export interface IKpiTeamThreshold {
    averageHandlingTimeSeconds: {
        Voice: number | null;
        Email: number | null;
        Chat: number | null;
        External: number | null;
    };
    handledRatio: {
        Voice: number | null;
        Email: number | null;
        Chat: number | null;
        External: number | null;
    };
}

export interface ITeamThreshold {
    target: number;
    low: number;
    high: number;
    enabled: boolean;
}

export interface ITelXLKpiTeamThreshold {
    averageHandlingTimeSeconds: {
        voice: ITeamThreshold;
        email: ITeamThreshold;
        webchat: ITeamThreshold;
        socials: ITeamThreshold;
        average: ITeamThreshold;
    };
    handledRatio: {
        voice: number | null;
        email: number | null;
        webchat: number | null;
        socials: number | null;
    };
}

export const TelXLKpiTeamThresholdDefaultState: ITelXLKpiTeamThreshold = {
    averageHandlingTimeSeconds: {
        voice: {
            target: 0,
            low: 0,
            high: 0,
            enabled: false,
        },
        email: {
            target: 0,
            low: 0,
            high: 0,
            enabled: false,
        },
        webchat: {
            target: 0,
            low: 0,
            high: 0,
            enabled: false,
        },
        socials: {
            target: 0,
            low: 0,
            high: 0,
            enabled: false,
        },
        average: {
            target: 0,
            low: 0,
            high: 0,
            enabled: false,
        },
    },
    handledRatio: {
        voice: 0,
        email: 0,
        webchat: 0,
        socials: 0,
    },
};

export const compareTeamThreshold = (
    a: ITeamThreshold,
    b: ITeamThreshold,
): boolean => {
    return (
        a.low !== b.low ||
        a.high !== b.high ||
        a.target !== b.target ||
        a.enabled !== b.enabled
    );
};
