import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { findStateForMetric } from '../../../helpers/metric';
import { getColourValue } from '../../../helpers/style';
import { IMetric } from '../../../models/metrics/metric';

/**
 * Component to display nested ring charts.
 * Each ring represents a percentage value with a colour code indicating the status.
 *
 * @selector rings
 * @standalone true
 * @component RingsComponent
 * @imports CommonModule, BaseChartDirective
 *
 * @Input metrics - Array of objects conforming to IMetric interface, each representing a ring in the chart.
 * @Input label - Text to display.
 * @Input overall - The summary value for the data.
 *
 * Usage:
 * <rings [metric]="data" [label]="label" [overall]="overall"></rings>
 *
 * Note:
 * A data item contains the percent value of the ring and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [{25, 'success'}, {75, 'danger'}]}
 * In this scenario a percent value over 25 will render success state and over 75 will render danger state.
 *
 * e.g. { percent: 30, state: [{50, 'warning'}]}
 * In this scenario a percent value over 50 will render warning state. No red state is configured.
 *
 */
@Component({
    selector: 'rings',
    standalone: true,
    imports: [CommonModule, BaseChartDirective],
    templateUrl: './rings.component.html',
    styleUrl: './rings.component.scss',
})
export class RingsComponent implements OnChanges {
    // @ts-ignore
    private danger = getColourValue('--red-primary');

    // @ts-ignore
    private warning = getColourValue('--yellow-primary');

    // @ts-ignore
    private success = getColourValue('--green-primary');

    // @ts-ignore
    private dark = getColourValue('--dark-primary');

    // @ts-ignore
    private primary = getColourValue('--blue-primary');
    private background = getColourValue('--background');

    @Input() defaultState:
        | 'success'
        | 'warning'
        | 'danger'
        | 'primary'
        | 'dark' = 'success';
    @Input() emptyState: 'success' | 'warning' | 'danger' | 'primary' | 'dark' =
        'dark';
    @Input() height!: string;
    @Input() width!: string;
    @Input() label!: string;
    @Input() overall!: string;
    @Input() metric: IMetric[] = [];
    @Input() percentage = false;
    @ViewChild(BaseChartDirective) private chart!: BaseChartDirective;

    public ringsChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        cutout: this.cutout,
        responsive: true,
        animation: false,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    public ringsChartData: ChartConfiguration<'doughnut'>['data'] = {
        datasets: [],
    };

    public ringsChartType: ChartConfiguration<'doughnut'>['type'] = 'doughnut';

    ngOnChanges(changes: SimpleChanges) {
        if (changes['metric'] || changes['overall']) {
            this.updateChartData();
            this.chart?.chart?.update();
        }
    }

    private updateChartData() {
        this.ringsChartData.datasets = this.metric.flatMap((value, index) => {
            const state = findStateForMetric(
                value,
                this.defaultState,
                this.emptyState,
            );

            const colour = this[state as keyof this] as string;

            const ring = {
                data: [value.percent, 100 - value.percent],
                backgroundColor: [colour, this.background],
                hoverBackgroundColor: [colour, this.background],
                borderWidth: 0,
                borderRadius: 20,
            };

            const blank = {
                data: [100, 0],
                backgroundColor: ['#FFFFFFFF', '#FFFFFFFF'],
                hoverBackgroundColor: ['#FFFFFFFF', '#FFFFFFFF'],
                borderWidth: 0,
                borderRadius: 0,
                weight: 0.5,
            };

            if (index < this.metric.length - 1) return [ring, blank];
            else return [ring];
        });
    }

    private get cutout(): string {
        return `${90 - this.metric.length * 10}%`;
    }
}
