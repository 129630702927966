<div class="d-flex">
    <div class="w-25">
        <doughnut
            [overall]="value.toString()"
            [metric]="metric"
            height="80px"
            size="largest"
            weight="medium"
            defaultState="socials"
        />
    </div>
    <div class="w-75">
        <table class="w-100">
            <thead>
                <tr class="font-small weight-medium text-center">
                    <th></th>
                    <td class="text-start">Channel</td>
                    <td>Handled</td>
                    <td>Missed</td>
                    <td class="text-end">% Split</td>
                    <td>Avg Handling Time</td>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="font-small weight-light text-center"
                    (click)="selectChannelType('Voice')"
                    [ngClass]="{
                        active: selectedMediaType === 'Voice',
                    }"
                >
                    <th class="pt-1 pb-1">
                        <i
                            class="fa-phone"
                            [ngClass]="getIconClass('Voice')"
                        ></i>
                    </th>
                    <td class="text-start pt-1 pb-1 voice ps-1">Voice</td>
                    <td class="pt-1 pb-1">
                        {{ channelTypes.Voice.handledCount | number: '3.0-0' }}
                    </td>
                    <td class="pt-1 pb-1">
                        {{ channelTypes.Voice.missedCount | number: '3.0-0' }}
                    </td>
                    <td class="weight-medium text-end pt-1 pb-1">
                        {{ channelTypes.Voice.split }}%
                    </td>
                    <td
                        class="pt-1 pb-1"
                        [ngClass]="
                            getThresholdClass(
                                channelTypes.Voice.averageHandlingTimeInSeconds
                                    .metric
                            )
                        "
                    >
                        ({{
                            secondsToDuration(
                                channelTypes.Voice.averageHandlingTimeInSeconds
                                    .value
                            ) | formatDuration: '[dd]:hh:mm:ss'
                        }})
                    </td>
                </tr>
                <tr
                    class="font-small weight-light text-center"
                    (click)="selectChannelType('Webchat')"
                    [ngClass]="{
                        active: selectedMediaType === 'Webchat',
                    }"
                >
                    <th class="pt-1 pb-1">
                        <i
                            class="fa-message"
                            [ngClass]="getIconClass('Webchat')"
                        ></i>
                    </th>
                    <td class="text-start pt-1 pb-1 webchat ps-1">Webchat</td>
                    <td class="pt-1 pb-1">
                        {{
                            channelTypes.Webchat.handledCount | number: '3.0-0'
                        }}
                    </td>
                    <td class="pt-1 pb-1">
                        {{ channelTypes.Webchat.missedCount | number: '3.0-0' }}
                    </td>
                    <td class="weight-medium text-end pt-1 pb-1">
                        {{ channelTypes.Webchat.split }}%
                    </td>
                    <td
                        class="pt-1 pb-1"
                        [ngClass]="
                            getThresholdClass(
                                channelTypes.Webchat
                                    .averageHandlingTimeInSeconds.metric
                            )
                        "
                    >
                        ({{
                            secondsToDuration(
                                channelTypes.Webchat
                                    .averageHandlingTimeInSeconds.value
                            ) | formatDuration: '[dd]:hh:mm:ss'
                        }})
                    </td>
                </tr>
                <tr
                    class="font-small weight-light text-center"
                    (click)="selectChannelType('Email')"
                    [ngClass]="{
                        active: selectedMediaType === 'Email',
                    }"
                >
                    <th class="pt-1 pb-1">
                        <i
                            class="fa-envelope"
                            [ngClass]="getIconClass('Email')"
                        ></i>
                    </th>
                    <td class="text-start pt-1 pb-1 email ps-1">Email</td>
                    <td class="pt-1 pb-1">
                        {{ channelTypes.Email.handledCount | number: '3.0-0' }}
                    </td>
                    <td class="pt-1 pb-1">
                        {{ channelTypes.Email.missedCount | number: '3.0-0' }}
                    </td>
                    <td class="weight-medium text-end pt-1 pb-1">
                        {{ channelTypes.Email.split }}%
                    </td>
                    <td
                        class="pt-1 pb-1"
                        [ngClass]="
                            getThresholdClass(
                                channelTypes.Email.averageHandlingTimeInSeconds
                                    .metric
                            )
                        "
                    >
                        ({{
                            secondsToDuration(
                                channelTypes.Email.averageHandlingTimeInSeconds
                                    .value
                            ) | formatDuration: '[dd]:hh:mm:ss'
                        }})
                    </td>
                </tr>
                <tr
                    class="font-small weight-light text-center"
                    (click)="selectChannelType('Messaging')"
                    [ngClass]="{
                        active: selectedMediaType === 'Messaging',
                    }"
                >
                    <th class="pt-1 pb-1">
                        <i
                            class="fa-share-nodes"
                            [ngClass]="getIconClass('Messaging')"
                        ></i>
                    </th>
                    <td class="text-start pt-1 pb-1 socials ps-1">Socials</td>
                    <td class="pt-1 pb-1">
                        {{
                            channelTypes.Messaging.handledCount
                                | number: '3.0-0'
                        }}
                    </td>
                    <td class="pt-1 pb-1">
                        {{
                            channelTypes.Messaging.missedCount | number: '3.0-0'
                        }}
                    </td>
                    <td class="weight-medium text-end pt-1 pb-1">
                        {{ channelTypes.Messaging.split }}%
                    </td>
                    <td
                        class="pt-1 pb-1"
                        [ngClass]="
                            getThresholdClass(
                                channelTypes.Messaging
                                    .averageHandlingTimeInSeconds.metric
                            )
                        "
                    >
                        ({{
                            secondsToDuration(
                                channelTypes.Messaging
                                    .averageHandlingTimeInSeconds.value
                            ) | formatDuration: '[dd]:hh:mm:ss'
                        }})
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
