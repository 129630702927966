export interface IVoiceLogin {
    sessionId: string;
    isSuccess: boolean;
    data: IVoiceLoginData[];
}

export interface IVoiceLoginData {
    username: string;
    secret: string;
}
