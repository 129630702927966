import { computed, Injectable, OnDestroy, signal } from '@angular/core';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { IScope } from '../../models/agent/agent-scope';
import { IUser } from '../../models/auth/user';
import { IRbacRole } from '../../models/rbac/permissions';

@Injectable({
    providedIn: 'root',
})
export class UserService implements OnDestroy {
    // constructor(private presenceService: PresenceService) {}
    private subscriptions = new Subscription();
    private _permissions: Map<string, Set<string>> = new Map();

    default: IUser = {
        id: '',
        name: '',
        emailAddress: '',
        authenticated: false,
        enabled: false,
        phoneNumber: '',
        profilePictureUrl: null,
        photo: undefined,
        tenantId: '',
        displayName: '',
        jobTitle: '',
        tenants: [],
        tenant: undefined,
        scopes: [] as IScope[],
    };

    // Using signal for template access
    private _userSignal = signal<IUser>(this.default);
    user = computed<IUser>(() => this._userSignal.asReadonly()());

    // Using BehaviorSubject for other parts of the application
    private _userSubject = new BehaviorSubject<IUser>(this.default);
    user$ = this._userSubject
        .asObservable()
        .pipe(filter(user => user.tenant !== undefined));

    // presence = signal<IPresence>(
    //     calculateUserPresence('LoggedOut', new Date(Date.now())),
    // );

    // setUserAgentState(agentState: IAgentClientState) {
    //     this._userAgentState.next(agentState);
    // }

    update(user: IUser) {
        // Update both signal and BehaviorSubject
        this._userSignal.set({ ...this.user(), ...user });
        this._userSubject.next({ ...this._userSubject.getValue(), ...user });
    }

    clear() {
        // Clear both signal and BehaviorSubject
        this._userSignal.set(this.default);
        this._userSubject.next(this.default);
        this._permissions.clear();
    }

    setPermissions(role: IRbacRole): void {
        this._permissions.clear();

        role.permissions.forEach(permission => {
            const businessEntity = permission.businessEntity.toLowerCase();
            const permissionType = permission.permissionType.toLowerCase();

            if (!this._permissions.has(businessEntity)) {
                this._permissions.set(businessEntity, new Set());
            }
            this._permissions.get(businessEntity)?.add(permissionType);
        });
    }

    hasPermissionByEntityAndType(entity: string, type: string): boolean {
        return (
            (this._permissions.has(entity) &&
                this._permissions.get(entity)?.has(type)) ||
            false
        );
    }

    hasPermission(permission: string): boolean {
        const [entity, type] = permission.toLowerCase().split('.');

        if (!entity || !type) {
            return false;
        }

        return this.hasPermissionByEntityAndType(entity, type);
    }

    getPermissionsForEntity(entity: string): Set<string> | undefined {
        return this._permissions.get(entity);
    }

    isSupervisor(): boolean {
        return this.user().scopes.some(
            scope =>
                scope.name === 'bp.access' &&
                scope.claims.some(
                    claim =>
                        claim.type === 'buzzplus.role' &&
                        claim.value === 'supervisor',
                ),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
