import {
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { interval, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AgentApiService } from '@dxp/shared/api';
import { CommonModule } from '@angular/common';
import { Duration } from 'date-fns';
import { FormsModule } from '@angular/forms';
import { IWorkItem, IWrap } from '@dxp/shared/models';
import { IDropdown, TelXLButtonComponent } from '@dxp/elements';
import { secondsToDuration } from '@dxp/shared/helpers';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { TelXLDropdownComponent } from '@dxp/elements';
import { SettingsService } from '@dxp/shared/services';
import { CacheService } from '@dxp/shared/services';

@Component({
    selector: 'telxl-shared-wrap',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TelXLButtonComponent,
        FormatDurationPipe,
        TelXLDropdownComponent,
    ],
    templateUrl: './wrap.component.html',
    styleUrl: './wrap.component.scss',
})
export class TelXLWrapComponent implements OnInit, OnDestroy, OnChanges {
    private _now = new Date();
    private _intervalSubscription: Subscription | undefined;
    private _workItemSubscription: Subscription | undefined;

    @Input({ required: true }) workItem$!: Observable<IWorkItem>;

    wrapDetails!: IWrap;
    workItem!: IWorkItem;
    taskDetails = '';
    notes!: string | null;
    selectedCode!: string | null;

    constructor(
        private agentApiService: AgentApiService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        private router: Router,
        private settingsService: SettingsService,
        private cacheService: CacheService,
    ) {}

    ngOnInit() {
        this.subscribeToWorkItem();
        this.ngZone.runOutsideAngular(() => {
            this._intervalSubscription = interval(1000).subscribe(() => {
                this._now = new Date();

                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
        this.wrapDetails = this.settingsService.getWrapDetails();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['workItem$']) {
            this.subscribeToWorkItem();
            this.notes = this.cacheService.getFromCache(
                `wrap-${this.workItem.workItemId}-notes`,
            );
            this.selectedCode = this.cacheService.getFromCache(
                `wrap-${this.workItem.workItemId}-code`,
            );
        }
    }

    private subscribeToWorkItem() {
        if (this._workItemSubscription) {
            this._workItemSubscription.unsubscribe();
        }

        this._workItemSubscription = this.workItem$
            .pipe(
                switchMap(item => item.workItemId),
                distinctUntilChanged(),
                switchMap(() => this.workItem$),
            )
            .subscribe(item => {
                this.workItem = item;
                this.taskDetails = this.workItem.primaryMediaType;
            });
    }

    ngOnDestroy() {
        if (this._intervalSubscription) {
            this._intervalSubscription.unsubscribe();
        }

        if (this._workItemSubscription) {
            this._workItemSubscription.unsubscribe();
        }
    }

    extend() {
        this.agentApiService.resetWrapup(this.workItem.workItemId);
    }

    complete() {
        this.agentApiService.completeWorkItem({
            notes: this.notes ? this.notes : '',
            workItemId: this.workItem.workItemId,
            closureCode: this.selectedCode ?? '<Default>',
        });
        this.cacheService.removeCache(`wrap-${this.workItem.workItemId}-notes`);
        this.cacheService.removeCache(`wrap-${this.workItem.workItemId}-code`);
        this.router.navigate(['/dashboard']);
    }

    get canExtend(): boolean {
        return this.workItem.remainingWrapUpResets > 0;
    }

    get canComplete(): boolean {
        return (
            !this.workItem.forceCompletionCodeSelection ||
            this.selectedCode !== ''
        );
    }

    getResetDescription(): string {
        if (this.workItem.remainingWrapUpResets > 1) {
            return `${this.workItem.remainingWrapUpResets} remaining resets`;
        } else if (this.workItem.remainingWrapUpResets === 1) {
            return `${this.workItem.remainingWrapUpResets} remaining reset`;
        }
        return '';
    }

    get wrapCountdown(): Duration {
        if (this.wrapDetails.enabled) {
            return secondsToDuration(
                this.workItem.wrapupDurationSeconds -
                    Math.round(
                        (this._now.getTime() -
                            new Date(
                                this.workItem.lastStateChangeDate,
                            ).getTime()) /
                            1000,
                    ),
            );
        } else {
            return secondsToDuration(
                Math.round(
                    (this._now.getTime() -
                        new Date(this.workItem.lastStateChangeDate).getTime()) /
                        1000,
                ),
            );
        }
    }

    get progress(): number {
        const remainingTime =
            this.workItem.wrapupDurationSeconds -
            Math.round(
                (this._now.getTime() -
                    new Date(this.workItem.lastStateChangeDate).getTime()) /
                    1000,
            );
        return (remainingTime / this.wrapDetails.timeoutSeconds) * 100;
    }

    get menuItems(): IDropdown<string>[] {
        return this.workItem.usableClosureCodes
            .map(b => ({
                label: b,
                value: b,
                disabled: false,
            }))
            .sort((a: { label: string }, b: { label: string }) =>
                a.label.localeCompare(b.label),
            );
    }

    onCodeChange(code: string | null): void {
        this.selectedCode = code;
        this.cacheService.saveToCache(
            `wrap-${this.workItem.workItemId}-code`,
            code,
        );
    }

    onNotesChange(value: string): void {
        this.cacheService.saveToCache(
            `wrap-${this.workItem.workItemId}-notes`,
            value,
        );
    }
}
