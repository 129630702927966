export interface IRbacUser {
    id: string;
    name: string;
    tenantId: string;
    emailAddress: string;
    phoneNumber: string;
    enabled: boolean;
    profilePictureUrl: string | null;
    displayName: string;
    jobTitle: string;    
}
