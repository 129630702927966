export interface IKpiQueueThreshold {
    slaThreshold?: number;
    maxLwtSeconds?: number;
    averageWaitTimeSeconds?: number;
    maxConversationsWaiting?: number;
}

export interface IQueueThreshold {
    low: number;
    high: number;
    enabled: boolean;
}

export interface ITelXLKpiQueueThreshold {
    slaThreshold: IQueueThreshold;
    maxLongestWaitTimeSeconds: IQueueThreshold;
    averageWaitTimeSeconds: IQueueThreshold;
    maxConversationsWaiting: IQueueThreshold;
}

export const TelXLKpiQueueThresholdDefaultState: ITelXLKpiQueueThreshold = {
    slaThreshold: {
        low: 0,
        high: 0,
        enabled: false,
    },
    averageWaitTimeSeconds: {
        low: 0,
        high: 0,
        enabled: false,
    },
    maxConversationsWaiting: {
        low: 0,
        high: 0,
        enabled: false,
    },
    maxLongestWaitTimeSeconds: {
        low: 0,
        high: 0,
        enabled: false,
    },
};

export const compareQueueThreshold = (
    a: IQueueThreshold,
    b: IQueueThreshold,
): boolean => {
    return a.low !== b.low || a.high !== b.high || a.enabled !== b.enabled;
};
