import { Injectable, signal } from '@angular/core';
import { ChannelGroupType } from '@models/queue/queue';
import { RonaType } from '@models/rbac/rona';
import { ViewType } from '@helpers/style';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private _ronaConfiguration: Map<RonaType, number> = new Map();

    viewType = signal<ViewType>('standard');
    channelGroupFilter = signal<ChannelGroupType | undefined>(undefined);

    setRona(rona: RonaType, timeOut: number) {
        this._ronaConfiguration.set(rona, timeOut);
    }

    clearChannelProviderConfigurations() {
        this._ronaConfiguration.clear();
    }

    getRona(rona: RonaType): number {
        return this._ronaConfiguration.get(rona) ?? 0;
    }
}
