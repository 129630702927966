@if (workItemId !== 'new') {
    <telxl-panel cardClass="p4">
        <ng-template #cardBody>
            <voice-call />
        </ng-template>
    </telxl-panel>
} @else {
    <telxl-panel>
        <ng-template #cardBody>
            <div class="font-medium weight-medium text-center">
                Outgoing call - todo
            </div>
        </ng-template>
    </telxl-panel>
}
