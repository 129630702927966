import { Role } from './participant';

export interface ISender {
    id: string;
    role: Role;
    name?: string;
}

export interface IAttachment {
    contentUrl: string;
    contentType?: string;
    name?: string;
}

export interface IMessage {
    id: string;
    createdAt: string;
    textMessage: string;
    attachments: IAttachment[];
    isInternal: boolean;
    sender: ISender;
    translatedText?: string;
    detectedLanguage?: string;
}

export interface IMessageRecommendation {
    text: string;
    confidence: number;
}

export type MessageRecommendations = Record<string, IMessageRecommendation[]>;

export interface IMessageWithRecommendations extends IMessage {
    recommendations: MessageRecommendations;
}
