import { computed, Injectable, signal } from '@angular/core';
import { environment } from '@core/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggerService } from '@services/logger/logger.service';
import { SignalRHubService } from '@signalr/signalr.hub.service';
import {
    calculateUserPresence,
    IUserPresence,
} from '@models/presence/user-presence';
import { IAgentClientState, IWorkItem } from '@models/agent/agent-state';
import { HubConnectionState } from '@microsoft/signalr';

@Injectable({
    providedIn: 'root',
})
export class AgentHubService extends SignalRHubService {
    private _timer!: number;
    private _interval = environment.heartbeatInterval;

    private _agentWorkItems = signal<IWorkItem[]>([]);
    agentWorkItems = computed<IWorkItem[]>(() =>
        this._agentWorkItems.asReadonly()(),
    );

    presence = signal<IUserPresence>(
        calculateUserPresence('LoggedOut', new Date(Date.now())),
    );

    constructor(
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Agent',
            `${environment.blenderUrl}/agentHub`,
            oidcSecurityService,
            logger,
        );
    }

    protected override onStart() {
        this._timer = window.setInterval(
            () => this.heartbeat(),
            this._interval * 1000,
        );
    }

    protected override onStop() {
        window.clearInterval(this._timer);
    }

    protected override registerHandlers() {
        this.hubConnection.on('GetState', (agentState: IAgentClientState) => {
            this.messageReceived();

            this.logger.debug('Agent Service (GetState) ->');
            this.logger.table(agentState);

            this._agentWorkItems.set(agentState.workItems);

            this.presence.set(
                calculateUserPresence(
                    agentState.agentProperties.state,
                    agentState.agentProperties.stateSince,
                    agentState.agentProperties.breakName,
                ),
            );
        });

        this.hubConnection.on(
            'StateTransitionRequested',
            (agentStateTransitionRequested: any) => {
                this.messageReceived();

                this.logger.debug(
                    'Agent Service (StateTransitionRequested) ->',
                );
                this.logger.table(agentStateTransitionRequested);
            },
        );

        this.hubConnection.on(
            'OnWorkItemClosed',
            (workItemClosedEvent: any) => {
                this.messageReceived();

                this.logger.debug('Agent Service (OnWorkItemClosed) ->');
                this.logger.table(workItemClosedEvent);
            },
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('GetState');
        this.hubConnection.off('StateTransitionRequested');
        this.hubConnection.off('OnWorkItemClosed');
    }

    heartbeat() {
        if (this.hubConnection.state === HubConnectionState.Connected) {
            this.logger.debug('Agent Service -> Heartbeat sent');
            this.hubConnection.send('HeartBeat');
        }
    }
}
