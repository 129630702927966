import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { findStateForMetric } from '../../../helpers/metric';
import { getColourValue } from '../../../helpers/style';
import { IMetric } from '../../../models/metrics/metric';

/**
 * Component to display doughnut chart with centered text.
 * The ring represents a percentage value with a colour code indicating the status.
 *
 * @selector doughnut
 * @standalone true
 * @component DoughnutComponent
 * @imports CommonModule, BaseChartDirective
 *
 * @Input metric - Object conforming to IMetric interface, representing the ring in the chart.
 * @Input overall - The summary value for the data.
 *
 * Usage:
 * <doughnut [metric]="data" [overall]="overall"></doughnut>
 *
 * Note:
 * A data item contains the percent value of the ring and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [{25, 'success'}, {75, 'danger'}]}
 * In this scenario a percent value over 25 will render success state and over 75 will render danger state.
 *
 * e.g. { percent: 30, state: [{50, 'warning'}]}
 * In this scenario a percent value over 50 will render warning state. No red state is configured.
 *
 */
@Component({
    selector: 'doughnut',
    standalone: true,
    imports: [CommonModule, BaseChartDirective],
    templateUrl: './doughnut.component.html',
    styleUrl: './doughnut.component.scss',
})
export class DoughnutComponent implements OnChanges, AfterViewInit {
    // @ts-ignore
    private danger = getColourValue('--red-primary');

    // @ts-ignore
    private warning = getColourValue('--yellow-primary');
    // @ts-ignore

    // @ts-ignore
    private success = getColourValue('--green-primary');

    // @ts-ignore
    private dark = getColourValue('--dark-primary');

    // @ts-ignore
    private socials = getColourValue('--purple-primary');

    // @ts-ignore
    private webchat = getColourValue('--webchat-primary');

    // @ts-ignore
    private email = getColourValue('--email-primary');

    // @ts-ignore
    private primary = getColourValue('--blue-primary');
    private background = getColourValue('--background');

    @Input() defaultState:
        | 'success'
        | 'warning'
        | 'danger'
        | 'primary'
        | 'socials'
        | 'email'
        | 'webchat'
        | 'dark' = 'success';
    @Input() emptyState:
        | 'success'
        | 'warning'
        | 'danger'
        | 'primary'
        | 'dark'
        | 'socials'
        | 'email'
        | 'webchat' = 'dark';
    @Input() overall!: string;
    @Input() metric!: IMetric;
    @Input() percentage = false;
    @Input({ required: true }) height!: string;
    @Input({ required: true }) size!:
        | 'small'
        | 'medium'
        | 'large'
        | 'larger'
        | 'largest';
    @Input({ required: true }) weight!: 'light' | 'medium' | 'heavy';
    @Input() multiLine = false;
    @Input() heading = '';
    @Input() perc = 0;
    @Input() doughnutCutout = '90%';

    @ViewChild(BaseChartDirective) private chart!: BaseChartDirective;

    constructor(private cdr: ChangeDetectorRef) {}

    private centerTextPlugin: any = {
        id: 'customCenterText',
        afterDraw: (chart: any) => {
            const ctx = chart.ctx;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            const lines = this.multiLine
                ? [
                      { text: this.heading, weight: 400 },
                      { text: this.overall, weight: 600 },
                      { text: this.perc + '%', weight: 600 },
                  ]
                : [
                      {
                          text: this.overall,
                          weight: this.fontWeight,
                      },
                  ];
            const lineHeight = !this.overall
                ? parseInt(this.fontSize(), 10) + 20
                : parseInt(this.fontSize(), 10) + 35;
            const startY = centerY - ((lines.length - 1) / 2) * lineHeight;

            lines.forEach((line, index) => {
                if (line.text) {
                    if (this.percentage && !this.multiLine) {
                        ctx.font = `${this.fontWeight()} ${this.percentageFontSize()} source-sans-pro`;
                        ctx.fillText(`${line.text}%`, centerX, centerY + 2);
                    } else {
                        ctx.font = `${line.weight} ${this.fontSize()} source-sans-pro`;
                        ctx.fillStyle = '#000';
                        ctx.fillText(
                            line.text,
                            centerX,
                            startY + index * lineHeight,
                        );
                    }
                }
            });

            ctx.restore();
        },
    };

    private fontSize(): string {
        switch (this.size) {
            case 'largest':
                return '1.8rem';
            case 'larger':
                return '1.4rem';
            case 'large':
                return '1.2rem';
            case 'medium':
                return '1rem';
            case 'small':
                return '0.8rem';
            default:
                return '0.6rem';
        }
    }

    private fontWeight(): string {
        switch (this.weight) {
            case 'heavy':
                return 'bold';
            case 'medium':
                return '600';
            default:
                return '400';
        }
    }

    private percentageFontSize(): string {
        switch (this.size) {
            case 'largest':
                return '1.0rem';
            case 'larger':
                return '0.8rem';
            case 'large':
                return '0.6rem';
            default:
                return '0.4rem';
        }
    }

    public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        cutout: this.doughnutCutout,
        responsive: true,
        animation: false,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    public doughnutChartData: ChartConfiguration<'doughnut'>['data'] = {
        datasets: [],
    };

    public doughnutChartPlugins: any = [this.centerTextPlugin];

    public doughnutChartType: ChartConfiguration<'doughnut'>['type'] =
        'doughnut';

    ngOnChanges(changes: SimpleChanges) {
        if (changes['doughnutCutout']) {
            this.doughnutChartOptions!.cutout = this.doughnutCutout;
        }
        if (changes['metric'] || changes['overall']) {
            this.updateChartData();
            this.chart?.chart?.update();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.updateChartData();
            this.chart?.chart?.update();
            this.cdr.detectChanges();
        });
    }

    private updateChartData() {
        const state = findStateForMetric(
            this.metric,
            this.defaultState,
            this.emptyState,
        );

        const colour = this[state as keyof this] as string;

        this.doughnutChartData.datasets = [
            {
                data: [this.metric.percent, 100 - this.metric.percent],
                backgroundColor: [colour, this.background],
                hoverBackgroundColor: [colour, this.background],
                borderWidth: 0,
                borderRadius: 20,
            },
        ];
    }
}
