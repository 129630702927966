import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments';
import {
    ICallState,
    IVoiceLogin,
    IVoiceServers,
    IVoiceStunServers,
} from './../../models';
import { LoggerService, SettingsService } from './../../services';

@Injectable({
    providedIn: 'root',
})
export class VoiceApiService {
    constructor(
        private logger: LoggerService,
        private http: HttpClient,
        private settingsService: SettingsService,
    ) {}

    changeCallState(params: ICallState) {
        this.http
            .patch<void>(`${environment.voiceUrl}/call`, params)
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Voice Service (End call completed) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (End call completed) -> Failed',
                        error,
                    );
                },
            });
    }

    getAsteriskServers() {
        this.http
            .get<IVoiceServers>(`${environment.voiceUrl}/data/asterisk/servers`)
            .subscribe({
                next: asteriskServer => {
                    this.settingsService.setAsteriskServers(asteriskServer);
                    this.logger.debug(
                        'Voice Service (Get Asterisk servers) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Get Asterisk servers) -> Failed',
                        error,
                    );
                },
            });
    }

    getAsteriskStunServers() {
        this.http
            .get<IVoiceStunServers>(
                `${environment.voiceUrl}/data/asterisk/stun`,
            )
            .subscribe({
                next: stunServers => {
                    this.settingsService.setAsteriskStunServers(stunServers);
                    this.logger.debug(
                        'Voice Service (Get Asterisk stun servers) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Get Asterisk stun servers) -> Failed',
                        error,
                    );
                },
            });
    }

    getAsteriskLogin() {
        this.http
            .get<IVoiceLogin>(`${environment.voiceUrl}/data/asterisk/user-info`)
            .subscribe({
                next: login => {
                    this.settingsService.setAsteriskLogin(login);
                    this.logger.debug(
                        'Voice Service (Get Asterisk login) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Get Asterisk login) -> Failed',
                        error,
                    );
                },
            });
    }
}
