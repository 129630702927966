<!-- This code is ready for the splash screen -->

<!-- @if (isNotReady()) {
    <telxl-splash
        (agentReadyEvent)="agentReady()"
        [agentName]="user.name || 'Agent'"
    />
} -->

<div class="d-none d-lg-flex flex-row h-100">
    @if (isAuthenticated()) {
        @if (agentStateProperties()) {
            <app-shell-side-nav />

            <div class="flex-grow-1 main-layout">
                <app-shell-header class="w-100 pt-1 pb-1" />

                <app-shell-main-layout />
            </div>
        } @else {
            <div
                class="d-flex flex-column justify-content-center align-items-center vh-100"
            >
                <telxl-shared-loading-spinner>
                    <div class="mt-3">
                        <p>We are fetching user details...</p>
                    </div>
                </telxl-shared-loading-spinner>
            </div>
        }
    } @else if (!isAuthenticated()) {
        <div
            class="d-flex flex-column justify-content-center align-items-center vh-100"
        >
            <telxl-shared-loading-spinner>
                <div class="mt-3">
                    <p>We are authenticating your credentials...</p>
                </div>
            </telxl-shared-loading-spinner>
        </div>
    } @else {
        <div
            class="alert alert-danger text-center mt-5 mx-auto"
            style="max-width: 500px"
        >
            <div class="d-flex align-items-center justify-content-center mb-3">
                <i class="fas fa-exclamation-circle fa-2x me-2"></i>
                <h4 class="mb-0">Something Went Wrong</h4>
            </div>
            <p class="mb-3">
                An error occurred. Please try refreshing the page.
            </p>
            <button class="btn btn-danger" onclick="window.location.reload();">
                Refresh Page
            </button>
        </div>
    }
</div>
<div
    class="d-flex d-lg-none flex-column justify-content-center align-items-center vh-100 p1"
>
    <div class="mt-3">
        <p>Current Display Resolution is Not Support</p>
    </div>
</div>

<app-shell-notification-toasts />

<div class="visibility-hidden">
    <audio #localAudio muted="muted"></audio>
    <audio #remoteAudio id="remoteAudio" autoplay></audio>
</div>
