import { computed, Injectable, signal } from '@angular/core';
import { RbacApiService } from '@api/rbac/rbac.api.service';
import { BusinessUnit } from '@models/business-unit/business-unit';
import { ITelXLKpiQueueThreshold } from '@models/rbac/queue-kpi';
import { IRbacTenant } from '@models/rbac/tenant';
import { ITenant, Tenant } from '@models/tenant/tenant';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    private _tenant = signal<Tenant>({} as Tenant);
    tenant = computed<ITenant>(() => this._tenant.asReadonly()());
    loading = signal<boolean>(true);

    constructor(private rbacApiService: RbacApiService) {}

    set(tenant: IRbacTenant) {
        this._tenant.set(new Tenant(tenant.id, tenant.name, []));

        this.rbacApiService.getKpiThresholds(tenant.id, 'tenants').subscribe({
            next: kpiThresholds => {
                if (kpiThresholds) {
                    this.setKpi(
                        kpiThresholds.kpiTelXLThreshold as ITelXLKpiQueueThreshold,
                    );
                }
            },
            complete: () => this.loading.set(false),
        });
    }

    getKpi(): ITelXLKpiQueueThreshold {
        return this._tenant.asReadonly()().kpiTelXLThreshold;
    }

    setKpi(telXLKpiThreshold: ITelXLKpiQueueThreshold) {
        const tenent = this._tenant.asReadonly()();

        this._tenant.set(
            new Tenant(
                tenent.id,
                tenent.name,
                tenent._businessUnits,
                telXLKpiThreshold,
            ),
        );
    }

    updateBusinessUnits(businessUnits: BusinessUnit[]) {
        const tenent = this._tenant.asReadonly()();

        this._tenant.set(
            new Tenant(
                tenent.id,
                tenent.name,
                businessUnits,
                tenent.kpiTelXLThreshold,
            ),
        );
    }
}
