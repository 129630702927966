export const AgentStates = [
    'Busy',
    'Break',
    'Idle',
    'LoggedOut',
    'PendingLogout',
    'PendingBreak',
] as const;
export type AgentState = (typeof AgentStates)[number];

export interface IPresence {
    state: AgentState;
    stateSince: Date;
    label: string;
    class: string;
    online: boolean;
    timedBreakExpiresAt?: Date;
}
