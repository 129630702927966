import { Component, OnInit } from '@angular/core';
import { VoiceCallComponent } from './components/voice-call/voice-call.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TelXLPanelComponent } from '@dxp/elements';

@Component({
    standalone: true,
    selector: 'voice-container',
    templateUrl: './voice-container.component.html',
    styleUrls: ['./voice-container.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
        VoiceCallComponent,
        TelXLPanelComponent,
    ],
})
export class VoiceContainerComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}
    workItemId: string | null = null;

    ngOnInit(): void {
        this.workItemId = this.route.snapshot.paramMap.get('workItemId');
    }
}
