export interface IRbacPermission {
    businessEntity: string;
    permissionType: string;
}

export interface IRbacRole {
    id: number;
    tenantId: string;
    name: string;
    permissions: IRbacPermission[];
}
