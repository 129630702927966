import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { findStateForMetric } from '../../../helpers/metric';
import { IMetric } from '../../../models/metrics/metric';

/**
 * Component to display progress
 *
 * @selector progressbar
 * @standalone true
 * @component ProgressComponent
 * @imports CommonModule
 *
 * @Input metric - Object conforming to IMetric interface, representing the progress.
 * @Input overall - The summary value for the data.
 * @Input label - Text to display.
 * @Input percentage - Display the % symbol
 *
 * Usage:
 * <progressbar [metric]="data" [overall]="overall" [label]="SLA" [percentage]="true"></progressbar>
 *
 * Note:
 * A data item contains the percent value of the progress bar and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [{25, 'success'}, {75, 'danger'}]}
 * In this scenario a percent value over 25 will render success state and over 75 will render danger state.
 *
 * e.g. { percent: 30, state: [{50, 'warning'}]}
 * In this scenario a percent value over 50 will render warning state. No red state is configured.
 *
 */
@Component({
    selector: 'progressbar',
    standalone: true,
    imports: [CommonModule, NgbProgressbarModule],
    templateUrl: './progress.component.html',
    styleUrl: './progress.component.scss',
})
export class ProgressComponent implements OnChanges {
    progress = 0;
    state = 'success';
    @Input() defaultState:
        | 'success'
        | 'warning'
        | 'danger'
        | 'primary'
        | 'dark' = 'success';
    @Input() emptyState: 'success' | 'warning' | 'danger' | 'primary' | 'dark' =
        'dark';
    @Input({ required: true }) label!: string;
    @Input({ required: true }) overall!: string;
    @Input({ required: true }) orientation!: 'horizontal' | 'vertical';
    @Input() metric: IMetric | undefined;
    @Input() percentage = false;
    @Input() emphasis: 'standard' | 'light' | 'heavy' = 'standard';
    @Input() class = '';

    ngOnChanges(changes: SimpleChanges) {
        if (changes['metric'] && this.metric) {
            this.progress = this.metric.percent;
            this.state = findStateForMetric(
                this.metric,
                this.defaultState,
                this.emptyState,
            );
        }
    }
}
