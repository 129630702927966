import { IAttachment } from './message';

export interface IConversationCommand {
    conversationId: string;
}

export interface IConnectCommand extends IConversationCommand {
    asSupervisor: boolean;
}

export interface IDisconnectCommand extends IConversationCommand {
    nextNodeId?: string;
}

export interface IMessageCommand extends IConversationCommand {
    textMessage: string;
    attachments: IAttachment[];
    isInternal: boolean;
}

export type ITypingCommand = IConversationCommand & {
    isInternal: boolean;
};

export interface ITransferToAgentCommand extends IConversationCommand {
    targetAgentId: string;
}
