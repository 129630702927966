import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { share, tap } from 'rxjs';
import { environment } from './../../environments';
import { LoggerService } from './../../services';

@Injectable({
    providedIn: 'root',
})
export class DirectoryApiService {
    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getPhoto() {
        return this.http
            .get(`${environment.directoryUrl}/me/photo`, {
                responseType: 'blob',
            })
            .pipe(
                tap({
                    next: photo => {
                        this.logger.debug(
                            'Directory Service (Get Photo) -> Successful',
                            photo,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Directory Service (Get Photo) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }
}
