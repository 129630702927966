import {
    EventTypes,
    OidcSecurityService,
    PublicEventsService
} from 'angular-auth-oidc-client';
import { Injectable, inject } from '@angular/core';
import { LoggerService, StateService, UserService } from './../../services';
import { Observable, OperatorFunction, catchError, delay, filter, map, of, retry, tap } from 'rxjs';

import { IUser } from './../../models';

function getEventTypeName(eventType: EventTypes): string {
    switch (eventType) {
        case EventTypes.CheckingAuth:
            return 'CheckingAuth';
        case EventTypes.CheckingAuthFinished:
            return 'CheckingAuthFinished';
        case EventTypes.CheckingAuthFinishedWithError:
            return 'CheckingAuthFinishedWithError';
        case EventTypes.ConfigLoadingFailed:
            return 'ConfigLoadingFailed';
        case EventTypes.CheckSessionReceived:
            return 'CheckSessionReceived';
        case EventTypes.UserDataChanged:
            return 'UserDataChanged';
        case EventTypes.NewAuthenticationResult:
            return 'NewAuthenticationResult';
        case EventTypes.TokenExpired:
            return 'TokenExpired';
        case EventTypes.IdTokenExpired:
            return 'IdTokenExpired';
        case EventTypes.SilentRenewStarted:
            return 'SilentRenewStarted';
        case EventTypes.SilentRenewFailed:
            return 'SilentRenewFailed';
        default:
            return 'Unknown Event';
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly eventService = inject(PublicEventsService);

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private stateService: StateService,
        private userService: UserService,
        private logger: LoggerService,
    ) {
        this.eventService
            .registerForEvents()
            .pipe(
                filter(notification =>
                    [
                        EventTypes.CheckingAuth,
                        EventTypes.CheckingAuthFinished,
                        EventTypes.CheckingAuthFinishedWithError,
                        EventTypes.ConfigLoadingFailed,
                        EventTypes.CheckSessionReceived,
                        EventTypes.UserDataChanged,
                        EventTypes.NewAuthenticationResult,
                        EventTypes.TokenExpired,
                        EventTypes.IdTokenExpired,
                        EventTypes.SilentRenewStarted,
                        EventTypes.SilentRenewFailed,
                        EventTypes.CheckSessionReceived,
                        EventTypes.UserDataChanged,
                    ].includes(notification.type),
                ),
            )
            .subscribe(value => {
                if (value.type === EventTypes.NewAuthenticationResult) {
                    this.stateService.onAccessTokenChange();
                }

                this.logger.debug(
                    `Authentication event (${getEventTypeName(value.type as EventTypes)})`,
                    value,
                );
            });
    }

    private onLogin(userData: any) {
        this.userService.update({
            id: userData.sub,
            name: userData.name,
            emailAddress: userData.email,
            authenticated: true,
        } as IUser);

        this.logger.debug('User information', userData);

        this.stateService.onLogin();
    }

    initializeAuthentication(): Observable<boolean> {
        return this.oidcSecurityService.checkAuth().pipe(
            tap(({ isAuthenticated, userData, accessToken }) => {
                this.logger.info(
                    `Authentication initialization the user is ${
                        isAuthenticated ? '' : 'not '
                    }authenticated.`,
                );

                if (isAuthenticated) {
                    this.onLogin(userData);
                    this.logger.debug('Access token', accessToken);
                } else {
                    this.userService.clear();
                    this.stateService.onLogoff();
                }
            }),
            map(a => a.isAuthenticated),
            this.logErrorAndDelayRetry(),
            catchError(error => {
                this.logger.error('Error during authentication initialization', error);
                this.userService.clear();
                this.stateService.onLogoff();
                return of(false);
            })
        );
    }

    private logErrorAndDelayRetry(
        retryTimes = 3,
        delayMileseconds = 2000,
    ): OperatorFunction<boolean, boolean> {
        return retry({
            count: retryTimes,
            delay: (error, retryCount) => {
                this.logger.error('Retrying authentication initialization', error);

                const incrementalDelay = delayMileseconds * retryCount;
                this.logger.info(
                    `Retry #${retryCount} after ${incrementalDelay}ms`,
                );

                return of(null).pipe(delay(incrementalDelay));
            },
        });
    }

    login() {
        this.oidcSecurityService.authorize();
    }

    logout() {
        this.stateService.onLogout().subscribe(() => {
            this.oidcSecurityService.logoff().subscribe(() => {
                this.userService.clear();
            });
        });
    }

    
}
