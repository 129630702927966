import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { recordToArray } from '../../helpers/team-agents';
import { secondsToDuration } from '../../helpers/time';
import { IMetric } from '../../models/metrics/metric';
import { FormatDurationPipe } from '../../pipes/format-duration/format-duration.pipe';
import { ProgressComponent } from '../metrics/progress/progress.component';
import { PaginationComponent } from '../pagination/pagination.component';

export interface IBreakReason {
    label: string;
    time: string;
    count: number;
    metric: IMetric;
}

@Component({
    selector: 'team-break-reason-panel',
    standalone: true,
    imports: [
        CommonModule,
        ProgressComponent,
        FormatDurationPipe,
        PaginationComponent,
    ],
    templateUrl: './break-reason-panel.component.html',
    styleUrl: './break-reason-panel.component.scss',
})
export class TeamBreakReasonComponent {
    recordToArray = recordToArray;
    secondsToDuration = secondsToDuration;

    itemsPerPage = 5;
    currentPage = 1;

    @Input({ required: true }) breakReasons!: Record<
        string,
        { value: number; percentage: number }
    >;

    changePage(newPage: number) {
        this.currentPage = newPage;
    }

    showBreakReason(index: number): boolean {
        const low = (this.currentPage - 1) * this.itemsPerPage;
        const high = low + this.itemsPerPage - 1;

        return index >= low && index <= high;
    }

    get breakReasonsArray(): {
        name: string;
        value: number;
        percentage: number;
    }[] {
        return recordToArray(this.breakReasons).sort(
            (a, b) => b.percentage - a.percentage,
        );
    }
    get totalPages() {
        const pages = Math.ceil(
            this.breakReasonsArray.length / this.itemsPerPage,
        );
        return pages > 0 ? pages : 1;
    }
}
