import { IMetric } from '@models/metrics/metric';

export const findStateForMetric = (
    metric: IMetric,
    defaultState:
        | 'success'
        | 'warning'
        | 'danger'
        | 'primary'
        | 'dark'
        | 'socials'
        | 'webchat'
        | 'email',
    emptyState:
        | 'success'
        | 'warning'
        | 'danger'
        | 'primary'
        | 'dark'
        | 'webchat'
        | 'email'
        | 'socials',
): string => {
    if (metric.states.length === 0) return emptyState;

    const sortedStates = metric.states.sort((a, b) => a.value - b.value);

    for (let i = sortedStates.length - 1; i >= 0; i--) {
        if (metric.percent >= sortedStates[i].value) {
            return sortedStates[i].state;
        }
    }

    return defaultState;
};

export const toPercentage = (value?: number, defaultValue = 0): number => {
    return Math.min(Math.round((value ?? defaultValue) * 100), 100);
};
