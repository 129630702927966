import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProgressComponent } from '../../components/metrics/progress/progress.component';
import { secondsToDuration } from '../../helpers/time';
import { AgentState } from '../../models/presence/user-presence';
import { FormatDurationPipe } from '../../pipes/format-duration/format-duration.pipe';

@Component({
    selector: 'team-utilisation-panel',
    standalone: true,
    imports: [CommonModule, ProgressComponent, FormatDurationPipe],
    templateUrl: './utilisation-panel.component.html',
    styleUrl: './utilisation-panel.component.scss',
})
export class TeamUtilisationComponent {
    secondsToDuration = secondsToDuration;

    @Input({ required: true }) utilisation!: Record<
        AgentState,
        { value: number; percentage: number }
    >;
}
