import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pagination',
    standalone: true,
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    @Input() currentPage = 1;
    @Input() totalPages = 1;
    @Output() pageChanged = new EventEmitter<number>();

    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.pageChanged.emit(this.currentPage + 1);
        }
    }
    prevPage() {
        if (this.currentPage > 1) {
            this.pageChanged.emit(this.currentPage - 1);
        }
    }
}
