<div class="h-100vh full-width">
    @if (loading()) {
        <div
            class="d-flex flex-column justify-content-center align-items-center vh-100 p1"
        >
            <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
            >
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="mt-3">
                <p>Loading user details</p>
            </div>
        </div>
    } @else if (user.authenticated) {
        <div class="d-flex flex-row h-fitscreen">
            <app-shell-side-nav />

            <div class="flex-grow-1 main-layout">
                <app-shell-header class="w-100 header-bar pt-1 pb-1" />
                <app-shell-main-layout />
            </div>
        </div>
    }
    <notification-toasts></notification-toasts>
    <div class="visibility-hidden">
        <audio #localAudio muted="muted"></audio>
        <audio #remoteAudio id="remoteAudio" autoplay></audio>
    </div>
</div>
