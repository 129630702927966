import {
    Component,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef,
    NgZone,
    computed,
    signal,
    effect,
    OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { format, parseISO } from 'date-fns';
import { interval, Subscription } from 'rxjs';
import { AgentHubService } from '@signalr/agent/agent.hub.service';
import { TaskBeltItemComponent } from '@base/components/task-belt/item/task-belt-item.component';
import { TaskOrder } from '@models/agent/agent-state';
import { IWorkItem } from '@models/agent/agent-state';
import { FormatDurationPipe } from '@pipes/format-duration/format-duration.pipe';
import { secondsToDuration } from '@helpers/time';
import { ProgressBarComponent } from '@components/task-progress/task-progress.component';

type MediaType = 'Voice' | 'Webchat' | 'Messaging' | 'Email';

interface IIncomingTask {
    order: number;
    workItemId: string;
    conversationId: string;
    mediaType: string;
    channelType: string;
    createdAt: string;
    createdAtActual: Date;
    queueName: string;
    icon: string;
    iconSelected: string;
    selected: boolean;
    classSelected: string;
    navigation: string;
}

@Component({
    selector: 'task-belt',
    standalone: true,
    imports: [
        CommonModule,
        TaskBeltItemComponent,
        ProgressBarComponent,
        FormatDurationPipe,
    ],
    templateUrl: './task-belt.component.html',
    styleUrl: './task-belt.component.scss',
    animations: [
        trigger('dropInOut', [
            transition(':enter', [
                style({ opacity: 0, transform: '{{transformStart}}' }),
                animate(
                    '0.5s ease-out',
                    style({ opacity: 1, transform: 'translateY(0)' }),
                ),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate(
                    '0.5s ease-in',
                    style({ opacity: 0, transform: 'translateY(20px)' }),
                ),
            ]),
        ]),
    ],
})
export class TaskBeltComponent implements AfterViewInit, OnDestroy {
    @ViewChild('taskBelt') taskBeltRef!: ElementRef<HTMLDivElement>;

    secondsToDuration = secondsToDuration;
    workItems = this.agentHubService.agentWorkItems;
    displayedTasks = signal<IIncomingTask[]>([]);

    selectedItem: string | null = null;
    waitingTime: string = '00:00';
    timer: any;
    elapsedTime: number = 0;
    intervalSubscription: Subscription | undefined;

    tasksExist = computed(() => this.displayedTasks().length > 0);

    delayedTasksExist = false;
    delayTimeout: any;

    constructor(
        private agentHubService: AgentHubService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
    ) {
        effect(() => {
            if (this.tasksExist()) {
                this.delayedTasksExist = true;
                if (this.delayTimeout) clearTimeout(this.delayTimeout);
            } else {
                this.delayTimeout = setTimeout(() => {
                    this.delayedTasksExist = false;
                }, 50);
            }
        });
        effect(
            () => {
                this.syncTasksWithWorkItems();
            },
            { allowSignalWrites: true },
        );
    }

    ngOnInit(): void {
        this.startTimer();
    }

    ngAfterViewInit() {
        this.updateTaskStatus();
    }

    ngOnDestroy(): void {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
        clearTimeout(this.delayTimeout);
    }

    private readonly mediaTypeMappings: Record<
        MediaType | 'default',
        {
            icon: string;
            selectedIcon: string;
            class: string;
            order: TaskOrder;
            navigation: string;
        }
    > = {
        Voice: {
            icon: 'fa-solid fa-phone task-phone',
            selectedIcon: 'fa-solid fa-phone task-phone',
            class: 'selected-phone',
            order: TaskOrder.Voice,
            navigation: 'chats/voice',
        },
        Webchat: {
            icon: 'fa-light fa-message task-chat',
            selectedIcon: 'fa-solid fa-message task-chat',
            class: 'selected-chat',
            order: TaskOrder.Webchat,
            navigation: 'chats/webchat',
        },
        Messaging: {
            icon: 'fa-light fa-message task-chat',
            selectedIcon: 'fa-solid fa-message task-chat',
            class: 'selected-chat',
            order: TaskOrder.Messaging,
            navigation: 'chats/webchat',
        },
        Email: {
            icon: 'fa-light fa-envelope task-email',
            selectedIcon: 'fa-solid fa-envelope task-email',
            class: 'selected-email',
            order: TaskOrder.Email,
            navigation: 'chats/email',
        },
        default: {
            icon: 'fa-light fa-share-nodes task-social',
            selectedIcon: 'fa-solid fa-share-nodes task-social',
            class: 'selected-social',
            order: TaskOrder.Unknown,
            navigation: 'chats/webchat',
        },
    };

    syncTasksWithWorkItems(): void {
        let workItemsArray = this.workItems();
        if (
            Object.values(workItemsArray).length < this.displayedTasks().length
        ) {
            this.removeTask();
        }
        workItemsArray = this.workItems();
        if (
            Object.values(workItemsArray).length > this.displayedTasks().length
        ) {
            this.acceptIncomingTask();
        }

        this.updateTaskStatus();
    }

    queueName(task: IWorkItem): string {
        if (task.conversations) {
            const conversation = task.conversations[task.primaryConversationId];
            if (conversation && conversation.queueName) {
                return conversation.queueName;
            }
        }
        return 'Unknown Queue';
    }

    acceptIncomingTask() {
        const workItemsArray = this.workItems();
        Object.values(workItemsArray).forEach(task => {
            if (!this.checkDuplicateTask(task)) {
                const newTask: IIncomingTask = {
                    order:
                        this.mediaTypeMappings[task.primaryMediaType]?.order ||
                        this.mediaTypeMappings.default.order,
                    workItemId: task.workItemId,
                    conversationId: task.primaryConversationId,
                    mediaType: task.primaryMediaType,
                    channelType: task.primaryChannelType,
                    createdAt: format(
                        parseISO(task.createdAt),
                        'hh:mma',
                    ).toLowerCase(),
                    queueName: this.queueName(task),
                    createdAtActual: parseISO(task.createdAt),
                    icon:
                        this.mediaTypeMappings[task.primaryMediaType]?.icon ||
                        this.mediaTypeMappings.default.icon,
                    iconSelected:
                        this.mediaTypeMappings[task.primaryMediaType]
                            ?.selectedIcon ||
                        this.mediaTypeMappings.default.selectedIcon,
                    selected: false,
                    classSelected:
                        this.mediaTypeMappings[task.primaryMediaType]?.class ||
                        this.mediaTypeMappings.default.class,
                    navigation:
                        this.mediaTypeMappings[task.primaryMediaType]
                            ?.navigation ||
                        this.mediaTypeMappings.default.navigation,
                };

                this.displayedTasks.update(tasks => [...tasks, newTask]);
                this.displayedTasks().sort((task1, task2) => {
                    if (task1.order === task2.order) {
                        return (
                            task2.createdAtActual.getTime() -
                            task1.createdAtActual.getTime()
                        );
                    }
                    return task1.order - task2.order;
                });
                this.stopTimer();
                this.updateTaskStatus();
            }
        });
    }

    removeTask() {
        const currentTasks = this.displayedTasks();
        let tskid: string = '';
        currentTasks.forEach(task => {
            const existsInWorkItems = this.taskExists(task.workItemId);
            if (!existsInWorkItems) {
                tskid = task.workItemId;
            }
        });

        const updatedItems = currentTasks.filter(
            item => item.workItemId !== tskid,
        );
        this.displayedTasks.set(updatedItems);
        if (this.displayedTasks().length === 0) {
            this.elapsedTime = 0;
            this.startTimer();
        }
    }

    checkDuplicateTask(task: IWorkItem): boolean {
        return this.displayedTasks().some(
            value => value.workItemId === task.workItemId,
        );
    }

    taskExists(workItemId: string): boolean {
        return Object.values(this.workItems()).some(
            value => value.workItemId === workItemId,
        );
    }

    updateTaskStatus() {
        const taskExistValue = this.tasksExist();
        if (taskExistValue) {
            this.cdr.detectChanges();
        }
    }

    startTimer() {
        this.stopTimer();
        if (this.elapsedTime === 0) {
            this.ngZone.runOutsideAngular(() => {
                this.intervalSubscription = interval(1000).subscribe(() => {
                    this.elapsedTime++;
                    this.ngZone.run(() => {
                        this.cdr.detectChanges();
                    });
                });
            });
        }
    }

    stopTimer() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
            this.intervalSubscription = undefined;
        }
        this.elapsedTime = 0;
    }

    isSelected(workItemId: string): boolean {
        return this.selectedItem === workItemId;
    }

    deselectOthers(workItemId: string) {
        this.selectedItem =
            this.selectedItem === workItemId ? null : workItemId;
        if (this.selectedItem !== workItemId) {
            this.selectedItem = workItemId;
        }
    }

    selectTimeout(mediaType: string): number {
        switch (mediaType) {
            case 'Voice':
                return 120;
            case 'Webchat':
                return 120;
            case 'Messaging':
                return 120;
            case 'Email':
                return 120;
            default:
                return 120;
        }
    }
}
