@if (!isTask) {
    <div class="d-flex flex-row align-items-center">
        <div class="flex-grow-1">
            <div
                class="progress-bar progress-non-task"
                [ngClass]="{
                    'bg-success': progress > 75,
                    'bg-warning': progress > 50 && progress <= 75,
                    'bg-danger': progress <= 50,
                }"
                role="progressbar"
                [style.width]="progress + '%'"
            ></div>
        </div>
        <div class="ms-2 flex-shrink-1 text-end weight-light">
            {{ remainingWrap | formatDuration }}
        </div>
    </div>
} @else {
    <div
        class="progress-bar progress-task ms-2"
        [ngClass]="{
            'bg-success': progress > 75,
            'bg-warning': progress > 50 && progress <= 75,
            'bg-danger': progress <= 50,
        }"
        role="progressbar"
        [style.width]="progress + '%'"
    ></div>
}
