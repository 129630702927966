import { Component, Signal } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IPresence } from '@dxp/shared/models';
import { PresenceService } from '@dxp/shared/services';
import { TaskBeltComponent } from './../task-belt/container/task-belt.component';
import { MainLayoutBreakComponent } from './main-layout-break/main-layout-break.component';

@Component({
    selector: 'app-shell-main-layout',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TaskBeltComponent,
        MainLayoutBreakComponent,
    ],
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {
    presence: Signal<IPresence>;

    constructor(private presenceService: PresenceService) {
        this.presence = this.presenceService.presence;
    }
}
