import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { IAgentReport } from '../../models/agent/agent';
import { IQueueWithChannelsReport } from '../../models/queue/queue';
import { LoggerService } from '../../services/logger/logger.service';
import { SignalRHubService } from '../../signalr/signalr.hub.service';

@Injectable({
    providedIn: 'root',
})
export class AggregationsHubService extends SignalRHubService {
    private agentReportSubject = new Subject<IAgentReport>();
    private queueReportSubject = new Subject<IQueueWithChannelsReport>();

    agentReport$ = this.agentReportSubject.asObservable();
    queueReport$ = this.queueReportSubject.asObservable();

    constructor(
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Aggregation',
            `${environment.aggregationsUrl}/today/hub`,
            oidcSecurityService,
            logger,
        );
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'ReceiveTodaysAgentReportData',
            (agentReport: IAgentReport) => {
                this.messageReceived();

                this.logger.debug(
                    'Aggregation Service (ReceiveTodaysAgentReportData) ->',
                );
                this.logger.table(agentReport);

                this.agentReportSubject.next(agentReport);
            },
        );

        this.hubConnection.on(
            'ReceiveTodaysQueueData',
            (queueReport: IQueueWithChannelsReport) => {
                this.messageReceived();

                this.logger.debug(
                    'Aggregation Service (ReceiveTodaysQueueData) ->',
                );
                this.logger.table(queueReport);

                this.queueReportSubject.next(queueReport);
            },
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('ReceiveTodaysAgentReportData');
        this.hubConnection.off('ReceiveTodaysQueueData');
    }
}
