import {
    Component,
    effect,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Signal,
    NgZone,
    ChangeDetectorRef,
    Injector,
    runInInjectionContext,
} from '@angular/core';
import {
    ICallState,
    IIncomingTask,
    IWorkItem,
    IWrap,
} from '@dxp/shared/models';
import { SettingsService, SipService } from '@dxp/shared/services';
import { AgentService } from '@dxp/shared/services';
import { ChatHubService } from '@dxp/shared/signalr';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { TelXLTaskProgressComponent } from '@dxp/components';
import { VoiceApiService } from '@dxp/shared/api';
import { interval, map, Subscription } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { Duration } from 'date-fns';
import { secondsToDuration } from '@dxp/shared/helpers';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { AgentApiService } from '@dxp/shared/api';

@Component({
    selector: 'app-shell-task-belt-item',
    standalone: true,
    imports: [CommonModule, TelXLTaskProgressComponent, FormatDurationPipe],
    templateUrl: './task-belt-item.component.html',
    styleUrl: './task-belt-item.component.scss',
})
export class TaskBeltItemComponent implements OnInit, OnDestroy {
    @Input() createdAtActual = new Date();
    @Input() isSelected = false;
    @Input() timeout = 0;
    @Input() task!: IIncomingTask;
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    stopProgress = false;
    displayTaskButtons = false;
    displayProgress = true;
    workItem!: Signal<IWorkItem>;
    inWrap = false;
    now = new Date();

    wrapDetails!: IWrap;
    wrapDuration = 0;
    wrapResets = 0;
    wrapEnabled = false;
    private intervalSubscription: Subscription | undefined;

    constructor(
        private sipService: SipService,
        private router: Router,
        private settingsService: SettingsService,
        private voiceApiService: VoiceApiService,
        private chatHubService: ChatHubService,
        private agentService: AgentService,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef,
        private injector: Injector,
        private agentApiService: AgentApiService,
    ) {
        effect(() => {
            const workItem = this.workItem();
            if (workItem) {
                if (workItem.workItemState === 'Afterwork' && !this.inWrap) {
                    this.startTimer();
                    this.inWrap = true;
                }
            }
        });
    }

    ngOnInit() {
        this.displayTaskButtons =
            this.task.workItemState === 'Preview' &&
            this.timeout > 0 &&
            this.task.mediaType !== 'Voice';
        this.displayProgress = this.task.mediaType !== 'Voice';
        this.wrapDetails = this.settingsService.getWrapDetails();

        runInInjectionContext(this.injector, () => {
            const workItem$ = this.agentService.agentWorkItems$.pipe(
                map(
                    wi =>
                        wi.find(
                            w => w.workItemId === this.task?.workItemId,
                        ) as IWorkItem,
                ),
            );
            this.workItem = toSignal(workItem$, {
                initialValue: {} as IWorkItem,
            });
        });
    }

    ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    extendWrap() {
        this.agentApiService.resetWrapup(this.workItem().workItemId);
    }

    startTimer() {
        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(1000).subscribe(() => {
                this.now = new Date();

                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
    }

    onClick() {
        if (!this.displayTaskButtons) {
            this.stopProgress = true;

            this.router.navigate([this.task.navigation, this.task.workItemId]);
        }
        this.isSelected = true;
        this.clicked.emit();
    }

    onAcceptClick(): void {
        if (this.task.mediaType === 'Voice') {
            this.sipService.answerCall();
            this.task.acceptedAt = new Date();
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Answer',
            };
            this.voiceApiService.changeCallState(params);
        } else {
            this.chatHubService.accept({
                conversationId: this.task.conversationId,
            });
        }

        this.isSelected = true;
        this.displayTaskButtons = false;
        this.router.navigate([this.task.navigation, this.task.workItemId]);
        this.timeout = 0;
    }

    onRejectClick(): void {
        if (this.task.mediaType === 'Voice') {
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Hangup',
            };
            this.voiceApiService.changeCallState(params);
            this.sipService.reject();
        } else {
            this.chatHubService.reject({
                conversationId: this.task.conversationId,
            });
        }
        this.displayProgress = false;
        this.displayTaskButtons = false;
    }

    get wrapCountdown(): Duration {
        if (this.wrapDetails.enabled) {
            return secondsToDuration(
                this.workItem().wrapupDurationSeconds -
                    Math.round(
                        (this.now.getTime() -
                            new Date(
                                this.workItem().lastStateChangeDate,
                            ).getTime()) /
                            1000,
                    ),
            );
        } else {
            return secondsToDuration(
                Math.round(
                    (new Date().getTime() -
                        new Date(
                            this.workItem().lastStateChangeDate,
                        ).getTime()) /
                        1000,
                ),
            );
        }
    }

    get wrapProgress(): number {
        const remainingTime =
            this.workItem().wrapupDurationSeconds -
            Math.round(
                (new Date().getTime() -
                    new Date(this.workItem().lastStateChangeDate).getTime()) /
                    1000,
            );
        return (remainingTime / this.wrapDetails.timeoutSeconds) * 100;
    }
}
