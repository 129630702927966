<li
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="active"
    #rla="routerLinkActive"    
    id="side-nav-item-{{ label | lowercase | replaceSpaces }}"
>
    <a class="nav-link d-flex flex-column align-items-center nav-item" [routerLink]="routerLink">
        <i
            [class]="iconName"
            class="fa-lg"
            [ngClass]="rla.isActive ? 'fa-solid nav-item-selected' : 'fa-light'"
        ></i>
        <span
            class="mt-3 font-small"
            [ngClass]="{ 'nav-item-selected': rla.isActive }"
            id="side-nav-item-button-text-{{
                label | lowercase | replaceSpaces
            }}"
        >
        {{ label }}
        </span>
    </a>

</li>
