import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'telxl-modal',
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-template #modalTemplate let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Message Templates</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        ></button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[modal-footer]"></ng-content>
      </div>
    </ng-template>
  `,
})
export class TelXLModalComponent {
  @ViewChild('modalTemplate', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalTemplate!: TemplateRef<any>;
}
