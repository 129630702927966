import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogLevel } from '../../models/configuration/log';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    debug(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel <= LogLevel.Debug) {
            if (optionalParams.length > 0) console.log(message, optionalParams);
            else console.log(message);
        }
    }

    error(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel <= LogLevel.Error) {
            if (optionalParams.length > 0)
                console.error(message, optionalParams);
            else console.error(message);
        }
    }

    warn(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel <= LogLevel.Warning) {
            if (optionalParams.length > 0)
                console.warn(message, optionalParams);
            else console.warn(message);
        }
    }

    info(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel <= LogLevel.Information) {
            if (optionalParams.length > 0)
                console.info(message, optionalParams);
            else console.info(message);
        }
    }

    table(tabularData?: any, properties?: string[]) {
        if (environment.logLevel <= LogLevel.Debug) {
            console.table(tabularData, properties);
        }
    }
}
