import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: true,
})
export class SearchPipe implements PipeTransform {
    transform(value: any[], searchText: string, property: string): any[] {
        if (!value) return [];
        if (!searchText) return value;

        searchText = searchText.toLowerCase();

        return value.filter((item: any) => {
            const fieldToSearch = property
                ? item[property]
                : JSON.stringify(item);
            return fieldToSearch?.toLowerCase().includes(searchText);
        });
    }
}
