import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    NgbOffcanvas,
    NgbOffcanvasOptions,
    NgbOffcanvasRef,
} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@core/environments/environment';
import { LoggerService } from '@services/logger/logger.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SignalRHubService } from '@signalr/signalr.hub.service';
import {
    INotificationModel,
    NotificationActionType,
    NotificationType,
} from '@models/notification/notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationHubService extends SignalRHubService {
    private _notificationPanelService = inject(NgbOffcanvas);
    private _notificationAlertSubject =
        new BehaviorSubject<INotificationModel | null>(null);

    private _notifications = signal<INotificationModel[]>([]);

    notifications = computed<INotificationModel[]>(() =>
        this._notifications.asReadonly()(),
    );

    unread = computed<number>(
        () =>
            this._notifications().filter(
                n => n.read === false && n.type === NotificationType.Tier3,
            ).length,
    );

    constructor(
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
        private http: HttpClient,
    ) {
        super(
            'Notification',
            `${environment.blenderUrl}/notificationHub`,
            oidcSecurityService,
            logger,
        );
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'Notification',
            (notification: INotificationModel) => {
                this.messageReceived();

                this.logger.debug('Notification Service (Notification) ->');
                this.logger.table(notification);

                this.addorUpdateNotification(notification);
            },
        );

        this.hubConnection.on('Remove', (notification: INotificationModel) => {
            this.messageReceived();

            this.logger.debug('Notification Service (Remove) ->');
            this.logger.table(notification);

            this.removeNotification(notification);
        });
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('Notification');
        this.hubConnection.off('Remove');
    }

    open(content: any, options?: NgbOffcanvasOptions): NgbOffcanvasRef {
        this._notifications().forEach(notification => {
            if (
                notification.type === NotificationType.Tier3 &&
                !notification.read
            ) {
                this.markNotificationAs(
                    notification,
                    NotificationActionType.Read,
                );
            }
        });

        return this._notificationPanelService.open(content, options);
    }

    addorUpdateNotification(notification: INotificationModel) {
        if (notification.type === NotificationType.Tier1) {
            this._notificationAlertSubject.next(notification);
            return;
        }

        if (
            notification.type === NotificationType.Tier3 &&
            !notification.read &&
            this._notificationPanelService.hasOpenOffcanvas()
        ) {
            this.markNotificationAs(notification, NotificationActionType.Read);
        }

        const existingIndex = this._notifications().findIndex(
            n => n.id === notification.id,
        );

        if (existingIndex >= 0) {
            const update = [...this._notifications()];
            update[existingIndex] = notification;
            this._notifications.set(update.sort(this.sort));
        } else {
            const update = [...this._notifications(), notification];
            this._notifications.set(update.sort(this.sort));
        }
    }

    removeNotification(notification: INotificationModel) {
        if (notification.type === NotificationType.Tier1) {
            this._notificationAlertSubject.next(null);
            return;
        }

        const updatedNotifications = this._notifications().filter(
            n => n.id !== notification.id,
        );

        this._notifications.set(updatedNotifications);
    }

    markNotificationAs(
        notification: INotificationModel,
        action: NotificationActionType,
    ) {
        this.http
            .patch(
                environment.blenderUrl +
                    `/notification/${notification.id}/mark-as-${action}`,
                {},
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Notification Service (Notification) -> Successful',
                        `${notification.id} marked as ${action}`,
                    );
                },
                error: error => {
                    this.logger.error(
                        'Notification Service (Notification) Failed ->',
                        error,
                    );
                },
            });
    }

    private sort(a: INotificationModel, b: INotificationModel): number {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);

        return dateB.getTime() - dateA.getTime();
    }

    get notificationAlert(): Observable<INotificationModel | null> {
        return this._notificationAlertSubject.asObservable();
    }
}
