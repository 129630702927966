import { computed, Injectable, OnDestroy, signal } from '@angular/core';
import {
    IQueue,
    IQueueInformation,
    IQueueWithChannelsReport,
    Queue,
} from '../../models/queue/queue';
import {
    IKpiQueueThreshold,
    ITelXLKpiQueueThreshold,
} from '../../models/rbac/queue-kpi';
import { CacheHandler, CacheService } from '../../services/cache/cache-service';

import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { RbacApiService } from '../../api/rbac/rbac.api.service';
import { NBU_ID } from '../../models/business-unit/business-unit';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import { ChannelProviderService } from '../../services/channel-provider/channel-provider.service';
import { LoggerService } from '../../services/logger/logger.service';
import { AggregationsHubService } from '../../signalr/aggregations/aggregations.hub.service';
import { LiveHubService } from '../../signalr/live/live.hub.service';

const CACHE_TYPE = 'queue';

@Injectable({
    providedIn: 'root',
})
export class QueueService implements OnDestroy {
    private _newQueues = new BehaviorSubject<IQueue[]>([] as IQueue[]);
    private _queues = signal<Queue[]>([]);
    private subscriptions = new Subscription();

    loading = signal<boolean>(true);
    queues$ = this._newQueues.asObservable().pipe(
        filter(queue => !!queue && queue.length > 0),
        // take(1),
    );
    /**
     * @deprecated Use queues$ instead
     * Marked as deprecated until we clean up references to this property outside of the templates
     */
    queues = computed<IQueue[]>(() => this._queues.asReadonly()());

    constructor(
        private aggregationsHubService: AggregationsHubService,
        private liveHubService: LiveHubService,
        private rbacApiService: RbacApiService,
        private businesUnitService: BusinessUnitService,
        private channelProviderService: ChannelProviderService,
        private loggerService: LoggerService,
        private cacheService: CacheService,
    ) {
        const reportSubscription =
            this.aggregationsHubService.queueReport$.subscribe(
                (queueReport: IQueueWithChannelsReport) => {
                    this.setReport(queueReport);
                },
            );

        const informationSubscription =
            this.liveHubService.queueInformation$.subscribe(
                (queueInformation: IQueueInformation) => {
                    this.setInformation(queueInformation);
                },
            );

        this.subscriptions.add(reportSubscription);
        this.subscriptions.add(informationSubscription);
    }

    getKpi(queueId: string): ITelXLKpiQueueThreshold {
        return this._queues
            .asReadonly()()
            .find(q => q.id === queueId)!.kpiTelXLThreshold;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    set(queueIds: string[]) {
        this.doQueueStuff(queueIds);
    }

    setInformation(queueInformation: IQueueInformation, writeToCache = true) {
        queueInformation.businessUnitId =
            queueInformation.businessUnitId ?? NBU_ID;

        const queueUpdate = this._queues
            .asReadonly()()
            .map(q =>
                q.id === queueInformation.id
                    ? new Queue(
                          q.id,
                          this.channelProviderService.getChannelProvidersByWorkflowId(
                              queueInformation.workflowId,
                          ),
                          queueInformation,
                          q.report,
                          q.kpiTelXLThreshold,
                      )
                    : q,
            );

        this._queues.set(queueUpdate);
        // TODO - this should be refactored
        this._newQueues.next(queueUpdate);

        const businessUnit = queueUpdate.find(
            q => q.id === queueInformation.id,
        );
        if (businessUnit) {
            this.businesUnitService.updateQueue(businessUnit);
        } else {
            this.loggerService.error(
                `Queue ${queueInformation.name} not found as expected`,
                queueInformation,
            );
        }

        if (writeToCache) {
            this.cacheService.saveToCache(
                `queue-information-${queueInformation.id}`,
                queueInformation,
            );
        }
    }

    setKpi(
        queueId: string,
        kpiThreshold?: IKpiQueueThreshold,
        telXLKpiThreshold?: ITelXLKpiQueueThreshold,
        writeToCache = true,
    ) {
        const threshold =
            telXLKpiThreshold ??
            this.assignKpiThresholdFromLegacy(kpiThreshold);

        const queueUpdate = this._queues
            .asReadonly()()
            .map(q =>
                q.id === queueId
                    ? new Queue(
                          q.id,
                          q.channelProviders,
                          q.information,
                          q.report,
                          threshold,
                      )
                    : q,
            );

        this._queues.set(queueUpdate);
        // TODO - this should be refactored
        this._newQueues.next(queueUpdate);

        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueId) as Queue,
        );

        if (writeToCache && threshold) {
            this.cacheService.saveToCache(
                `queue-threshold-${queueId}`,
                threshold,
            );
        }
    }

    setNew(queueIds: string[]) {
        this.doQueueStuff(queueIds);
    }

    setReport(queueReport: IQueueWithChannelsReport, writeToCache = true) {
        if (queueReport.name === 'Not found') return;

        const queueUpdate = this._queues
            .asReadonly()()
            .map(q =>
                q.id === queueReport.id
                    ? new Queue(
                          q.id,
                          q.channelProviders,
                          q.information,
                          queueReport,
                          q.kpiTelXLThreshold,
                      )
                    : q,
            );

        this._queues.set(queueUpdate);
        // TODO - this should be refactored
        this._newQueues.next(queueUpdate);

        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueReport.id) as Queue,
        );

        if (writeToCache) {
            this.cacheService.saveToCache(
                `queue-report-${queueReport.id}`,
                queueReport,
            );
        }
    }

    private assignKpiThresholdFromLegacy(
        legacyKpiThreshold?: IKpiQueueThreshold,
    ): ITelXLKpiQueueThreshold | undefined {
        if (!legacyKpiThreshold) return undefined;

        return {
            slaThreshold: {
                low: legacyKpiThreshold.slaThreshold ?? 0,
                high: legacyKpiThreshold.slaThreshold ?? 0,
                enabled:
                    legacyKpiThreshold.slaThreshold !== undefined &&
                    legacyKpiThreshold.slaThreshold !== null,
            },
            maxConversationsWaiting: {
                low: legacyKpiThreshold.maxConversationsWaiting ?? 0,
                high: legacyKpiThreshold.maxConversationsWaiting ?? 0,
                enabled:
                    legacyKpiThreshold.maxConversationsWaiting !== undefined &&
                    legacyKpiThreshold.maxConversationsWaiting !== null,
            },
            averageWaitTimeSeconds: {
                low: legacyKpiThreshold.averageWaitTimeSeconds ?? 0,
                high: legacyKpiThreshold.averageWaitTimeSeconds ?? 0,
                enabled:
                    legacyKpiThreshold.averageWaitTimeSeconds !== undefined &&
                    legacyKpiThreshold.averageWaitTimeSeconds !== null,
            },
            maxLongestWaitTimeSeconds: {
                low: legacyKpiThreshold.maxLwtSeconds ?? 0,
                high: legacyKpiThreshold.maxLwtSeconds ?? 0,
                enabled:
                    legacyKpiThreshold.maxLwtSeconds !== undefined &&
                    legacyKpiThreshold.maxLwtSeconds !== null,
            },
        };
    }

    private doQueueStuff(queueIds: string[]) {
        const queues = queueIds.map(id => new Queue(id));

        this._queues.set(queues);
        // TODO - this should be refactored
        this._newQueues.next(queues);

        this._queues
            .asReadonly()()
            .forEach(queue => {
                const cacheHandlers: CacheHandler<any>[] = [
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'information',
                        setter: data =>
                            this.setInformation(
                                data as IQueueInformation,
                                false,
                            ),
                    },
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'report',
                        setter: data =>
                            this.setReport(
                                data as IQueueWithChannelsReport,
                                false,
                            ),
                    },
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'threshold',
                        setter: data =>
                            this.setKpi(
                                queue.id,
                                undefined,
                                data as ITelXLKpiQueueThreshold,
                                false,
                            ),
                    },
                ];

                cacheHandlers.forEach(handler =>
                    this.cacheService.handleCache(
                        handler.cacheType,
                        handler.cacheKey,
                        queue.id,
                        handler.setter,
                    ),
                );
            });

        // We should try to remove the below code to keep the method single responsibility
        this._queues
            .asReadonly()()
            .forEach(q =>
                this.rbacApiService.getKpiThresholds(q.id, 'queues').subscribe({
                    next: kpiThresholds => {
                        if (kpiThresholds) {
                            this.setKpi(
                                q.id,
                                kpiThresholds.kpiThreshold as IKpiQueueThreshold,
                                kpiThresholds.kpiTelXLThreshold as ITelXLKpiQueueThreshold,
                            );
                        }
                    },
                    complete: () => this.loading.set(false),
                }),
            );
    }
}
