import { computed, Injectable, signal } from '@angular/core';
import { RbacApiService } from '@api/rbac/rbac.api.service';
import { Agent } from '@models/agent/agent';
import {
    IKpiTeamThreshold,
    ITeamThreshold,
    ITelXLKpiTeamThreshold,
} from '@models/rbac/team-kpi';
import { IRbacTeam } from '@models/rbac/teams';
import { ITeam, Team } from '@models/team/team';

@Injectable({
    providedIn: 'root',
})
export class TeamService {
    private _teams = signal<Team[]>([]);
    teams = computed<ITeam[]>(() => this._teams.asReadonly()());
    loading = signal<boolean>(true);

    constructor(private rbacApiService: RbacApiService) {}

    set(teams: IRbacTeam[]) {
        this._teams.set(
            teams.map(
                t =>
                    new Team(
                        t.id,
                        t.name,
                        t.memberIds.map(m => new Agent(m)),
                        t.queueIds,
                    ),
            ),
        );

        this._teams
            .asReadonly()()
            .forEach(t =>
                this.rbacApiService.getKpiThresholds(t.id, 'teams').subscribe({
                    next: kpiThresholds => {
                        if (kpiThresholds) {
                            this.setKpi(
                                t.id,
                                kpiThresholds.kpiThreshold as IKpiTeamThreshold,
                                kpiThresholds.kpiTelXLThreshold as ITelXLKpiTeamThreshold,
                            );
                        }
                    },
                    complete: () => this.loading.set(false),
                }),
            );
    }

    getKpi(teamId: string): ITelXLKpiTeamThreshold {
        return (
            this._teams
                .asReadonly()()
                .find(t => t.id === teamId) as Team
        ).kpiTelXLThreshold;
    }

    setKpi(
        teamId: string,
        kpiThreshold?: IKpiTeamThreshold,
        telXLKpiThreshold?: ITelXLKpiTeamThreshold,
    ) {
        const threshold =
            telXLKpiThreshold ??
            this.assignKpiThresholdFromLegacy(kpiThreshold);

        const teamUpdate = this._teams
            .asReadonly()()
            .map(t =>
                t.id === teamId
                    ? new Team(t.id, t.name, t._agents, t.queues, threshold)
                    : t,
            );

        this._teams.set(teamUpdate);
    }

    updateAgent(agent: Agent) {
        const memberOfTeams = this._teams
            .asReadonly()()
            .filter(t => t.agents.some(a => a.id === agent.id));

        memberOfTeams.forEach(team => {
            const agentUpdate = team._agents.map(a =>
                a.id === agent.id ? agent : a,
            );

            const teamUpdate = this._teams
                .asReadonly()()
                .map(t =>
                    t.id === team.id
                        ? new Team(
                              t.id,
                              t.name,
                              agentUpdate,
                              t.queues,
                              t.kpiTelXLThreshold,
                          )
                        : t,
                );

            this._teams.set(teamUpdate);
        });
    }

    private assignKpiThresholdFromLegacy(
        legacyKpiThreshold?: IKpiTeamThreshold,
    ): ITelXLKpiTeamThreshold | undefined {
        if (!legacyKpiThreshold) return undefined;

        return {
            averageHandlingTimeSeconds: {
                voice: {
                    target:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Voice ??
                        0,
                    low:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Voice ??
                        0,
                    high:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Voice ??
                        0,
                    enabled:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Voice !==
                            undefined &&
                        legacyKpiThreshold.averageHandlingTimeSeconds.Voice !==
                            null,
                },
                webchat: {
                    target:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Chat ?? 0,
                    low:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Chat ?? 0,
                    high:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Chat ?? 0,
                    enabled:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Chat !==
                            undefined &&
                        legacyKpiThreshold.averageHandlingTimeSeconds.Chat !==
                            null,
                },
                email: {
                    target:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Email ??
                        0,
                    low:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Email ??
                        0,
                    high:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Email ??
                        0,
                    enabled:
                        legacyKpiThreshold.averageHandlingTimeSeconds.Email !==
                            undefined &&
                        legacyKpiThreshold.averageHandlingTimeSeconds.Email !==
                            null,
                },
                socials: {
                    target:
                        legacyKpiThreshold.averageHandlingTimeSeconds
                            .External ?? 0,
                    low:
                        legacyKpiThreshold.averageHandlingTimeSeconds
                            .External ?? 0,
                    high:
                        legacyKpiThreshold.averageHandlingTimeSeconds
                            .External ?? 0,
                    enabled:
                        legacyKpiThreshold.averageHandlingTimeSeconds
                            .External !== undefined &&
                        legacyKpiThreshold.averageHandlingTimeSeconds
                            .External !== null,
                },
                average: this.calculateAverageFromLegacy(legacyKpiThreshold),
            },
            handledRatio: {
                voice: legacyKpiThreshold.handledRatio.Voice ?? 0,
                webchat: legacyKpiThreshold.handledRatio.Chat ?? 0,
                email: legacyKpiThreshold.handledRatio.Email ?? 0,
                socials: legacyKpiThreshold.handledRatio.External ?? 0,
            },
        };
    }

    private calculateAverageFromLegacy(
        legacyKpiThreshold: IKpiTeamThreshold,
    ): ITeamThreshold {
        let value = 0,
            count = 0;

        if (
            legacyKpiThreshold.averageHandlingTimeSeconds.Voice !== undefined &&
            legacyKpiThreshold.averageHandlingTimeSeconds.Voice !== null
        ) {
            value += legacyKpiThreshold.averageHandlingTimeSeconds.Voice;
            count++;
        }

        if (
            legacyKpiThreshold.averageHandlingTimeSeconds.Email !== undefined &&
            legacyKpiThreshold.averageHandlingTimeSeconds.Email !== null
        ) {
            value += legacyKpiThreshold.averageHandlingTimeSeconds.Email;
            count++;
        }

        if (
            legacyKpiThreshold.averageHandlingTimeSeconds.Chat !== undefined &&
            legacyKpiThreshold.averageHandlingTimeSeconds.Chat !== null
        ) {
            value += legacyKpiThreshold.averageHandlingTimeSeconds.Chat;
            count++;
        }

        if (
            legacyKpiThreshold.averageHandlingTimeSeconds.External !==
                undefined &&
            legacyKpiThreshold.averageHandlingTimeSeconds.External !== null
        ) {
            value += legacyKpiThreshold.averageHandlingTimeSeconds.External;
            count++;
        }

        if (count > 0) value /= count;

        return {
            target: Math.round(value),
            high: Math.round(value),
            low: Math.round(value),
            enabled: count > 0,
        };
    }
}
