<div class="row mb-4">
    <div class="col-10 font-large">
        <progressbar
            label="Available"
            orientation="horizontal"
            [overall]="utilisation.Idle.percentage.toString()"
            [metric]="{
                percent: utilisation.Idle.percentage,
                states: [{ value: 0, state: 'success' }],
            }"
            defaultState="success"
            [percentage]="true"
            emphasis="light"
        />
    </div>
    <div
        class="col-2 font-small d-flex align-items-center justify-content-center"
    >
        ({{
            secondsToDuration(utilisation.Idle.value)
                | formatDuration: '[dd]:hh:mm:ss'
        }})
    </div>
</div>
<div class="row mb-4">
    <div class="col-10">
        <progressbar
            label="Busy"
            orientation="horizontal"
            [overall]="utilisation.Busy.percentage.toString()"
            [metric]="{
                percent: utilisation.Busy.percentage,
                states: [{ value: 0, state: 'danger' }],
            }"
            defaultState="danger"
            [percentage]="true"
            emphasis="light"
        />
    </div>
    <div
        class="col-2 font-small d-flex align-items-center justify-content-center"
    >
        ({{
            secondsToDuration(utilisation.Busy.value)
                | formatDuration: '[dd]:hh:mm:ss'
        }})
    </div>
</div>
<div class="row mb-4">
    <div class="col-10">
        <progressbar
            label="On Break"
            orientation="horizontal"
            [overall]="utilisation.Break.percentage.toString()"
            [metric]="{
                percent: utilisation.Break.percentage,
                states: [{ value: 0, state: 'warning' }],
            }"
            defaultState="warning"
            [percentage]="true"
            emphasis="light"
        />
    </div>
    <div
        class="col-2 font-small d-flex align-items-center justify-content-center"
    >
        ({{
            secondsToDuration(utilisation.Break.value)
                | formatDuration: '[dd]:hh:mm:ss'
        }})
    </div>
</div>
