import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptors,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    ApplicationConfig,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
    provideRouter,
    withEnabledBlockingInitialNavigation,
} from '@angular/router';
import {
    LogLevel,
    authInterceptor,
    provideAuth,
} from 'angular-auth-oidc-client';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '@dxp/shared/environment';
import { AuthTokenInterceptor } from '@dxp/shared/interceptors';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
        provideAuth({
            config: {
                triggerAuthorizationResultEvent: true,
                postLoginRoute: '/auth-callback',
                forbiddenRoute: '/forbidden',
                unauthorizedRoute: '/unauthorized',
                logLevel: LogLevel.Debug,
                historyCleanupOff: true,
                authority: environment.authority,
                redirectUrl: `${window.location.origin}/auth-callback`,
                postLogoutRedirectUri: window.location.origin,
                clientId: 'axis-portal',
                scope: environment.scope,
                useRefreshToken: true,
                responseType: 'code',
                silentRenew: true,
                silentRenewUrl: `${window.location.origin}/silent-renew.html`,
                renewTimeBeforeTokenExpiresInSeconds: 100,
            },
        }),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true,
        },
        provideHttpClient(
            withInterceptors([authInterceptor()]),
            withInterceptorsFromDi(),
        ),
        provideCharts(withDefaultRegisterables()),
        provideExperimentalZonelessChangeDetection(),
    ],
};
