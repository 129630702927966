import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { TelXLLoadingSpinnerService } from '../loading-spinner.service';

@Component({
    selector: 'telxl-shared-mini-loading-spinner',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './loading-spinner.component.html',
    styleUrl: './loading-spinner.component.scss',
})
export class TelXLMiniLoadingSpinnerComponent {
    loading$!: Observable<boolean>;

    constructor(public loadingSpinnerService: TelXLLoadingSpinnerService) {
        this.loading$ = this.loadingSpinnerService.loading$;
    }
}
