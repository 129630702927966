import { computed, Injectable, signal } from '@angular/core';
import { Agent, IAgent, IAgentReport, IAgentState } from '@models/agent/agent';
import { CacheHandler, CacheService } from '@services/cache/cache-service';
import { LoggerService } from '@services/logger/logger.service';
import { TeamService } from '@services/team/team.service';

const CACHE_TYPE = 'agent';

@Injectable({
    providedIn: 'root',
})
export class AgentService {
    private _agents = signal<Agent[]>([]);
    agents = computed<IAgent[]>(() =>
        this._agents
            .asReadonly()()
            .filter(a => a.name !== ''),
    );

    constructor(
        private teamService: TeamService,
        private cacheService: CacheService,
        private loggerService: LoggerService,
    ) {}

    set(agentIds: string[]) {
        const _agents = agentIds.map(id => new Agent(id));
        this._agents.set(_agents);

        this._agents
            .asReadonly()()
            .forEach(agent => {
                const cacheHandlers: CacheHandler<any>[] = [
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'state',
                        setter: data =>
                            this.setState(data as IAgentState[], false),
                    },
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'report',
                        setter: data =>
                            this.setReport(data as IAgentReport, false),
                    },
                ];

                cacheHandlers.forEach(handler =>
                    this.cacheService.handleCache(
                        handler.cacheType,
                        handler.cacheKey,
                        agent.id,
                        handler.setter,
                    ),
                );
            });
    }

    setReport(agentReport: IAgentReport, writeToCache = true) {
        const agentUpdate = this._agents
            .asReadonly()()
            .map(a =>
                a.id === agentReport.id
                    ? new Agent(a.id, agentReport, a.state)
                    : a,
            );

        this._agents.set(agentUpdate);
        this.teamService.updateAgent(
            agentUpdate.find(a => a.id === agentReport.id) as Agent,
        );

        if (writeToCache) {
            this.cacheService.saveToCache(
                `agent-report-${agentReport.id}`,
                agentReport,
            );
        }
    }

    setState(_agentstates: IAgentState[], writeToCache = true) {
        _agentstates
            .filter(as => as.agentName !== '')
            .forEach(as => {
                const agentUpdate = this._agents
                    .asReadonly()()
                    .map(a =>
                        a.id === as.agentId ? new Agent(a.id, a.report, as) : a,
                    );

                this._agents.set(agentUpdate);

                const agent = agentUpdate.find(a => a.id === as.agentId);
                if (agent) {
                    this.teamService.updateAgent(agent);
                } else {
                    this.loggerService.error(
                        `Agent ${as.agentName} not found as expected`,
                        as,
                    );
                }

                if (writeToCache) {
                    this.cacheService.saveToCache(`agent-state-${as.agentId}`, [
                        as,
                    ]);
                }
            });
    }
}
