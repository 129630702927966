import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable, share, tap } from 'rxjs';
import { environment } from './../../environments';
import { LoggerService } from './../../services';

@Injectable({
    providedIn: 'root',
})
export class PresenceApiService {
    private _breakNames = signal<string[]>([]);
    breakNames = computed<string[]>(() => this._breakNames.asReadonly()());

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    login(): Observable<void> {
        return this.http
            .post<void>(environment.blenderUrl + '/resource/login', null)
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Presence Service (Login) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Presence Service (Login) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    logout(): Observable<void> {
        return this.http
            .post<void>(environment.blenderUrl + '/resource/logout', null)
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Presence Service (Logout) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Presence Service (Logout) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    activate() {
        this.http
            .post<void>(environment.blenderUrl + '/resource/activate', null)
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Presence Service (Agent Activation) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Presence Service (Agent Activation) -> Failed',
                        error,
                    );
                },
            });
    }

    /**
     * @deprecated Use agents$ instead
     * Marked as deprecated until we clean up references to this property outside of the templates
     */
    activateNew(): Observable<void> {
        return this.http
            .post<void>(environment.blenderUrl + '/resource/activate', null)
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Presence Service (Agent Activation) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Presence Service (Agent Activation) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    goToBreak(breakName: string) {
        this.http
            .post<void>(
                environment.blenderUrl +
                    `/resource/moveToBreak?breakName=${breakName ?? ''}`,
                null,
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Presence Service (Agent on Break) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Presence Service (Agent on Break) -> Failed',
                        error,
                    );
                },
            });
    }

    getBreakNames() {
        this.http
            .get<string[]>(environment.blenderUrl + '/resource/breaknames')
            .subscribe({
                next: breakNames => {
                    this._breakNames.set(breakNames);

                    this.logger.debug(
                        'Presence Service (Get Break Names) -> Successful',
                        breakNames,
                    );
                },
                error: error => {
                    this.logger.error(
                        'Presence Service (Get Break Names) -> Failed',
                        error,
                    );
                },
            });
    }
}
