export const AgentStates = [
    'Busy',
    'Break',
    'Idle',
    'LoggedOut',
    'PendingLogout',
    'PendingBreak',
] as const;
export type AgentState = (typeof AgentStates)[number];

export interface IUserPresence {
    state: AgentState;
    stateSince: Date;
    label: string;
    class: string;
    online: boolean;
    timedBreakExpiresAt?: Date;
}

export const calculateUserPresence = (
    state: AgentState,
    stateSince: Date,
    breakName?: string,
    timedBreakExpiresAt?: Date,
): IUserPresence => {
    return {
        state: state,
        stateSince: stateSince,
        label: stateToLabel(state, breakName),
        class: stateToClass(state),
        online: stateToOnline(state),
        timedBreakExpiresAt: timedBreakExpiresAt,
    };
};

const stateToLabel = (state: AgentState, breakName?: string): string => {
    switch (state) {
        case 'LoggedOut':
            return 'Logged out';
        case 'Idle':
            return 'Available';
        case 'Break':
            return breakName || 'Break';
        default:
            return state;
    }
};

const stateToClass = (state: AgentState): string => {
    switch (state) {
        case 'Break':
            return 'warning';
        case 'Idle':
            return 'success';
        case 'Busy':
            return 'danger';
        case 'LoggedOut':
        case 'PendingLogout':
        case 'PendingBreak':
            return 'secondary';
    }
};

const stateToOnline = (state: AgentState): boolean => {
    return state === 'Idle';
};
