@if (orientation === 'vertical') {
    <div class="h-100">
        <div class="font-small weight-medium p-0 m-0">{{ label }}</div>
        <div class="font-largest weight-medium p-0 m-0">
            {{ overall }}
            <span class="font-small weight-heavy">
                @if (percentage) {
                    %
                }
            </span>
        </div>
        @if (metric) {
            <ngb-progressbar [type]="state" [value]="progress" height=".3rem" />
        } @else {
            <span style="height: 0.3rem; display: flex"></span>
        }
    </div>
}

@if (orientation === 'horizontal') {
    <div [ngClass]="class">
        <div
            [ngStyle]="{
                display: 'flex',
                'justify-content': 'space-between',
                'align-items': emphasis === 'light' ? 'center' : 'flex-start',
            }"
        >
            <span
                class="p-0 m-0"
                [ngClass]="
                    emphasis === 'light'
                        ? 'font-small weight-light'
                        : 'font-medium weight-medium'
                "
            >
                {{ label }}
            </span>
            <span
                class="weight-medium p-0 m-0"
                [ngClass]="[
                    percentage || emphasis === 'light' ? '' : 'pb-1',
                    emphasis === 'light' ? 'font-large' : 'font-largest',
                ]"
                [ngStyle]="
                    emphasis === 'light' ? { 'line-height': '1rem' } : {}
                "
            >
                {{ overall }}
                @if (percentage) {
                    <span
                        class="font-small weight-heavy"
                        [ngClass]="{
                            'fix-small-percentage': emphasis === 'light',
                        }"
                    >
                        %
                    </span>
                }
            </span>
        </div>
        @if (metric) {
            <ngb-progressbar [type]="state" [value]="progress" height=".3rem" />
        }
    </div>
}
