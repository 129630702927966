import { Injectable, signal } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, merge, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AggregationLevel } from '../../models/data/aggregate-level';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import { QueueService } from '../../services/queue/queue.service';
import { UserService } from '../../services/user/user.service';

export interface ISegment {
    id: string;
    label: string;
    url: string;
    level: AggregationLevel;
}

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    private _segmentsSubject = new BehaviorSubject<ISegment[]>([]);
    segments$ = this._segmentsSubject.asObservable().pipe(
        filter(segments => segments.length > 0),
        tap(segments => {
            this.current.set(
                segments && segments.length > 0
                    ? segments[segments.length - 1]
                    : undefined,
            );
        }),
    );

    current = signal<ISegment | undefined>(undefined);
    subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private userService: UserService,
        private businessUnitService: BusinessUnitService,
        private queueService: QueueService,
    ) {
        const root = this.router.routerState.snapshot.root;

        // Initial segments update
        const segments = this.processRouteSegments(
            root,
            '',
            AggregationLevel.None,
        );
        this._segmentsSubject.next(segments);

        // Subscription to the observable
        this.subscription.add(merge(
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                tap(() => {
                    const currentRoot = this.router.routerState.snapshot.root;
                    const segments = this.processRouteSegments(
                        currentRoot,
                        '',
                        AggregationLevel.None,
                    );

                    this._segmentsSubject.next(segments);
                }),
            ),
            this.segments$,
        )
        .subscribe({
            next: (value) => {
                console.log('Merged Observable Emission:', value);
            },
            error: (err) => {
                console.error('Error in merged observables:', err);
            },
            complete: () => {
                console.log('Merged observables complete');
            }
        }));
    }

    private processRouteSegments(
        route: ActivatedRouteSnapshot,
        url: string,
        level: AggregationLevel,
    ): ISegment[] {
        let segments: ISegment[] = [];
        if (route) {
            const newSegments = route.url
                .map(segment => {
                    if (segment.path) {
                        level++;
                        url += `/${segment.path}`;
                        return {
                            id: segment.path,
                            label: this.getBreadcrumbLabel(level, segment.path),
                            url,
                            level,
                        };
                    }
                    return null;
                })
                .filter(segment => segment !== null) as ISegment[];

            segments.push(...newSegments);

            if (route.children.length > 0) {
                route.children.forEach(child => {
                    segments = segments.concat(
                        this.processRouteSegments(child, url, level),
                    );
                });
            }
        }
        return segments;
    }

    private getBreadcrumbLabel(level: AggregationLevel, id: string): string {
        switch (level) {
            case AggregationLevel.Tenant:
                return this.userService.user().tenant?.name ?? '';

            case AggregationLevel.BusinessUnit:
                return (
                    this.businessUnitService
                        .businessUnits()
                        ?.find(bu => bu.id === id)?.name ?? ''
                );

            case AggregationLevel.Queue:
                return (
                    this.queueService.queues().find(q => q.id === id)?.name ??
                    ''
                );

            default:
                return '';
        }
    }
}
