import { Component, effect, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@base/components/header/header.component';
import { TaskBeltComponent } from '@base/components/task-belt/container/task-belt.component';
import { NavigationService } from '@services/navigation/navigation.service';
import { Router } from '@angular/router';

@Component({
    selector: 'main-layout',
    standalone: true,
    imports: [CommonModule, HeaderComponent, RouterModule, TaskBeltComponent],
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnDestroy {
    private navigationEffect = effect(
        () => {
            const route = this.navigationService.navigateSignal();
            if (route) {
                const { path, params } = route;
                if (params) {
                    this.router.navigate([path], { queryParams: params });
                } else {
                    this.router.navigate([path]);
                }
                this.navigationService.resetNavigation();
            }
        },
        { allowSignalWrites: true },
    );

    constructor(
        private navigationService: NavigationService,
        private router: Router,
    ) {}

    ngOnDestroy() {
        this.navigationEffect.destroy();
    }
}
