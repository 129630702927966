@if (loading$ | async) {
    <div
        class="d-flex justify-content-center align-items-center w-100 h-100 bg-light bg-opacity-50"
        style="z-index: 1050"
    >
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading Data...</span>
        </div>
    </div>
}
