@if (conversation) {
    <div class="container-fluid w-auto">
        <div class="row">
            <div class="col-2 p-0">
                <div class="rounded-circle user-initials mt-3 ms-1">
                    {{ customerName | initials }}
                </div>
            </div>
            <div class="col-10">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                        <div class="font-xs mt-2 text-secondary">
                            Incoming CLI:
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <div class="font-xxl">
                            {{ conversation.fromNumber }}
                        </div>
                    </div>
                </div>
                <div
                    class="font-xl d-flex justify-content-between align-items-center"
                >
                    {{ customerName }}
                </div>
                <div class="d-flex align-items-start mt-3 mb-3">
                    <div class="flex-grow-1">
                        <div class="d-flex align-items-center">
                            <div class="col-4 font-xs me-1 text-secondary">
                                Number dialled:
                            </div>
                            <div class="col-8 font-sm">
                                {{ conversation.dialedNumber }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-4 font-xs me-1 text-secondary">
                                Queue name:
                            </div>
                            <div class="col-8 font-sm">
                                {{ conversation.queueName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
