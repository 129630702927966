import { Injectable, signal, ViewChild, ElementRef } from '@angular/core';
import {
    UserAgent,
    UserAgentOptions,
    Registerer,
    RegistererOptions,
    SessionState,
    URI,
    Invitation,
    Session,
    // SessionDescriptionHandler,
} from 'sip.js';

/*
interface MySessionDescriptionHandler extends SessionDescriptionHandler {
    peerConnection: RTCPeerConnection;
}
*/

@Injectable({
    providedIn: 'root',
})
export class SipService {
    localStream = signal<MediaStream | null>(null);
    remoteStream = signal<MediaStream | null>(null);
    public callState = signal<string>('idle');

    private userAgents: UserAgent[] = [];
    private registerers: Registerer[] = [];
    private currentSession: Session | null = null;
    public incomingInvitation = signal<Invitation | null>(null);
    public registrationStatus = signal<string>('Not Registered');

    private ringingAudio: HTMLAudioElement = new Audio(
        '././assets/sounds/telephone-ring-04.mp3',
    );

    @ViewChild('remoteAudio') remoteAudioRef!: ElementRef<HTMLAudioElement>;

    constructor() {
        this.ringingAudio.loop = true;
    }

    async initializeSipClients(
        servers: { uri: string }[],
        stunServers: string[],
        username: string,
        password: string,
    ) {
        const registrationPromises = servers.map(server => {
            return this.registerWithServer(
                server.uri,
                stunServers,
                username,
                password,
            );
        });

        await Promise.all(registrationPromises);
        console.log('All registrations completed successfully');
    }

    private async registerWithServer(
        server: string,
        stunServers: string[],
        username: string,
        password: string,
    ): Promise<void> {
        const uri = new URI('sip', username, server);
        const wsServer = 'wss://' + server + ':8089/ws';
        const userAgentOptions: UserAgentOptions = {
            uri: uri,
            transportOptions: {
                server: wsServer,
            },
            authorizationUsername: username,
            authorizationPassword: password,
            sessionDescriptionHandlerFactoryOptions: {
                rtcConfiguration: {
                    iceServers: stunServers.map(url => ({ urls: url })),
                },
                constraints: {
                    audio: true,
                    video: false,
                },
            },
        };

        const userAgent = new UserAgent(userAgentOptions);
        this.userAgents.push(userAgent);

        const registererOptions: RegistererOptions = {
            expires: 60,
        };

        const registerer = new Registerer(userAgent, registererOptions);
        this.registerers.push(registerer);

        try {
            await userAgent.start();
            console.log(`User Agent started for server: ${wsServer}`);

            userAgent.delegate = {
                onInvite: invitation => this.handleIncomingCall(invitation),
            };

            await registerer.register();
            console.log(`Successfully registered with server: ${wsServer}`);
        } catch (error) {
            console.error(`Failed to register with server: ${wsServer}`, error);
        }
    }

    /*private async initializeLocalStream(): Promise<MediaStream | null> {
        try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        this.localStream.set(stream);
        return stream;
        } catch (err) {
        console.error('Error accessing microphone:', err);
        return null;
        }
    }*/

    private stopLocalStream(): void {
        if (this.localStream()) {
            this.localStream()
                ?.getTracks()
                .forEach(track => track.stop());
            this.localStream.set(null);
        }
    }

    private handleIncomingCall(invitation: Invitation): void {
        // const callerURI = invitation.remoteIdentity.uri.toString();
        // const callerDisplayName = invitation.remoteIdentity.displayName;
        // const callerUsername = invitation.remoteIdentity.uri.user;
        this.incomingInvitation.set(invitation);
        this.playRingingSound();
        this.callState.set('ringing');

        invitation.stateChange.addListener(state => {
            if (state === SessionState.Terminated) {
                this.stopRingingSound();
                this.incomingInvitation.set(null);
            }
        });
    }

    public async answerCall(): Promise<void> {
        const invitation = this.incomingInvitation();
        if (!invitation) return;
        invitation.stateChange.addListener(state => {
            if (state === SessionState.Established) {
                this.callState.set('in-call');
                const remoteStream = new MediaStream();
                const sessionDescriptionHandler =
                    invitation.sessionDescriptionHandler as any;
                const peerConnection =
                    sessionDescriptionHandler?.peerConnection;

                if (peerConnection) {
                    peerConnection
                        .getReceivers()
                        .forEach((receiver: { track: MediaStreamTrack }) => {
                            if (receiver.track) {
                                remoteStream.addTrack(receiver.track);
                            }
                        });

                    const audioElement = document.querySelector(
                        '#remoteAudio',
                    ) as HTMLAudioElement;
                    audioElement.srcObject = remoteStream;
                    audioElement.play();
                }
            } else if (state === SessionState.Terminated) {
                this.callState.set('terminated');
                this.stopLocalStream();
                this.remoteStream.set(null);
            }
        });

        try {
            await invitation.accept({
                sessionDescriptionHandlerOptions: {
                    constraints: { audio: true, video: false },
                },
            });
            this.currentSession = invitation;
            this.incomingInvitation.set(null);
            this.stopRingingSound();
        } catch (error) {
            console.error('Failed to accept call:', error);
            this.stopLocalStream();
            this.stopRingingSound();
        }
    }

    public hangUp(): void {
        if (
            this.currentSession &&
            this.currentSession.state !== SessionState.Terminated
        ) {
            this.currentSession.bye();
            this.currentSession = null;
        }
        this.stopLocalStream();
        this.stopRingingSound();
        this.incomingInvitation.set(null);
    }
    /*
    private attachLocalStreamToSession(session: Session, localStream: MediaStream) {
        const sessionDescriptionHandler = session.sessionDescriptionHandler as MySessionDescriptionHandler | undefined;
        if (sessionDescriptionHandler) {
            const pc = sessionDescriptionHandler.peerConnection;
            if (localStream) {
                localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));
            }

            pc.ontrack = (event) => {
                const remoteStream = new MediaStream();
                event.streams[0].getTracks().forEach((track) => remoteStream.addTrack(track));
                this.remoteStream.set(remoteStream);
            };
        }
    }
    */
    private playRingingSound(): void {
        try {
            this.ringingAudio.play();
        } catch (error) {
            console.error('Error playing ringing sound:', error);
        }
    }

    private stopRingingSound(): void {
        this.callState.set('terminated');
        this.ringingAudio.pause();
        this.ringingAudio.currentTime = 0;
    }
}
