import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ITab {
    label: string;
    panel: string;
}

@Component({
    selector: 'tabs',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './tabs.component.html',
    styleUrl: './tabs.component.scss',
})
export class TabsComponent {
    @Input() tabs: { label: string; panel: string }[] = [];
    @Input() activePanel!: string;
    @Input() fontSize!: string;
    @Input() fontWeight!: string;

    @Output() activePanelChange = new EventEmitter<string>();

    setActivePanel(panel: string) {
        this.activePanelChange.emit(panel);
    }
}
