import { Duration, intervalToDuration } from 'date-fns';

export const secondsToDuration = (totalSeconds: number) => {
    const startDate = new Date(0);
    const endDate = new Date(totalSeconds * 1000);

    return intervalToDuration({
        start: startDate,
        end: endDate,
    });
};

export const durationToSeconds = (duration: Duration): number => {
    const daysInSeconds = (duration.days ?? 0) * 86400;
    const hoursInSeconds = (duration.hours ?? 0) * 3600;
    const minutesInSeconds = (duration.minutes ?? 0) * 60;
    const seconds = duration.seconds ?? 0;

    return daysInSeconds + hoursInSeconds + minutesInSeconds + seconds;
};
