import { ErrorReason, ErrorType } from './error';
import { IMessage, IMessageRecommendation, ISender } from './message';

export interface IConversationEvent {
    conversationId: string;
}

export interface TransferInitiatedBy {
    agentId: string;
    agentName: string;
}

export interface IConversationInfoEvent extends IConversationEvent {
    queueId?: string;
    isOutbound: boolean | null;
    ronaTimerExpiresAt?: string;
    customerName?: string;
    customerEmail?: string;
    channelName?: string;
    channelId: number;
    transferee: string | null;
    transferInitiatedBy: TransferInitiatedBy | null;
}

export interface IChatMessageEvent extends IConversationEvent {
    message: IMessage;
}

export interface IChatHistoryEvent extends IConversationEvent {
    messages: IMessage[];
}

export interface IParticipantTypingEvent extends IConversationEvent {
    isInternal: boolean;
    sender: ISender;
}

export interface IRecommendationEvent extends IConversationEvent {
    messageId: string;
    recommendations: IMessageRecommendation[];
}

export interface IErrorEvent extends IConversationEvent {
    errorType: ErrorType;
    errorReason: ErrorReason;
}
