import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { SipService } from '@services/sip/sip.service';
import { NavigationService } from '@services/navigation/navigation.service';

@Component({
    selector: 'task-belt-item',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './task-belt-item.component.html',
    styleUrl: './task-belt-item.component.scss',
})
export class TaskBeltItemComponent {
    @Input() workitemId = '';
    @Input() taskName = '';
    @Input() description = '';
    @Input() createdAt = '';
    @Input() icon = '';
    @Input() iconSelected = '';
    @Input() order = 0;
    @Input() isSelected = false;
    @Input() selectedClass = '';
    @Input() removing = false;
    @Input() timeout = 0;
    @Input() mediaType = '';
    @Input() navigation = '';
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    stopProgress = false;
    displayCallButtons = false;

    constructor(
        // private sipService: SipService,
        private navigationService: NavigationService,
    ) {}

    ngOnInit() {
        this.displayCallButtons = this.mediaType === 'Voice';
    }

    onClick() {
        this.clicked.emit();
        this.stopProgress = true;
        this.navigationService.triggerNavigation(this.navigation, {
            workitemId: this.workitemId,
        });
    }

    onAcceptClick(): void {
        //this.sipService.answerCall();
        this.stopProgress = true;
        this.navigationService.triggerNavigation(this.navigation);
        this.displayCallButtons = false;
    }

    onRejectClick(): void {
        //this.sipService.hangUp();
        this.stopProgress = true;
        this.displayCallButtons = false;
    }
}
