import {
    KeyValuePair,
    aggregateWorkItemsPerMediaType,
    calculateWithPercentage,
    convertToMediaTypeRecord,
} from '../../helpers/team-agents';
import { Agent, IAgent } from '../../models/agent/agent';
import {
    IHandledCountKpi,
    IWorkItemKpis,
    MediaType,
} from '../../models/agent/agent-state';
import { IValueWithMetric } from '../../models/metrics/metric';
import { AgentState, AgentStates } from '../../models/presence/user-presence';
import {
    ITelXLKpiTeamThreshold,
    TelXLKpiTeamThresholdDefaultState,
} from '../../models/rbac/team-kpi';

export interface ITeam {
    id: string;
    name: string;
    agents: IAgent[];
    activeAgents: IAgent[];
    queues: string[];
    status: {
        loggedOut: number;
        idle: number;
        break: number;
        busy: number;
        online: number;
        total: number;
    };
    businessUnits: string[];
    hasActivity: boolean;
    kpiTelXLThreshold: ITelXLKpiTeamThreshold;

    statistics: ITeamStatistics;
}

export interface ITeamStatistics {
    timeSpentInAgentStates: Record<
        AgentState,
        { value: number; percentage: number }
    >;
    timeSpentInBreakNames: Record<
        string,
        { value: number; percentage: number }
    >;
    workItemDispositionCodes: Record<
        string,
        { value: number; percentage: number }
    >;
    workItemsPerMediaType: Record<
        MediaType,
        {
            split: number;
            handledCount: number;
            missedCount: number;
            averageHandlingTimeInSeconds: IValueWithMetric;
            ratio: number;
        }
    >;
    tasks: {
        handled: number;
        missed: number;
        ratio: number;
        averageHandlingTimeInSeconds: IValueWithMetric;
    };
}

export class Team implements ITeam {
    id: string;
    name: string;
    _agents: Agent[];
    private _queues: string[];
    private _kpiTelXLThreshold: ITelXLKpiTeamThreshold;

    constructor(
        id: string,
        name: string,
        agents: Agent[],
        queues: string[],
        kpiTelXLThreshold?: ITelXLKpiTeamThreshold,
    ) {
        this.id = id;
        this.name = name;
        this._agents = agents;
        this._queues = queues;
        this._kpiTelXLThreshold =
            kpiTelXLThreshold ?? TelXLKpiTeamThresholdDefaultState;
    }

    get hasActivity(): boolean {
        return this.activeAgents.length > 0;
    }

    get agents(): IAgent[] {
        return this._agents;
    }

    get activeAgents(): IAgent[] {
        return this._agents.filter(a => a.hasActivity);
    }

    get businessUnits(): string[] {
        return [...new Set(this._agents.flatMap(a => a.businessUnits))];
    }

    get queues(): string[] {
        return this._queues;
    }

    get kpiTelXLThreshold(): ITelXLKpiTeamThreshold {
        return this._kpiTelXLThreshold;
    }

    get status(): {
        loggedOut: number;
        idle: number;
        break: number;
        busy: number;
        online: number;
        total: number;
    } {
        const status = this.activeAgents.map(a => a.status);

        const aggregate = status.reduce(
            (current, next) => {
                switch (next.state) {
                    case 'Busy':
                        current.busy += 1;
                        current.online += 1;
                        break;
                    case 'Idle':
                        current.idle += 1;
                        current.online += 1;
                        break;
                    case 'Break':
                        current.break += 1;
                        current.online += 1;
                        break;
                    case 'LoggedOut':
                        current.loggedOut += 1;
                        break;
                }
                return current;
            },
            {
                loggedOut: 0,
                idle: 0,
                break: 0,
                busy: 0,
                online: 0,
                total: this.agents.length,
            },
        );

        return { ...aggregate, total: this.agents.length };
    }

    get statistics(): ITeamStatistics {
        const aggregatedTimeSpentInAgentStates =
            this.aggregateAgentData<number>(
                agent => agent.statistics.timeSpentInAgentStates,
                next => next.value,
                (current, next) => current + next.value,
            );

        const aggregatedTimeSpentInBreakNames = this.aggregateAgentData<number>(
            agent => agent.statistics.timeSpentInBreakNames,
            next => next.value,
            (current, next) => current + next.value,
        );

        const aggregatedWorkItemKpisPerDispositionCode =
            this.aggregateAgentData<IHandledCountKpi>(
                agent => agent.statistics.workItemDispositionCodes,
                next => ({ handledCount: next.value }),
                (current, next) => ({
                    handledCount: current.handledCount + next.value,
                }),
            );

        const aggregatedworkItemsPerMediaType =
            this.aggregateWorkItemsPerMediaType();

        return {
            timeSpentInAgentStates: calculateWithPercentage(
                AgentStates.filter(
                    as => as !== 'LoggedOut',
                ) as readonly string[],
                aggregatedTimeSpentInAgentStates,
                value => value,
            ),
            timeSpentInBreakNames: calculateWithPercentage(
                aggregatedTimeSpentInBreakNames.map(kvp => kvp.key) ?? [],
                aggregatedTimeSpentInBreakNames ?? [],
                value => value,
            ),
            workItemDispositionCodes: calculateWithPercentage(
                aggregatedWorkItemKpisPerDispositionCode.map(kvp => kvp.key) ??
                    [],
                aggregatedWorkItemKpisPerDispositionCode ?? [],
                item => item.handledCount,
            ),
            workItemsPerMediaType: convertToMediaTypeRecord(
                aggregatedworkItemsPerMediaType ?? [],
                this._kpiTelXLThreshold.averageHandlingTimeSeconds,
            ),
            tasks: aggregateWorkItemsPerMediaType(
                aggregatedworkItemsPerMediaType ?? [],
                this._kpiTelXLThreshold.averageHandlingTimeSeconds.average,
            ),
        };
    }

    private aggregateAgentData<T>(
        getAgentData: (agent: IAgent) => Record<string, { value: any }>,
        createFunction: (next: { value: any }) => T,
        mergeFunction: (current: T, next: { value: any }) => T,
    ): KeyValuePair<T>[] {
        const aggregatedData: KeyValuePair<T>[] = [];

        this.activeAgents.forEach(agent => {
            const agentData = getAgentData(agent);
            Object.entries(agentData).forEach(([key, data]) => {
                const existingEntry = aggregatedData.find(a => a.key === key);
                if (!existingEntry) {
                    aggregatedData.push({ key, value: createFunction(data) });
                } else {
                    existingEntry.value = mergeFunction(
                        existingEntry.value,
                        data,
                    );
                }
            });
        });

        return aggregatedData;
    }

    private aggregateWorkItemsPerMediaType(): KeyValuePair<IWorkItemKpis>[] {
        const aggregatedData: KeyValuePair<IWorkItemKpis>[] = [];
        const averaging: KeyValuePair<number>[] = [];

        this.activeAgents.forEach(agent => {
            const agentData = agent.statistics.workItemsPerMediaType;

            Object.entries(agentData).forEach(([key, data]) => {
                const existingEntry = aggregatedData.find(
                    a => a.key === (key as MediaType),
                );

                if (!existingEntry) {
                    aggregatedData.push({
                        key: key as MediaType,
                        value: {
                            handledCount: data.handledCount,
                            missedCount: data.missedCount,
                            averageHandlingTimeInSeconds:
                                data.averageHandlingTimeInSeconds.value,
                        },
                    });
                } else {
                    existingEntry.value.handledCount += data.handledCount;
                    existingEntry.value.missedCount += data.missedCount;
                    existingEntry.value.averageHandlingTimeInSeconds +=
                        data.averageHandlingTimeInSeconds.value;
                }

                const existingAveragingEntry = averaging.find(
                    a => a.key === (key as MediaType),
                );

                if (!existingAveragingEntry) {
                    averaging.push({
                        key: key as MediaType,
                        value:
                            data.averageHandlingTimeInSeconds.value > 0 ? 1 : 0,
                    });
                } else {
                    existingAveragingEntry.value +=
                        data.averageHandlingTimeInSeconds.value > 0 ? 1 : 0;
                }
            });
        });

        aggregatedData.forEach(ad => {
            const average = averaging.find(a => a.key === ad.key);

            if (average && average.value > 0) {
                ad.value.averageHandlingTimeInSeconds /= average.value;
            }
        });

        return aggregatedData;
    }
}
