import { Injectable, computed, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RbacApiService } from '../../api/rbac/rbac.api.service';
import {
    BusinessUnit,
    IBusinessUnit,
} from '../../models/business-unit/business-unit';
import { Queue } from '../../models/queue/queue';
import { IRbacBusinessUnit } from '../../models/rbac/business-unit';
import { ITelXLKpiQueueThreshold } from '../../models/rbac/queue-kpi';
import { TenantService } from '../../services/tenant/tenant.service';

/**
 * BusinessUnitService is responsible for managing business units and their related data.
 * It uses Angular signals and RxJS observables to manage and expose the state of business units.
 * Signals should only be used when the data is rendered in the DOM.
 */

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    /**
     * @deprecated Use _businessUnitsObservable instead.
     */
    private _businessUnits = signal<BusinessUnit[]>([]);
    /**
     * @deprecated Use businessUnitsObservable$ instead.
     */
    businessUnits = computed<IBusinessUnit[]>(() =>
        this._businessUnits.asReadonly()(),
    );
    // New observable to eventually replace the signal-based businessUnits
    private _businessUnitsObservable = new BehaviorSubject<BusinessUnit[]>([]);
    businessUnits$ = this._businessUnitsObservable.asObservable();
    /**
     * @deprecated Listen to businessUnitsObservable$ to determine when loading completes.
     */
    loading = signal<boolean>(true);

    constructor(
        private rbacApiService: RbacApiService,
        private tenantService: TenantService,
    ) {}

    set(businessUnits: IRbacBusinessUnit[]) {
        const newBusinessUnits = businessUnits.map(
            bu => new BusinessUnit(bu.id, bu.name, []),
        );
        this._businessUnits.set(newBusinessUnits);
        // Update the new observable
        this._businessUnitsObservable.next(newBusinessUnits);

        this._businessUnits
            .asReadonly()()
            .forEach(businessUnit =>
                this.rbacApiService
                    .getKpiThresholds(businessUnit.id, 'businessunits')
                    .subscribe({
                        next: kpiThresholds => {
                            if (kpiThresholds) {
                                this.setKpi(
                                    businessUnit.id,
                                    kpiThresholds.kpiTelXLThreshold as ITelXLKpiQueueThreshold,
                                );
                            }
                        },
                        complete: () => this.loading.set(false), // Deprecated
                    }),
            );
    }

    getKpi(businessUnitId: string): ITelXLKpiQueueThreshold {
        return (
            this._businessUnits
                .asReadonly()()
                .find(bu => bu.id === businessUnitId) as BusinessUnit
        ).kpiTelXLThreshold;
    }

    setKpi(businessUnitId: string, telXLKpiThreshold: ITelXLKpiQueueThreshold) {
        const businessUnitUpdate = this._businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === businessUnitId
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          bu._queues,
                          telXLKpiThreshold,
                      )
                    : bu,
            );

        this._businessUnits.set(businessUnitUpdate);
        // Update the new observable
        this._businessUnitsObservable.next(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }

    updateQueue(queue: Queue) {
        if (!queue.businessUnit || queue.name.length === 0) return;

        const businessUnit = this._businessUnits
            .asReadonly()()
            .find(bu => bu.id === queue.businessUnit);

        const queues = [
            ...(businessUnit?._queues.filter(q => q.id !== queue.id) ?? []),
            ...[queue],
        ];

        const businessUnitUpdate = this._businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === queue.businessUnit
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          queues,
                          bu.kpiTelXLThreshold,
                      )
                    : bu,
            );

        this._businessUnits.set(businessUnitUpdate);
        // Update the new observable
        this._businessUnitsObservable.next(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }
}
