import { toPercentage } from '@helpers/metric';
import { IMetric, IValueWithMetric } from '@models/metrics/metric';
import {
    ChannelGroupType,
    IQueue,
    IQueueStatistics,
    Queue,
    QueueMediaType,
} from '@models/queue/queue';
import {
    ITelXLKpiQueueThreshold,
    TelXLKpiQueueThresholdDefaultState,
} from '@models/rbac/queue-kpi';

export const NBU_NAME = 'No Business Unit';
export const NBU_ID = 'nobussinessunit';

export interface IBusinessUnit {
    id: string;
    name: string;
    kpiTelXLThreshold: ITelXLKpiQueueThreshold;
    queues: IQueue[];
    activeQueues: IQueue[];
    hasActivity: boolean;
    channelGroups: ChannelGroupType[];
    mediaTypes: QueueMediaType[];

    visibleByChannelFilter(filter: ChannelGroupType | undefined): boolean;
    statistics(index?: string): IBusinessUnitStatistics;
}

export interface IBusinessUnitStatistics {
    answeredWithinSLAPercentage: IValueWithMetric;
    answeredWithinSLAPercentageMetricByQueue: IMetric[];
    waiting: IValueWithMetric;
    connected: number;
    success: number;
    complete: number;
    timedOut: number;
    abandoned: number;
    outOfSla: number;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: IValueWithMetric;
    avgWaitingTimeInSeconds: IValueWithMetric;
}

export class BusinessUnit implements IBusinessUnit {
    id: string;
    name: string;
    _queues: Queue[];
    private _kpiTelXLThreshold: ITelXLKpiQueueThreshold;

    constructor(
        id: string,
        name: string,
        queues: Queue[],
        kpiTelXLThreshold?: ITelXLKpiQueueThreshold,
    ) {
        this.id = id;
        this.name = name;
        this._queues = queues;
        this._kpiTelXLThreshold =
            kpiTelXLThreshold ?? TelXLKpiQueueThresholdDefaultState;
    }

    get hasActivity(): boolean {
        return this.activeQueues.length > 0;
    }

    get queues(): IQueue[] {
        return this._queues;
    }

    get activeQueues(): IQueue[] {
        return this._queues.filter(q => q.hasActivity);
    }

    get kpiTelXLThreshold(): ITelXLKpiQueueThreshold {
        return this._kpiTelXLThreshold;
    }

    get channelGroups(): ChannelGroupType[] {
        return [...new Set(this._queues.flatMap(q => q.channelGroups))];
    }

    get mediaTypes(): QueueMediaType[] {
        return [...new Set(this._queues.flatMap(q => q.mediaType))];
    }

    visibleByChannelFilter(filter: ChannelGroupType | undefined): boolean {
        return this._queues.some(q => q.visibleByChannelFilter(filter));
    }

    statistics(index?: ChannelGroupType): IBusinessUnitStatistics {
        if (index === undefined) {
            return this.calculatePerformance(
                this.activeQueues.map(q => q.statistics()),
            );
        }

        return this.calculatePerformance(
            this.activeQueues.map(q => q.statistics(index)),
        );
    }

    private calculatePerformance(
        queueStatistics: IQueueStatistics[],
    ): IBusinessUnitStatistics {
        if (queueStatistics.length === 0) {
            return businessUnitStatisticDefault();
        }

        const currentLongestWaitingTimeStamp = queueStatistics
            .map(qs => qs.currentLongestWaitingTimeStamp)
            .filter((timeStamp): timeStamp is Date => timeStamp !== undefined)
            .reduce((minTimeStamp: Date | undefined, current: Date) => {
                return minTimeStamp === undefined ||
                    current.getTime() < minTimeStamp.getTime()
                    ? current
                    : minTimeStamp;
            }, undefined);

        const answeredWithinSLAPercentageMetricByQueue = queueStatistics.map(
            qs => qs.answeredWithinSLAPercentage.metric,
        );

        const answeredWithinSLAPercentage = Math.round(
            queueStatistics.reduce((total: number, qs: IQueueStatistics) => {
                return total + qs.answeredWithinSLAPercentage.value;
            }, 0) / queueStatistics.length,
        );

        const waiting = queueStatistics.reduce(
            (total: number, qs: IQueueStatistics) => {
                return total + qs.waiting.value;
            },
            0,
        );

        const connected = queueStatistics.reduce(
            (total: number, qs: IQueueStatistics) => {
                return total + qs.connected;
            },
            0,
        );

        const success = queueStatistics.reduce(
            (total: number, qs: IQueueStatistics) => {
                return total + qs.success;
            },
            0,
        );

        const complete = queueStatistics.reduce(
            (total: number, qs: IQueueStatistics) => {
                return total + qs.complete;
            },
            0,
        );

        const timedOut = queueStatistics.reduce(
            (total: number, qs: IQueueStatistics) => {
                return total + qs.timedOut;
            },
            0,
        );

        const abandoned = queueStatistics.reduce(
            (total: number, qs: IQueueStatistics) => {
                return total + qs.abandoned;
            },
            0,
        );

        const outOfSla = queueStatistics.reduce(
            (total: number, qs: IQueueStatistics) => {
                return total + qs.outOfSla;
            },
            0,
        );

        const longestWaitingTimeInSeconds = queueStatistics.reduce(
            (maxLWT: number, qs: IQueueStatistics) => {
                return Math.max(maxLWT, qs.longestWaitingTimeInSeconds.value);
            },
            0,
        );

        const avgWaitingTimeInSeconds = queueStatistics.reduce(
            (maxAWT: number, qs: IQueueStatistics) => {
                return Math.max(maxAWT, qs.avgWaitingTimeInSeconds.value);
            },
            0,
        );

        return {
            answeredWithinSLAPercentageMetricByQueue:
                answeredWithinSLAPercentageMetricByQueue,
            answeredWithinSLAPercentage: {
                value: answeredWithinSLAPercentage,
                metric: {
                    percent: answeredWithinSLAPercentage,
                    states: this.kpiTelXLThreshold.slaThreshold.enabled
                        ? [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.low,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.high,
                                  ),
                                  state: 'success',
                              },
                          ]
                        : [],
                },
            },
            waiting: {
                value: waiting,
                metric: this.kpiTelXLThreshold.maxConversationsWaiting.enabled
                    ? {
                          percent: toPercentage(
                              waiting /
                                  this.kpiTelXLThreshold.maxConversationsWaiting
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxConversationsWaiting.low /
                                          this.kpiTelXLThreshold
                                              .maxConversationsWaiting.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            connected: connected,
            success: success,
            complete: complete,
            currentLongestWaitingTimeStamp: currentLongestWaitingTimeStamp,
            longestWaitingTimeInSeconds: {
                value: longestWaitingTimeInSeconds,
                metric: this.kpiTelXLThreshold.maxLongestWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              longestWaitingTimeInSeconds /
                                  this.kpiTelXLThreshold
                                      .maxLongestWaitTimeSeconds.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            avgWaitingTimeInSeconds: {
                value: avgWaitingTimeInSeconds,
                metric: this.kpiTelXLThreshold.averageWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              (avgWaitingTimeInSeconds ?? 0) /
                                  this.kpiTelXLThreshold.averageWaitTimeSeconds
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .averageWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .averageWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            timedOut: timedOut,
            abandoned: abandoned,
            outOfSla: outOfSla,
        };
    }
}

export const businessUnitStatisticDefault = (): IBusinessUnitStatistics => {
    return {
        answeredWithinSLAPercentageMetricByQueue: [],
        answeredWithinSLAPercentage: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        waiting: { value: 0, metric: { percent: 0, states: [] } },
        connected: 0,
        success: 0,
        complete: 0,
        timedOut: 0,
        abandoned: 0,
        outOfSla: 0,
        longestWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        avgWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
    };
};
