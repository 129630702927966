import {
    Component,
    computed,
    effect,
    ElementRef,
    OnDestroy,
    OnInit,
    Signal,
    signal,
    ViewChild,
} from '@angular/core';
import {
    AgentState,
    AgentStates,
    IAgentClientState,
    INotificationModel,
    IUser,
    IVoiceLogin,
    IVoiceServers,
    IVoiceStunServers,
} from '@dxp/shared/models';
import {
    AgentService,
    PresenceService,
    SettingsService,
    SipService,
    StateService,
    UserService,
} from '@dxp/shared/services';
import { Observable, Subscription, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    TelXLLoadingSpinnerComponent,
    TelXLLoadingSpinnerService,
} from '@dxp/components';
import { TelXLAttachmentComponent } from '@dxp/elements';
import { AuthService } from '@dxp/shared/api';
import { NotificationHubService } from '@dxp/shared/signalr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NotificationModalComponent } from './notifications/notification-modal/notification-modal.component';
import { NotificationToastComponent } from './notifications/notification-toast/notification-toast.component';
import { SideNavComponent } from './side-nav/container/side-nav.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        MainLayoutComponent,
        NotificationToastComponent,
        SideNavComponent,
        TelXLLoadingSpinnerComponent,
        TelXLAttachmentComponent,
    ],
    providers: [TelXLLoadingSpinnerService],
    selector: 'app-shell-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
    private _notificationSubscription!: Subscription;

    @ViewChild('localAudio') localAudio!: ElementRef<HTMLAudioElement>;
    @ViewChild('remoteAudio') remoteAudio!: ElementRef<HTMLAudioElement>;

    agentState = signal<AgentState>(AgentStates[0]);
    agentStateProperties!: Signal<IAgentClientState | undefined>;
    asteriskLogin = computed<IVoiceLogin | undefined>(() =>
        this.settingsService.asteriskLogin(),
    );
    asteriskRegister = false;
    asteriskServers = computed<IVoiceServers | undefined>(() =>
        this.settingsService.asteriskServers(),
    );
    asteriskStunServers = computed<IVoiceStunServers | undefined>(() =>
        this.settingsService.asteriskStunServers(),
    );
    isAuthenticated!: Signal<boolean>;
    isCollapsed = false;
    isNotReady = signal<boolean>(true);
    loading = true;
    notification: Observable<INotificationModel | null>;
    showModal = false;
    subscriptions: Subscription[] = [];

    constructor(
        private agentService: AgentService,
        private authService: AuthService,
        private loadingSpinnerService: TelXLLoadingSpinnerService,
        private modalService: NgbModal,
        private notificationHubService: NotificationHubService,
        private presenceService: PresenceService,
        private settingsService: SettingsService,
        private sipService: SipService,
        private stateService: StateService,
        private userService: UserService,
    ) {
        this.notification = this.notificationHubService.notificationAlert;
        effect(() => {
            if (this.localAudio && this.sipService.localStream()) {
                this.localAudio.nativeElement.srcObject =
                    this.sipService.localStream();
            }

            if (
                this.asteriskServers() &&
                this.asteriskStunServers() &&
                this.asteriskLogin() &&
                !this.asteriskRegister
            ) {
                this.asteriskRegister = true;
                if (this.userService.hasPermission('voice.read')) {
                    this.registerAsterisk();
                }
            }
        });

        // This code is ready for the splash screen
        // const agentNotReady$ = this.agentService.agentNotReady$.pipe(
        //     tap(isNotReady => {
        //         const x = isNotReady === 'true' ? true : false;
        //         this.isNotReady.set(x);
        //         localStorage.setItem('agentIsNotReady', JSON.stringify(x));
        //     }),
        // );

        // agentNotReady should be called around the below code
        this.agentStateProperties = toSignal(
            this.agentService.agentState$.pipe(
                tap(x => {
                    this.presenceService.setPresence(x.agentProperties);

                    if (x.agentProperties.state === 'Break') {
                        this.agentState.set('Break');
                    } else {
                        this.agentState.set('Idle');
                    }

                    if (x) {
                        this.loadingSpinnerService.hide();
                        this.loading = false;
                    }
                }),
            ),
        );

        this.isAuthenticated = toSignal(
            this.authService.initializeAuthentication().pipe(),
            { initialValue: false },
        );
    }

    get user(): IUser {
        return this.userService.user();
    }

    // This code is ready for the splash screen
    agentReady(): void {
        this.agentService.setAgentNotReady(false);
    }

    endBreak(): void {
        const sub = this.stateService.onActivateNew().subscribe();
        this.subscriptions.push(sub);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());

        if (this._notificationSubscription) {
            this._notificationSubscription.unsubscribe();
        }
    }

    async ngOnInit(): Promise<void> {
        const agentIsNotReady = localStorage.getItem('agentIsNotReady');

        if (agentIsNotReady === null) {
            localStorage.setItem('agentIsNotReady', JSON.stringify(true));
        }

        if (agentIsNotReady === 'false') {
            this.agentService.setAgentNotReady(false);
        }

        this.loadingSpinnerService.show();

        this._notificationSubscription =
            this.notificationHubService.notificationAlert.subscribe(
                notification => {
                    if (notification) {
                        const modalRef = this.modalService.open(
                            NotificationModalComponent,
                            {
                                centered: true,
                                keyboard: false,
                                backdrop: 'static',
                                windowClass: 'modal-wrap',
                            },
                        );

                        modalRef.componentInstance.notification = notification;
                        modalRef.componentInstance.show();
                    } else {
                        this.modalService.dismissAll();
                    }
                },
            );
    }

    registerAsterisk() {
        const servers = (this.asteriskServers()!.data || []).map(item => ({
            uri: `${item.server}.call-view.com`,
        }));

        const stunServers = (this.asteriskStunServers()!.data || []).map(
            item => item.dataValue,
        );

        const login = this.asteriskLogin()!.data?.[0];

        if (login) {
            this.sipService.initializeSipClients(
                servers,
                stunServers,
                `webrtc_${login.username}`,
                login.secret,
            );
        }
    }
}
