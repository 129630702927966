import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Duration, intervalToDuration } from 'date-fns';
import { Subscription, interval } from 'rxjs';
import { AgentApiService } from '../../api/agent/agent.api.service';
import { TaskProgressComponent } from '../../components/task-progress/task-progress.component';
import { ICompleteWorkItemParams } from '../../models/agent/agent-state';
import { IWrap } from '../../models/wrap/wrap';
import { FormatDurationPipe } from '../../pipes/format-duration/format-duration.pipe';
import { SettingsService } from '../../services/settings/settings.service';

@Component({
    selector: 'wrap',
    standalone: true,
    templateUrl: './wrap.component.html',
    styleUrl: './wrap.component.scss',
    imports: [
        CommonModule,
        FormatDurationPipe,
        TaskProgressComponent,
        FormsModule,
    ],
})
export class WrapComponent implements OnInit, OnDestroy {
    @Input() workItemId = '';
    @Input() completionCodes!: string[];
    @Input() navigateOnClose = '';

    wrapSettings!: IWrap;
    selectedClosure = '';
    notes = '';
    wrapText = '';
    now = new Date();
    wrapStart: Date = new Date();
    subscription!: Subscription;
    wrapRestart!: Date;
    wrapRestarts = 0;
    wrapTimedOut = false;

    constructor(
        settingsService: SettingsService,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef,
        private agentApiService: AgentApiService,
        private router: Router,
    ) {
        this.wrapSettings = settingsService.getWrapDetails();
    }
    activeModal = inject(NgbActiveModal);

    ngOnInit() {
        // eslint-disable-next-line no-constant-condition
        this.wrapRestarts = this.wrapSettings.maxNumberOfWrapupResets = 0
            ? 999
            : this.wrapSettings.maxNumberOfWrapupResets;
        this.wrapText = this.wrapSettings.enabled
            ? 'Wrap time countdown'
            : 'Time spent in wrap:';
        if (!this.wrapSettings.enabled) {
            this.startWrap();
        }
    }

    startWrap(): void {
        this.ngZone.runOutsideAngular(() => {
            this.subscription = interval(1000).subscribe(() => {
                this.now = new Date();
                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
    }

    ngOnDestroy(): void {
        if (!this.wrapSettings.enabled) {
            this.subscription.unsubscribe();
        }
    }

    get wrapSpent(): Duration {
        return intervalToDuration({
            start: this.wrapStart,
            end: this.now,
        });
    }

    extendWrap(): void {
        this.agentApiService.resetWrapup(this.workItemId);
        this.wrapRestart = new Date();
        this.wrapRestarts--;
    }

    endTask(): void {
        const params: ICompleteWorkItemParams = {
            notes: this.notes ? this.notes : '<Default>',
            workItemId: this.workItemId,
            closureCode: this.selectedClosure,
        };
        this.agentApiService.completeWorkItem(params);
        this.cdr.detectChanges();

        if (this.navigateOnClose) {
            this.router.navigate([this.navigateOnClose]);
        }
        this.activeModal.close();
    }
}
