import { Injectable, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AggregationLevel } from '../../models/data/aggregate-level';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import { QueueService } from '../../services/queue/queue.service';
import { UserService } from '../../services/user/user.service';

export interface Segment {
    id: string;
    label: string;
    url: string;
    level: AggregationLevel;
}

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    private _segmentsSubject = new BehaviorSubject<Segment[]>([]);
    segments = toSignal(this._segmentsSubject.asObservable());
    current = computed<Segment | undefined>(() => {
        const segments = this.segments();
        return segments && segments.length > 0
            ? segments[segments.length - 1]
            : undefined;
    });

    constructor(
        private router: Router,
        private userService: UserService,
        private businessUnitService: BusinessUnitService,
        private queueService: QueueService,
    ) {
        this.updateSegments();

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.updateSegments();
            });
    }

    private updateSegments() {
        const root = this.router.routerState.snapshot.root;
        const segments: Segment[] = [];
        this.addSegment(root, '', segments, AggregationLevel.None);
        this._segmentsSubject.next(segments);
    }

    private addSegment(
        route: ActivatedRouteSnapshot,
        url: string,
        segments: Segment[],
        level: AggregationLevel,
    ) {
        if (route) {
            route.url.forEach(segment => {
                if (segment.path) {
                    level++;
                    url += `/${segment.path}`;
                    segments.push({
                        id: segment.path,
                        label: this.getBreadcrumbLabel(level, segment.path),
                        url,
                        level,
                    });
                }
            });

            if (route.children.length > 0) {
                route.children.forEach(child => {
                    this.addSegment(child, url, segments, level);
                });
            }
        }
    }

    private getBreadcrumbLabel(level: AggregationLevel, id: string): string {
        switch (level) {
            case AggregationLevel.Tenant:
                return this.userService.user().tenant?.name ?? '';

            case AggregationLevel.BusinessUnit:
                return (
                    this.businessUnitService
                        .businessUnits()
                        ?.find(bu => bu.id === id)?.name ?? ''
                );

            case AggregationLevel.Queue:
                return (
                    this.queueService.queues().find(q => q.id === id)?.name ??
                    ''
                );

            default:
                return '';
        }
    }
}
