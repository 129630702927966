<div class="col-12 p-0 text-left">
    <button
        type="button"
        class="btn btn-voice-call w-100 p-3 mt-3 text-start"
        [class.clicked]="isClicked"
        (click)="onButtonClick()"
    >
        <i
            class="fa=sharp fa-xl fa-light ms-2"
            [ngClass]="isClicked ? iconSelected : icon"
        ></i>
        <span class="ms-2 font-lg">
            {{ isClicked ? titleSelected : title }}
        </span>
    </button>
</div>
