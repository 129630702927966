import {
    Component,
    computed,
    Signal,
    OnDestroy,
    effect,
    OnInit,
    NgZone,
    ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ICallState, IWorkItem } from '@dxp/shared/models';
import { IConversation } from '@dxp/shared/models';
import { SipService } from '@dxp/shared/services';
import { VoiceApiService } from '@dxp/shared/api';
import { ICustomerDetails } from '@dxp/shared/models';
import { CustomerApiService } from '@dxp/shared/api';
import { Duration, intervalToDuration } from 'date-fns';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { Subscription, interval, map } from 'rxjs';
import { AgentService } from '@dxp/shared/services';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { VoiceCallInfoComponent } from '../voice-call-info/voice-call-info.component';
import { VoiceCallButtonComponent } from '../voice-call-buttons/voice-call-button.component';
import { CacheService } from '@dxp/shared/services';

@Component({
    selector: 'voice-call',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        VoiceCallButtonComponent,
        FormatDurationPipe,
        VoiceCallInfoComponent,
    ],
    templateUrl: './voice-call.component.html',
    styleUrl: './voice-call.component.scss',
})
export class VoiceCallComponent implements OnInit, OnDestroy {
    taskClosing = false;
    workItem!: Signal<IWorkItem>;
    initialWrapLength = 0;
    callEnded = false;

    workItemId: string | null = null;
    intervalSubscription: Subscription | undefined;
    now: Date = new Date();

    customerName = 'Unknown customer';
    callAccepted: Date | undefined;
    muted = false;
    hold = false;

    customer = computed<ICustomerDetails | null>(() =>
        this.customerApiService.customer(),
    );

    conversation: IConversation | undefined;

    constructor(
        private sipService: SipService,

        private voiceApiService: VoiceApiService,
        private customerApiService: CustomerApiService,
        private agentService: AgentService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private cacheService: CacheService,
    ) {
        this.workItemId = this.route.snapshot.paramMap.get('workItemId');

        const workItem$ = this.agentService.agentWorkItems$.pipe(
            map(
                wi =>
                    wi.find(w => w.workItemId === this.workItemId) as IWorkItem,
            ),
        );
        this.workItem = toSignal(workItem$, { initialValue: {} as IWorkItem });

        effect(() => {
            const customer = this.customer();
            if (customer) {
                this.customerName = customer.name;
                this.cdr.detectChanges();
            }
        });

        effect(() => {
            const workItem = this.workItem();
            if (workItem) {
                if (workItem.customerId) {
                    this.customerApiService.getCustomer(workItem.customerId);
                }
            }
        });
    }

    ngOnInit() {
        const currentWorkItem = this.workItem();
        if (currentWorkItem) {
            if (!this.conversation) {
                this.conversation = this.workItem().conversations.find(
                    c =>
                        c.conversationId ===
                        this.workItem().primaryConversationId,
                ) as IConversation;
            }

            if (this.cacheService.getFromCache('voice-mute')) {
                this.muted = true;
            }
            if (this.cacheService.getFromCache('voice-hold')) {
                this.hold = true;
            }
        }

        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(1000).subscribe(() => {
                this.now = new Date();

                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            });
        });
    }

    ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    endCall(): void {
        if (this.workItem()) {
            this.intervalSubscription?.unsubscribe();
            const params: ICallState = {
                conversationId: this.workItem().primaryConversationId as string,
                callState: 'Hangup',
            };
            this.voiceApiService.changeCallState(params);
        }
        this.cacheService.removeCache('voice-mute');
        this.sipService.hangUp();
    }

    get callTime(): Duration {
        if (this.workItem()) {
            return intervalToDuration({
                start: this.workItem().lastStateChangeDate,
                end: this.now,
            });
        }

        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }

    btnClicked(title: string): void {
        switch (title) {
            case 'Mute Call':
                this.sipService.mute();
                this.cacheService.saveToCache('voice-mute', true);
                break;

            case 'Unmute Call':
                this.sipService.unmute();
                this.cacheService.removeCache('voice-mute');
                break;

            case 'Hold': {
                const params: ICallState = {
                    conversationId: this.workItem()
                        .primaryConversationId as string,
                    callState: 'Hold',
                };
                this.voiceApiService.changeCallState(params);
                this.cacheService.saveToCache('voice-hold', true);
                break;
            }

            case 'Resume': {
                const params: ICallState = {
                    conversationId: this.workItem()
                        .primaryConversationId as string,
                    callState: 'Resume',
                };
                this.voiceApiService.changeCallState(params);
                this.cacheService.removeCache('voice-hold');
                break;
            }
        }
    }
}
