import { ChannelProviderType } from '@services/channel-provider/channel-provider.service';
import { IValueWithMetric } from '@models/metrics/metric';
import {
    ITelXLKpiQueueThreshold,
    TelXLKpiQueueThresholdDefaultState,
} from '@models/rbac/queue-kpi';
import { toPercentage } from '@helpers/metric';
import { IQueueActivity, QueueActivity } from './queue-activity';
import { ChannelType } from '@models/agent/agent-state';

export const QueueMediaTypes = [
    'Email',
    'Voice',
    'Webchat',
    'Socials',
    'Mixed',
    'Unknown',
] as const;
export type QueueMediaType = (typeof QueueMediaTypes)[number];

export const ChannelGroupTypes = [
    'email',
    'voice',
    'webchat',
    'socials',
    '',
] as const;
export type ChannelGroupType = (typeof ChannelGroupTypes)[number];

export interface IQueue {
    id: string;
    name?: string;
    businessUnit?: string;
    hasActivity: boolean;
    report?: IQueueWithChannelsReport;
    information?: IQueueInformation;
    kpiTelXLThreshold: ITelXLKpiQueueThreshold;
    channelProviders: ChannelProviderType[];
    mediaType: QueueMediaType;
    channelGroups: ChannelGroupType[];

    visibleByChannelFilter(filter: ChannelGroupType | undefined): boolean;
    activity(index?: string): IQueueActivity[];
    statistics(index?: string): IQueueStatistics;
}

export interface IQueueInformation {
    id: string;
    name: string;
    workflowId: string;
    mediaGroup: string;
    queueSegments: IQueueSegment[];
    agentSegments: IAgentSegment[];
    todaysQueueSegments: IQueueSegment[];
    todaysAgentSegments: IAgentSegment[];
    segments: IQueueSegment[];
    businessUnitId: string;
    staffedLoggedInAgentCount: number;
    staffedAvailableAgentCount: number;
}

export interface IQueueSegment {
    queueId: string;
    queuedAt: Date;
    deQueuedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    position?: number;
    customer: ICustomer;
    channelId: number;
    channelType: ChannelType;
    channelGroup: ChannelGroupType;
    routingMode: 'Manual' | 'Automatic';
    conversationId: string;
    businessUnitId: string;
}

export interface IAgentSegment {
    customer: ICustomer;
    createdAt: Date;
    closedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    queueId: string;
    conversationId: string;
    agents: IAgent[];
}

interface ICustomer {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
}

interface IAgent {
    id: string;
    name: string;
}

export interface IQueueReport {
    id: string;
    name: string;
    channelId: string;
    channelType: ChannelType;
    channelGroup: ChannelGroupType;
    sla: number;
    abandonThreshold: number;
    startOfToday: Date;
    answeredWithinSLARatio: number;
    handledCount: number;
    handledWithinSLACount: number;
    abandonedCount: number;
    abandonedWithinSLACount: number;
    callbackRequestedCount: number;
    callbackAcceptedCount: number;
    longestWaitTimeInSeconds: number;
    avgWaitingTimeInSeconds: number;
    queueTimedOutCount: number;
    conversationCountForAvgWaitingTime: number;
    businessUnitId: string;
}

export interface IQueueWithChannelsReport extends IQueueReport {
    queueChannelReports: IQueueReport[];
}

export interface IQueueStatistics {
    answeredWithinSLAPercentage: IValueWithMetric;
    abandoned: number;
    timedOut: number;
    waiting: IValueWithMetric;
    connected: number;
    success: number;
    complete: number;
    avgWaitingTimeInSeconds: IValueWithMetric;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: IValueWithMetric;
    outOfSla: number;
}

export class Queue implements IQueue {
    id: string;
    private _information?: IQueueInformation;
    private _report?: IQueueWithChannelsReport;
    private _activity: QueueActivity[];
    private _kpiTelXLThreshold: ITelXLKpiQueueThreshold;
    private _channelProviders?: ChannelProviderType[];

    constructor(
        id: string,
        channelProviders?: ChannelProviderType[],
        information?: IQueueInformation,
        report?: IQueueWithChannelsReport,
        kpiTelXLThreshold?: ITelXLKpiQueueThreshold,
    ) {
        this.id = id;
        this._channelProviders = channelProviders;
        this._information = information;
        this._report = report;
        this._kpiTelXLThreshold =
            kpiTelXLThreshold ?? TelXLKpiQueueThresholdDefaultState;

        const agentSegments = [
            ...(this._information?.agentSegments ?? []),
            ...(this._information?.todaysAgentSegments ?? []),
        ];
        const queueSegments = [
            ...(this._information?.queueSegments ?? []),
            ...(this._information?.todaysQueueSegments ?? []),
        ];

        this._activity = queueSegments.map(
            q =>
                new QueueActivity(
                    this._report !== undefined &&
                        new Date(q.queuedAt).getTime() >=
                            new Date(this._report.startOfToday).getTime(),
                    q,
                    agentSegments.find(
                        a => a.conversationId === q.conversationId,
                    ),
                ),
        );
    }

    get name(): string | undefined {
        return this._information?.name;
    }

    get businessUnit(): string | undefined {
        return this._information?.businessUnitId;
    }

    get hasActivity(): boolean {
        try {
            return (
                this._report !== undefined && this._information !== undefined
            );
        } catch {
            return false;
        }
    }

    get report(): IQueueWithChannelsReport | undefined {
        return this._report;
    }

    get information(): IQueueInformation | undefined {
        return this._information;
    }

    get kpiTelXLThreshold(): ITelXLKpiQueueThreshold {
        return this._kpiTelXLThreshold;
    }

    get channelProviders(): ChannelProviderType[] {
        return this._channelProviders ?? [];
    }

    get mediaType(): QueueMediaType {
        switch (this._information?.mediaGroup) {
            case 'voice':
                return 'Voice';
            case 'email':
                return 'Email';
            case 'chat': {
                if (this.channelProviders.length === 0) return 'Unknown';

                if (
                    this.channelProviders.includes('Webchat') &&
                    this.channelProviders.length === 1
                )
                    return 'Webchat';
                if (
                    this.channelProviders.includes('Webchat') &&
                    this.channelProviders.length > 1
                )
                    return 'Mixed';
                return 'Socials';
            }
            default:
                return 'Unknown';
        }
    }

    get channelGroups(): ChannelGroupType[] {
        switch (this.mediaType) {
            case 'Voice':
                return ['voice'];
            case 'Email':
                return ['email'];
            case 'Webchat':
                return ['webchat'];
            case 'Socials':
                return ['socials'];
            case 'Mixed':
                return ['webchat', 'socials'];
            default:
                return ['email', 'voice', 'webchat', 'socials'];
        }
    }

    visibleByChannelFilter(filter: ChannelGroupType | undefined): boolean {
        if (!filter) return true;

        return this.channelGroups.includes(filter);
    }

    activity(index?: string): IQueueActivity[] {
        if (index === undefined) {
            return this._activity;
        }

        return this._activity.filter(a => a.channelGroup === index);
    }

    statistics(index?: ChannelGroupType): IQueueStatistics {
        if (!this._report || !this._information) return queueStatisticDefault();

        if (index === undefined) {
            return this.calculatePerformance(
                this._report,
                this._information.queueSegments,
                this._information.agentSegments,
            );
        }

        var report = this._report.queueChannelReports.find(
            qcr => qcr.channelGroup === index,
        );

        if (report) {
            const conversationsMap = new Set(
                this._information.queueSegments
                    .filter(q => q.channelGroup === index)
                    .map(q => q.conversationId),
            );

            return this.calculatePerformance(
                report,
                this._information.queueSegments.filter(
                    qs => qs.channelGroup === index,
                ),
                this._information.agentSegments.filter(as =>
                    conversationsMap.has(as.conversationId),
                ),
            );
        }

        return queueStatisticDefault();
    }

    private calculatePerformance(
        report: IQueueReport,
        queueSegments: IQueueSegment[],
        agentSegments: IAgentSegment[],
    ): IQueueStatistics {
        const minimumQueuedAt =
            queueSegments && queueSegments.length > 0
                ? queueSegments.reduce((min, current) => {
                      return new Date(current.queuedAt).getTime() <
                          min.getTime()
                          ? new Date(current.queuedAt)
                          : min;
                  }, new Date())
                : new Date();

        return {
            answeredWithinSLAPercentage: {
                value: toPercentage(Math.max(report.answeredWithinSLARatio, 0)),
                metric: {
                    percent: toPercentage(
                        Math.max(report.answeredWithinSLARatio, 0),
                    ),
                    states: this._kpiTelXLThreshold.slaThreshold.enabled
                        ? [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold.slaThreshold.low,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold.slaThreshold.high,
                                  ),
                                  state: 'success',
                              },
                          ]
                        : [],
                },
            },
            abandoned: report.abandonedCount ?? 0,
            timedOut: report.queueTimedOutCount ?? 0,
            waiting: {
                value: queueSegments.length ?? 0,
                metric: this._kpiTelXLThreshold.maxConversationsWaiting.enabled
                    ? {
                          percent: toPercentage(
                              (queueSegments.length ?? 0) /
                                  this._kpiTelXLThreshold
                                      .maxConversationsWaiting.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold
                                          .maxConversationsWaiting.low /
                                          this._kpiTelXLThreshold
                                              .maxConversationsWaiting.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            connected: agentSegments.length ?? 0,
            success: Math.max(report.handledWithinSLACount ?? 0, 0),
            complete: report.handledCount ?? 0,
            avgWaitingTimeInSeconds: {
                value: report.avgWaitingTimeInSeconds ?? 0,
                metric: this._kpiTelXLThreshold.averageWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              (report.avgWaitingTimeInSeconds ?? 0) /
                                  this._kpiTelXLThreshold.averageWaitTimeSeconds
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold
                                          .averageWaitTimeSeconds.low /
                                          this._kpiTelXLThreshold
                                              .averageWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            currentLongestWaitingTimeStamp:
                queueSegments && queueSegments?.length > 0
                    ? minimumQueuedAt
                    : undefined,
            longestWaitingTimeInSeconds: {
                value: report.longestWaitTimeInSeconds ?? 0,
                metric: this._kpiTelXLThreshold.maxLongestWaitTimeSeconds
                    .enabled
                    ? {
                          percent: toPercentage(
                              (report.longestWaitTimeInSeconds ?? 0) /
                                  this._kpiTelXLThreshold
                                      .maxLongestWaitTimeSeconds.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          this._kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            outOfSla:
                report.sla > 0
                    ? (report.handledCount ?? 0) -
                      (Math.max(report.handledWithinSLACount ?? 0, 0) ?? 0)
                    : 0,
        };
    }
}

export const queueStatisticDefault = (): IQueueStatistics => {
    return {
        answeredWithinSLAPercentage: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        waiting: { value: 0, metric: { percent: 0, states: [] } },
        connected: 0,
        success: 0,
        complete: 0,
        timedOut: 0,
        abandoned: 0,
        outOfSla: 0,
        longestWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        avgWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
    };
};
