import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { AgentState, IAgentProperties, IPresence } from './../../models';

@Injectable({
    providedIn: 'root',
})
export class PresenceService {
    private _presence = new BehaviorSubject<IPresence>({} as IPresence);

    presence = signal<IPresence>({} as IPresence);
    presence$ = this._presence.asObservable().pipe(
        tap(presence => {
            this.presence.set(presence);
        }),
    );

    setPresence(agentProperties: IAgentProperties) {
        this._presence.next(
            this.calculateUserPresence(
                agentProperties.state,
                agentProperties.stateSince,
                agentProperties.breakName,
                agentProperties.timedBreakExpiresAt,
            ),
        );
    }
    stateToClass = (state: AgentState): string => {
        switch (state) {
            case 'Break':
                return 'warning';
            case 'Idle':
                return 'success';
            case 'Busy':
                return 'danger';
            case 'LoggedOut':
            case 'PendingLogout':
            case 'PendingBreak':
                return 'secondary';
        }
    };

    stateToLabel = (state: AgentState, breakName?: string): string => {
        switch (state) {
            case 'LoggedOut':
                return 'Logged out';
            case 'Idle':
                return 'Available';
            case 'Break':
                return breakName || 'Break';
            default:
                return state;
        }
    };

    stateToOnline = (state: AgentState): boolean => {
        return state === 'Idle';
    };

    private calculateUserPresence = (
        state: AgentState,
        stateSince: Date,
        breakName?: string,
        timedBreakExpiresAt?: Date,
    ): IPresence => {
        return {
            state: state,
            stateSince: stateSince,
            label: this.stateToLabel(state, breakName),
            class: this.stateToClass(state),
            online: this.stateToOnline(state),
            timedBreakExpiresAt: timedBreakExpiresAt,
        };
    };
}
