import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share, tap } from 'rxjs';
import { environment } from '@core/environments/environment';
import { LoggerService } from '@services/logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class DirectoryApiService {
    constructor(private logger: LoggerService, private http: HttpClient) {}

    getPhoto() {
        return this.http
            .get(`${environment.directoryUrl}/me/photo`, {
                responseType: 'blob',
            })
            .pipe(
                tap({
                    next: photo => {
                        this.logger.debug(
                            'Directory Service (Get Photo) -> Successful',
                            photo
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Directory Service (Get Photo) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }
}
