import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'telxl-container-overlay',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './container-overlay.component.html',
    styleUrl: './container-overlay.component.scss',
})
export class TelXLContainerOverlayComponent {
    @Input() topOpacity = '0.7';
    @Input() overlayBackground = 'rgba(0, 0, 0, 0.5)';
    @Input({ required: true }) isOverlayVisible = false;
}
