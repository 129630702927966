<div
    style="display: flex; justify-content: space-evenly; align-items: center"
    [style.height]="height"
>
    <canvas
        baseChart
        [data]="doughnutChartData"
        [options]="doughnutChartOptions"
        [type]="doughnutChartType"
        [plugins]="doughnutChartPlugins"
    ></canvas>
</div>
