import { Injectable, signal, computed } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
    private targetRoute = signal<{ path: string, params?: any } | null>(null);

    navigateSignal = computed(() => this.targetRoute());

    triggerNavigation(route: string, params?: any) {
        this.targetRoute.set({ path: route, params });
    }

    resetNavigation() {
        this.targetRoute.set(null);
    }
}
