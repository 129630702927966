import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { recordToArray } from '../../helpers/team-agents';
import { DoughnutComponent } from '../metrics/doughnut/doughnut.component';
import { PaginationComponent } from '../pagination/pagination.component';

@Component({
    selector: 'team-disposition-codes-panel',
    standalone: true,
    imports: [CommonModule, DoughnutComponent, PaginationComponent],
    templateUrl: './disposition-codes-panel.component.html',
    styleUrl: './disposition-codes-panel.component.scss',
})
export class DispositionCodesComponent {
    itemsPerPage = 5;
    currentPage = 1;
    selectedDispositionCodeIndex = 0;
    recordToArray = recordToArray;

    @Input({ required: true }) dispositioncodes!: Record<
        string,
        { value: number; percentage: number }
    >;

    selectDispositionCode(dispositionCodeIndex: number) {
        this.selectedDispositionCodeIndex = dispositionCodeIndex;
    }

    changePage(newPage: number) {
        this.currentPage = newPage;
        this.selectedDispositionCodeIndex =
            (this.currentPage - 1) * this.itemsPerPage;
    }

    showDispositionCode(index: number): boolean {
        const low = (this.currentPage - 1) * this.itemsPerPage;
        const high = low + this.itemsPerPage - 1;

        return index >= low && index <= high;
    }

    get dispositionCodesArray(): {
        name: string;
        value: number;
        percentage: number;
    }[] {
        return recordToArray(this.dispositioncodes).sort(
            (a, b) => b.percentage - a.percentage,
        );
    }

    get percentage(): number {
        if (this.dispositionCodesArray.length === 0) {
            return 0;
        }

        return (
            this.dispositionCodesArray[this.selectedDispositionCodeIndex]
                ?.percentage ?? 0
        );
    }

    get totalPages() {
        const pages = Math.ceil(
            this.dispositionCodesArray.length / this.itemsPerPage,
        );
        return pages > 0 ? pages : 1;
    }
}
