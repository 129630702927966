import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICallState, IIncomingTask } from '@dxp/shared/models';
import { SettingsService, SipService } from '@dxp/shared/services';

import { ChatHubService } from '@dxp/shared/signalr';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { TaskProgressComponent } from '@dxp/shared/components';
import { VoiceApiService } from '@dxp/shared/api';

@Component({
    selector: 'app-shell-task-belt-item',
    standalone: true,
    imports: [CommonModule, TaskProgressComponent],
    templateUrl: './task-belt-item.component.html',
    styleUrl: './task-belt-item.component.scss',
})
export class TaskBeltItemComponent implements OnInit {
    @Input() createdAtActual = new Date();
    @Input() isSelected = false;
    @Input() timeout = 0;
    @Input() task!: IIncomingTask;
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    stopProgress = false;
    displayTaskButtons = false;
    displayProgress = true;

    constructor(
        private sipService: SipService,
        private router: Router,
        private settingsService: SettingsService,
        private voiceApiService: VoiceApiService,
        private chatHubService: ChatHubService,
    ) {}

    ngOnInit() {
        this.displayTaskButtons =
            this.task.workItemState === 'Preview' && this.timeout > 0;
    }

    onClick() {
        this.setTask();
        this.clicked.emit();
        this.stopProgress = true;
        this.isSelected = true;
        this.router.navigate([this.task.navigation]);
    }

    onAcceptClick(): void {
        if (this.task.mediaType === 'Voice') {
            this.sipService.answerCall();
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Answer',
            };
            this.voiceApiService.changeCallState(params);
        }
        this.chatHubService.accept({
            conversationId: this.task.conversationId,
        });

        this.setTask();
        this.isSelected = true;
        this.displayTaskButtons = false;
        this.router.navigate([this.task.navigation]);
        this.timeout = 0;
    }

    onRejectClick(): void {
        if (this.task.mediaType === 'Voice') {
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Hangup',
            };
            this.voiceApiService.changeCallState(params);
            this.sipService.reject();
        }
        this.chatHubService.reject({
            conversationId: this.task.conversationId,
        });

        this.displayProgress = false;
        this.displayTaskButtons = false;
    }

    setTask(): void {
        if (this.task) {
            switch (this.task.mediaType) {
                case 'Voice':
                    this.settingsService.setIncomingVoiceTask(this.task);
                    break;
                case 'Webchat':
                    this.settingsService.setIncomingWebchatTask(this.task);
                    break;
            }
        }
    }
}
