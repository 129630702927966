<div
    class="d-flex flex-row justify-content-between font-small w-100 text-socials pb-2"
>
    <span>
        {{ currentPage === 1 ? 'Top' : 'Next' }} {{ itemsPerPage }} Disposition
        Codes
    </span>
    <pagination
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        (pageChanged)="changePage($event)"
    ></pagination>
</div>
<div class="d-flex">
    <div class="w-25">
        <doughnut
            [overall]="percentage.toString()"
            [metric]="{
                percent: percentage,
                states: [{ value: 0, state: 'socials' }],
            }"
            height="100px"
            size="largest"
            weight="medium"
            defaultState="socials"
            [percentage]="true"
        />
    </div>
    <div class="w-75">
        <table class="w-100">
            @for (
                dispositioncode of dispositionCodesArray;
                track dispositioncode.name;
                let idx = $index
            ) {
                @if (showDispositionCode(idx)) {
                    <tr
                        class="font-small weight-light"
                        [ngClass]="{
                            active: selectedDispositionCodeIndex === idx,
                        }"
                        (click)="selectDispositionCode(idx)"
                    >
                        <td class="w-50 ps-1 pt-1 pb-1">
                            {{ dispositioncode.name }}
                        </td>
                        <td class="w-25 text-end pt-1 pb-1">
                            {{ dispositioncode.value | number: '2.0-0' }}
                        </td>
                        <td class="w-25 text-end weight-medium pe-1 pt-1 pb-1">
                            {{ dispositioncode.percentage }}%
                        </td>
                    </tr>
                }
            }
        </table>
    </div>
</div>
