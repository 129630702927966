@if (items.length === 0) {
    <i class="fa-solid fa-box-open fa-3x text-secondary mb-3"></i>
    <div>No data</div>
} @else {
    <div>
        <input
            type="text"
            class="form-control"
            [(ngModel)]="searchTerm"
            placeholder="Search..."
        />
    </div>

    <ul
    class="list-group mt-3"
    [ngStyle]="{ 'max-height': maxHeight, overflow: 'auto' }"
>
    <li *ngIf="filteredItems.length === 0" class="list-group-item">
        <div class="d-flex flex-column align-items-center">
            <i class="fa-solid fa-box-open fa-3x text-secondary mb-3"></i>
            <div>No data</div>
        </div>
    </li>
    <li
        *ngFor="let item of filteredItems"
        class="list-group-item"
        [ngStyle]="{ height: listItemHeight + 'px' }"
    >
        <div class="font-lg weight-medium">{{ item.title }}</div>

        <div class="d-flex align-items-center">
            <span class="flex-grow-1">{{ item.item | truncate: truncateLength }}</span>

            <ng-container
                *ngTemplateOutlet="buttonTemplate;
                    context: { $implicit: item, clickHandler: onButtonClick.bind(this) }"
            ></ng-container>
        </div>
    </li>
</ul>

}
