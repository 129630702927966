<div class="card px-0 ms-2 task-item"
    [ngClass]="isSelected ? selectedClass : ''"
    [ngClass]="{ 'task-voice': mediaType === 'Voice' }"
    [attr.clickable]="mediaType !== 'Voice' ? 'true' : null"
    (click)="mediaType !== 'Voice' ? onClick() : null">
    <div class="card-body py-0 px-0">
        <div class="d-flex">
            <div class="ms-2 me-2">
                <i [ngClass]="isSelected ? iconSelected : icon"></i>
            </div>
            <div class="ms-1">
                <div class="task-name">
                    {{taskName}}
                </div>
                <div class="task-description">
                    {{description}}
                </div>
                <div class="task-time mt-1">
                    {{createdAt}}
                </div>
            </div>  
            <div class="flex-grow-1 text-end call-buttons-container" [class.hidden]="!displayCallButtons">
                <div class="me-2 mt-1">
                    <button class="btn btn-xsm btn-voice-accept font-small" (click)="onAcceptClick()">Accept</button>
                </div>
                <div class="me-2 mt-1">
                    <button class="btn btn-xsm btn-voice-reject font-small" (click)="onRejectClick()">Reject</button>
                </div>
            </div>            
        </div>  
    </div>    
</div>

