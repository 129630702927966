export { TelXLBreakReasonComponent } from './break-reason-panel/break-reason-panel.component';
export { TelXLChannelTypeComponent } from './channel-type-panel/channel-type-panel.component';
export { TelXLDispositionCodesComponent } from './disposition-codes-panel/disposition-codes-panel.component';
export { TelXLMiniLoadingSpinnerComponent } from './loading-spinner/mini/loading-spinner.component';
export { TelXLLoadingSpinnerComponent } from './loading-spinner/main/loading-spinner.component';
export { TelXLLoadingSpinnerService } from './loading-spinner/loading-spinner.service';
export {
    KpiValueChange,
    SettingPanelType,
    TelXLSettingPanelComponent,
} from './setting-panel/setting-panel.component';
export { TelXLTaskProgressComponent } from './task-progress/task-progress.component';
export { TelXLUtilisationComponent } from './utilisation-panel/utilisation-panel.component';
export { TelXLWrapComponent } from './wrap/wrap.component';
