export interface IRbacUserTenant {
    tenantId: string;
    tenantName: string;
    userId: string;
    userName: string;
}

export interface IRbacTenant {
    id: string;
    name: string;
    address: IAddress;
    scopes: IScope[];
    concurrentUserLimit: number;
    license: string;
    environment: string;
}

interface IAddress {
    city: string;
    streetAddress: string;
    postalCode: string;
    country: string;
}

interface IScope {
    name: string;
    limit: number;
}
