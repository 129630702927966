<div class="container-fluid">
    <div class="row call-details ps-0 pt-1 pb-3 mb-5">
        <div class="col-10">
            <voice-call-info
                [conversation]="conversation"
                [customerName]="customerName"
            ></voice-call-info>
        </div>
        <div class="col-2 text-center">
            <div class="font-lg mt-2 text-secondary ms-auto">
                {{ callTime | formatDuration }}
            </div>
            <div
                class="d-flex flex-column align-items-center justify-content-center mt-3"
            >
                <button
                    type="button"
                    class="btn btn-reject rounded-circle p-3"
                    (click)="endCall()"
                >
                    <i class="fa-xl fa-solid fa-phone-hangup"></i>
                </button>
                <div class="text-secondary mt-1 font-sm text-center">
                    End Call
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0">
        <voice-call-button
            class="p-0"
            icon="fa-microphone-slash"
            iconSelected="fa-microphone"
            title="Mute Call"
            titleSelected="Unmute Call"
            [isClicked]="muted"
            (buttonClicked)="btnClicked($event)"
        ></voice-call-button>
    </div>
    <!-- <div class="row p-0">
        <voice-call-button
            class="p-0"
            icon="fa-circle-pause"
            iconSelected="fa-play"
            title="Hold"
            titleSelected="Resume"
            [isClicked]="hold"
            (buttonClicked)="btnClicked($event)"
        ></voice-call-button>
    </div> -->
</div>
