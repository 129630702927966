@if (loading$ | async) {
    <div
        class="d-flex flex-column justify-content-center align-items-center vh-100 vw-100"
    >
        <div
            class="spinner-border mb-3"
            style="width: 3rem; height: 3rem"
            role="status"
        ></div>

        <div>
            <ng-content></ng-content>
        </div>
    </div>
}
