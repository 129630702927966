export interface IMetricState {
    value: number;
    state:
        | 'success'
        | 'warning'
        | 'danger'
        | 'primary'
        | 'dark'
        | 'socials'
        | 'webchat'
        | 'email';
}

export interface IMetric {
    percent: number;
    states: IMetricState[];
}

export interface IValueWithMetric {
    value: number;
    metric: IMetric;
}

export interface IDisplayWithMetric {
    value: string;
    metric: IMetric;
}
