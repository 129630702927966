import { Component, inject, Input } from '@angular/core';
import { map, Subject, takeUntil, timer } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationHubService } from '@signalr/notification/notification.hub.service';
import {
    INotificationModel,
    NotificationActionType,
} from '@models/notification/notification';

@Component({
    selector: 'notification-modal',
    standalone: true,
    templateUrl: './notification-modal.component.html',
    styleUrl: './notification-modal.component.scss',
})
export class NotificationModalComponent {
    countdown = 0;
    enabled = true;
    private counter = new Subject<void>();

    @Input() notification!: INotificationModel;
    activeModal = inject(NgbActiveModal);

    constructor(private notificationHubService: NotificationHubService) {}

    show() {
        this.enabled = true;

        if (this.notification.expires) {
            const expires =
                (new Date(this.notification.expires).getTime() -
                    new Date().getTime()) /
                1000;

            this.startCountdown(Math.floor(expires));
        }
    }

    startCountdown(seconds: number) {
        this.countdown = seconds;
        this.counter.next();

        const countdown$ = timer(0, 1000).pipe(
            takeUntil(this.counter),
            map(elapsed => seconds - elapsed),
            takeUntil(timer(seconds * 1000))
        );

        countdown$.subscribe({
            next: value => {
                this.countdown = value;
            },
            complete: () => {
                this.endCountdown();
            },
        });
    }

    endCountdown() {
        this.enabled = false;
        this.counter.next();
    }

    dismissNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Dismissed
        );
    }

    actionNotification(notification: INotificationModel) {
        this.counter.next();

        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Actioned
        );
    }

    get hasExpiry(): boolean {
        return this.notification.expires !== null;
    }
}
