import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Duration } from 'date-fns';
import { SpinnerInputComponent } from '../../components/spinner-input/spinner-input.component';
import { durationToSeconds, secondsToDuration } from '../../helpers/time';
import { IQueueThreshold } from '../../models/rbac/queue-kpi';
import { ITeamThreshold } from '../../models/rbac/team-kpi';

export enum SettingPanelType {
    Percentage = 'Percentage',
    Time = 'Time',
    Numeric = 'Numeric',
}
export interface KpiValueChange {
    value: IQueueThreshold | ITeamThreshold;
    isValid: boolean;
}

@Component({
    selector: 'setting-panel',
    standalone: true,
    imports: [CommonModule, FormsModule, SpinnerInputComponent],
    templateUrl: './setting-panel.component.html',
    styleUrl: './setting-panel.component.scss',
})
export class SettingPanelComponent implements OnChanges {
    secondsToDuration = secondsToDuration;
    SettingPanelType = SettingPanelType;
    @Input() icon!: string;
    @Input({ required: true }) title!: string;
    @Input({ required: true }) settingPanelType!: SettingPanelType;
    @Input({ required: true }) value!: IQueueThreshold | ITeamThreshold;
    @Input() min!: number;
    @Input() max!: number;
    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
    @Input() compact = false;
    @Output() valueChange: EventEmitter<KpiValueChange> =
        new EventEmitter<KpiValueChange>();

    isEnabled = this.value?.enabled ?? false;
    _value!: IQueueThreshold | ITeamThreshold;
    _lowTime!: Duration;
    _highTime!: Duration;
    _targetTime!: Duration;

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes['value'] &&
            changes['value'].currentValue !== undefined &&
            changes['value'].currentValue !== null
        ) {
            this.value = changes['value'].currentValue as
                | IQueueThreshold
                | ITeamThreshold;
            this.isEnabled = this.value.enabled;
            this._value = { ...this.value };
            this._lowTime = secondsToDuration(this.value.low);
            this._highTime = secondsToDuration(this.value.high);

            if (this.isTeamThreshold) {
                this._targetTime = secondsToDuration(
                    (this.value as ITeamThreshold).target,
                );
            }
        }
    }

    onTargetValueChange(newValue: number | null) {
        (this._value as ITeamThreshold).target =
            this.settingPanelType === SettingPanelType.Percentage
                ? (newValue ?? 0) / 100
                : (newValue ?? 0);

        this.emitValueChange();
    }

    onLowValueChange(newValue: number | null) {
        this._value.low =
            this.settingPanelType === SettingPanelType.Percentage
                ? (newValue ?? 0) / 100
                : (newValue ?? 0);

        this.emitValueChange();
    }

    onHighValueChange(newValue: number | null) {
        this._value.high =
            this.settingPanelType === SettingPanelType.Percentage
                ? (newValue ?? 0) / 100
                : (newValue ?? 0);

        this.emitValueChange();
    }

    onEnabledValueChange(newValue: boolean | null) {
        this._value.enabled = newValue ?? false;
        this.emitValueChange();
    }

    onTargetValueTimeChange(value: {
        type: 'D' | 'H' | 'M' | 'S';
        newValue: number | null;
    }) {
        switch (value.type) {
            case 'D':
                this._targetTime.days = value.newValue ?? 0;
                break;

            case 'H':
                this._targetTime.hours = value.newValue ?? 0;
                break;

            case 'M':
                this._targetTime.minutes = value.newValue ?? 0;
                break;

            case 'S':
                this._targetTime.seconds = value.newValue ?? 0;
                break;
        }

        (this._value as ITeamThreshold).target = durationToSeconds(
            this._targetTime,
        );
        this.emitValueChange();
    }

    onLowValueTimeChange(value: {
        type: 'D' | 'H' | 'M' | 'S';
        newValue: number | null;
    }) {
        switch (value.type) {
            case 'D':
                this._lowTime.days = value.newValue ?? 0;
                break;

            case 'H':
                this._lowTime.hours = value.newValue ?? 0;
                break;

            case 'M':
                this._lowTime.minutes = value.newValue ?? 0;
                break;

            case 'S':
                this._lowTime.seconds = value.newValue ?? 0;
                break;
        }

        this._value.low = durationToSeconds(this._lowTime);
        this.emitValueChange();
    }

    onHighValueTimeChange(value: {
        type: 'D' | 'H' | 'M' | 'S';
        newValue: number | null;
    }) {
        switch (value.type) {
            case 'D':
                this._highTime.days = value.newValue ?? 0;
                break;

            case 'H':
                this._highTime.hours = value.newValue ?? 0;
                break;

            case 'M':
                this._highTime.minutes = value.newValue ?? 0;
                break;

            case 'S':
                this._highTime.seconds = value.newValue ?? 0;
                break;
        }

        this._value.high = durationToSeconds(this._highTime);
        this.emitValueChange();
    }

    get isValid(): boolean {
        if (!this._value.enabled) return true;

        if (this.isTeamThreshold) {
            return (
                (this._value as ITeamThreshold).target >= this._value.high &&
                this._value.high >= this._value.low
            );
        } else {
            return this._value.high >= this._value.low;
        }
    }

    get isTeamThreshold(): boolean {
        return (this.value as ITeamThreshold).target !== undefined;
    }

    get teamValue(): ITeamThreshold {
        return this.value as ITeamThreshold;
    }

    get panelClass(): string {
        return (this.settingPanelType === SettingPanelType.Numeric ||
            this.settingPanelType === SettingPanelType.Percentage) &&
            this.orientation === 'horizontal'
            ? 'd-flex flex-row justify-content-between'
            : '';
    }

    private emitValueChange() {
        this.valueChange.emit({
            value: this._value,
            isValid: this.isValid,
        });
    }
}
