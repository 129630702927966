import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReplaceSpacesPipe } from '@dxp/shared/pipes';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-shell-side-nav-item',
    standalone: true,
    templateUrl: './side-nav-item.component.html',
    styleUrl: './side-nav-item.component.scss',
    imports: [CommonModule, RouterModule, ReplaceSpacesPipe],
})
export class SideNavItemComponent {
    @Input({ required: true }) iconName!: string;
    @Input({ required: true }) label!: string;
    @Input() routerLink!: string;
}
