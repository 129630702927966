<div class="break-container">
    <div class="mt-2 ms-2 font-small position-absolute">Current status:</div>
    <div class="break-details h-100 w-100">
        <div>
            <i
                class="fa-4x fa-light"
                [ngClass]="
                    presence().state === 'Break' ? 'fa-hourglass-end' : 'fa-ban'
                "
            ></i>
        </div>
        <div class="font-large-3 break-text mt-3 fw-light">
            {{ presence().label }}
        </div>
        <div class="font-largest mt-2">
            {{ stateSince | formatDuration }}
            @if (presence().timedBreakExpiresAt) {
                remaining
            }
        </div>
        @if (presence().state === 'Break') {
            <div class="mt-4">
                <button class="btn btn-neutral" (click)="endBreak()">
                    End Break
                </button>
            </div>
            <div class="w-90 mt-3">
                <hr class="hr-break" />
            </div>
            <div class="container-fluid perf-container">
                <div class="row ms-5 me-5 mt-3">
                    <div class="col-4 text-center">
                        <div class="d-flex flex-column">
                            <div class="font-large weight-medium mb-3">
                                My Performance -
                                {{ now | date: 'dd / MM / yyyy' }}
                            </div>
                            <div class="mt-1 mb-1">
                                <span class="font-small">Agent:</span>
                                <span class="ms-1 weight-medium">
                                    {{ user.name }}
                                </span>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center mt-4"
                            >
                                <doughnut
                                    [overall]="
                                        secondsToDuration(
                                            agent()!.statistics.tasks
                                                .averageHandlingTimeInSeconds
                                                .value
                                        ) | formatDuration: '[dd]:hh:mm:ss'
                                    "
                                    [metric]="
                                        agent()!.statistics.tasks
                                            .averageHandlingTimeInSeconds.metric
                                    "
                                    [multiLine]="true"
                                    [perc]="
                                        agent()!.statistics.tasks
                                            .averageHandlingTimeInSeconds.metric
                                            .percent
                                    "
                                    heading="AHT"
                                    height="200px"
                                    size="largest"
                                    weight="light"
                                    doughnutCutout="95%"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div class="d-flex flex-column">
                            <div class="font-large weight-medium mb-3">
                                Team Performance -
                                {{ now | date: 'dd / MM / yyyy' }}
                            </div>
                            <div>
                                <div class="dropdown">
                                    <span class="font-small me-2">
                                        Team name:
                                    </span>
                                    <button
                                        class="btn btn-dropdown-transparent dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                    >
                                        {{ selectedTeam }}
                                    </button>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        @for (team of teams(); track team.id) {
                                            <li (click)="onTeamChange(team)">
                                                <button
                                                    class="dropdown-item"
                                                    type="button"
                                                >
                                                    {{ team.name }}
                                                </button>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center mt-4"
                            >
                                <doughnut
                                    [overall]="
                                        secondsToDuration(
                                            team()!.statistics.tasks
                                                .averageHandlingTimeInSeconds
                                                .value
                                        ) | formatDuration: '[dd]:hh:mm:ss'
                                    "
                                    [metric]="
                                        team()!.statistics.tasks
                                            .averageHandlingTimeInSeconds.metric
                                    "
                                    [multiLine]="true"
                                    [perc]="
                                        team()!.statistics.tasks
                                            .averageHandlingTimeInSeconds.metric
                                            .percent
                                    "
                                    heading="AHT"
                                    height="200px"
                                    size="largest"
                                    weight="light"
                                    doughnutCutout="95%"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div class="d-flex flex-column">
                            <div class="font-large weight-medium mb-3">
                                Queue Performance -
                                {{ now | date: 'dd / MM / yyyy' }}
                            </div>
                            <div class="dropdown">
                                <span class="font-small me-2">Queue name:</span>
                                <button
                                    class="btn btn-dropdown-transparent dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                >
                                    {{ selectedQueue }}
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    @for (queue of queues(); track queue.id) {
                                        <li (click)="onQueueChange(queue)">
                                            <button
                                                class="dropdown-item"
                                                type="button"
                                            >
                                                {{ queue.name }}
                                            </button>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center mt-4"
                            >
                                <doughnut
                                    [metric]="
                                        currentQueueStatistics
                                            .answeredWithinSLAPercentage.metric
                                    "
                                    [multiLine]="true"
                                    [perc]="
                                        currentQueueStatistics
                                            .answeredWithinSLAPercentage.metric
                                            .percent
                                    "
                                    heading="SLA"
                                    height="200px"
                                    size="largest"
                                    weight="light"
                                    percent="true"
                                    doughnutCutout="95%"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
