import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'date-fns';

@Pipe({
    name: 'formatDuration',
    standalone: true,
})
export class FormatDurationPipe implements PipeTransform {
    transform(value: Duration, format = '[dd]:[hh]:mm:ss'): string {
        const days = value.days ?? 0;
        const hours = value.hours ?? 0;
        const minutes = value.minutes ?? 0;
        const seconds = value.seconds ?? 0;

        const replacements: { [key: string]: string } = {
            dd: this.padZero(days),
            d: days.toString(),
            hh: this.padZero(hours),
            h: hours.toString(),
            mm: this.padZero(minutes),
            m: minutes.toString(),
            ss: this.padZero(seconds),
            s: seconds.toString(),
        };

        const conditionalFormat = format.replace(
            /\[([a-z]+)\]/gi,
            (_, token) => {
                if (
                    this.shouldShowConditionalValue(token, {
                        days,
                        hours,
                        minutes,
                        seconds,
                    })
                ) {
                    return replacements[token];
                } else {
                    return '';
                }
            },
        );

        let finalFormat = conditionalFormat.replace(
            /(dd|d|hh|h|mm|m|ss|s)/g,
            token => replacements[token],
        );

        finalFormat = finalFormat.replace(/^:|:$/g, '').replace(/::+/g, ':');
        finalFormat = finalFormat.replace(/^:|:$/g, '').replace(/::+/g, ':');
        finalFormat = finalFormat.replace(/^:|:$/g, '').replace(/::+/g, ':');

        if (!finalFormat) {
            finalFormat = '00';
        }

        return finalFormat;
    }

    private padZero(num: number): string {
        return num < 10 ? '0' + num : num.toString();
    }

    private shouldShowConditionalValue(
        token: string,
        values: {
            days: number;
            hours: number;
            minutes: number;
            seconds: number;
        },
    ): boolean {
        const { days, hours, minutes, seconds } = values;

        switch (token) {
            case 'dd':
            case 'd':
                return days > 0;
            case 'hh':
            case 'h':
                return days > 0 || hours > 0;
            case 'mm':
            case 'm':
                return days > 0 || hours > 0 || minutes > 0;
            case 'ss':
            case 's':
                return days > 0 || hours > 0 || minutes > 0 || seconds > 0;
            default:
                return false;
        }
    }
}
