import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    Signal,
} from '@angular/core';
import { IPresence, IUser } from '@dxp/shared/models';
import { FormatDurationPipe, InitialsPipe } from '@dxp/shared/pipes';
import {
    PresenceService,
    StateService,
    UserService,
} from '@dxp/shared/services';
import { Duration, intervalToDuration } from 'date-fns';
import { Subscription, interval, switchMap, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IDropdown, TelXLDropdownComponent } from '@dxp/elements';
import { PresenceApiService } from '@dxp/shared/api';
import { NotificationHubService } from '@dxp/shared/signalr';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationPanelComponent } from './../notifications/notification-panel/notification-panel.component';

@Component({
    selector: 'app-shell-header',
    standalone: true,
    imports: [
        CommonModule,
        NgbDropdownModule,
        FormsModule,
        FormatDurationPipe,
        InitialsPipe,
        TelXLDropdownComponent,
    ],
    providers: [NotificationPanelComponent],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
    private _intervalSubscription: Subscription | undefined;
    isUserDropdownOpen = false;
    breakNames: Signal<string[]>;
    presence: Signal<IPresence>;
    now = new Date();
    unread: Signal<number>;
    isBreakDropdownOpen = false;
    selectedBreak = '';
    presence$: any;

    constructor(
        private userService: UserService,
        private notificationHubService: NotificationHubService,
        private presenceApiService: PresenceApiService,
        private stateService: StateService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        private presenceService: PresenceService,
    ) {
        this.presence = this.presenceService.presence;
        this.breakNames = this.presenceApiService.breakNames;
        this.unread = this.notificationHubService.unread;
    }

    ngOnInit() {
        this.presence$ = this.ngZone.runOutsideAngular(() => {
            return interval(1000).pipe(
                tap(() => {
                    this.now = new Date();

                    this.ngZone.run(() => {
                        this.cdr.detectChanges();
                    });
                }),
                switchMap(() => this.presenceService.presence$),
            );
        });
    }

    ngOnDestroy() {
        if (this._intervalSubscription) {
            this._intervalSubscription.unsubscribe();
        }
    }

    toggleDropdownOpenState(isOpen: boolean) {
        this.isUserDropdownOpen = isOpen;
    }

    toggleNotificationPanel() {
        this.notificationHubService.open(NotificationPanelComponent, {
            position: 'end',
        });
    }

    changePresence() {
        if (!this.presence().online) {
            if (this.presence().state === 'LoggedOut') {
                this.stateService.onLogin();
            } else {
                this.stateService.onActivate();
            }
        } else {
            this.stateService.onLogoff();
        }
    }

    onBreakNameChange(breakName: string) {
        this.presenceApiService.goToBreak(breakName);
    }

    get stateSince(): Duration {
        const timedBreakExpiresAt = this.presence().timedBreakExpiresAt;
        if (timedBreakExpiresAt) {
            return intervalToDuration({
                start: new Date(),
                end: new Date(timedBreakExpiresAt),
            });
        }

        return intervalToDuration({
            start: new Date(this.presence().stateSince),
            end: this.now,
        });
    }

    get user(): IUser {
        return this.userService.user();
    }

    get menuItems(): IDropdown<string>[] {
        return this.breakNames()
            .map(b => ({
                label: b,
                value: b,
                disabled: false,
            }))
            .sort((a: { label: string }, b: { label: string }) =>
                a.label.localeCompare(b.label),
            );
    }
}
