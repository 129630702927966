<ul class="nav nav-pills nav-fill tabs mb-3">
    @for (tab of tabs; track tab.panel) {
        <li
            class="nav-item"
            (click)="setActivePanel(tab.panel)"
            [ngClass]="{ active: activePanel === tab.panel }"
        >
            <span
                class="nav-link"
                [ngClass]="[fontSize, fontWeight]"
                aria-current="page"
            >
                {{ tab.label }}
            </span>
        </li>
    }
</ul>
